import { ConstructionLoanTargetInfoData, ConstructionLoanTargetInfoDataType } from '@sec-loans-types'

import { BaseWizardContext, HierarchicalApplicationData } from '../../../types'

export interface ConstructionLoanTargetInfoStepContext extends ConstructionLoanTargetInfoDataType, BaseWizardContext {}

export type ConstructionLoanTargetInfoFormData = HierarchicalApplicationData &
  Omit<ConstructionLoanTargetInfoData, 'numberOfFloors' | 'livingSquareArea' | 'totalSquareArea'> & {
    numberOfFloors: string
    livingSquareArea: string
    totalSquareArea: string
    numberOfFloorsOther: string
  }

export enum ConstructionLoanTargetDataTypes {
  ConstructionMaterial = 'Construction material',
  NumberOfFloors = 'Number of floors',
  DesiredCompletionDate = 'Desired completion date',
  LivingSquareArea = 'Living square area',
  TotalSquareArea = 'Total square area',
  PricePerSquareMeter = 'Price per square meter',
}
