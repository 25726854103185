import { TransitionStep } from '../TransitionStep/TransitionStep'
import { AuthenticationCancelledStep } from './AuthenticationCancelledStep'
import { AuthenticateInput } from './types'
import { useAuthenticateState } from './useAuthenticateState'

interface AuthenticateStepProps extends AuthenticateInput {
  onExit: () => void
  onAuthenticated: (authToken: string) => void
}

export const AuthenticateStep = ({
  onExit,
  onAuthenticated,
  onAuthenticationCancelled,
  verifyAuthCodeCallback,
  authMode,
}: AuthenticateStepProps): JSX.Element => {
  const { isLoading, isCancelled, hasError, abort, retry, error } = useAuthenticateState({
    onExit,
    onAuthenticated,
    onAuthenticationCancelled,
    verifyAuthCodeCallback,
    authMode,
  })

  return isCancelled ? (
    <AuthenticationCancelledStep onAbort={abort} onRetry={retry} />
  ) : (
    <TransitionStep isLoading={isLoading} isErrored={hasError} onAbort={abort} onRetry={retry} error={error} />
  )
}
