import { Bank, MaritalStatus } from '@shared'
import { IntlShape } from 'react-intl'

import { CsatDifficultyLevel } from '../types'

export const getCommonLocalizedOtherOption = (formatMessage: IntlShape['formatMessage']): string => {
  return formatMessage({ id: 'common-other' })
}

export const getCommonLocalizedEnums = (
  formatMessage: IntlShape['formatMessage'],
): {
  yesNo: Map<boolean, string>
  csatDifficultyLevels: Record<CsatDifficultyLevel, string>
  bankTexts: Record<Bank, string>
  maritalStatusTexts: Record<MaritalStatus, string>
  isCopiedText: Map<boolean, string>
} => {
  const yesNo = new Map([
    [true, formatMessage({ id: 'common-yes' })],
    [false, formatMessage({ id: 'common-no' })],
  ])

  const csatDifficultyLevels: Record<CsatDifficultyLevel, string> = {
    [CsatDifficultyLevel.VeryEasy]: formatMessage({ id: 'common-csat-very-easy-label' }),
    [CsatDifficultyLevel.Easy]: formatMessage({ id: 'common-csat-easy-label' }),
    [CsatDifficultyLevel.Neutral]: formatMessage({ id: 'common-csat-neutral-label' }),
    [CsatDifficultyLevel.Difficult]: formatMessage({ id: 'common-csat-difficult-label' }),
    [CsatDifficultyLevel.VeryDifficult]: formatMessage({ id: 'common-csat-very-difficult-label' }),
  }

  const bankTexts: Record<Bank, string> = {
    [Bank.SPankki]: formatMessage({ id: 'finances-info-bank-s-bank' }),
    [Bank.Aktia]: formatMessage({ id: 'finances-info-bank-aktia' }),
    [Bank.DanskeBank]: formatMessage({ id: 'finances-info-bank-danske' }),
    [Bank.Handelsbanken]: formatMessage({ id: 'finances-info-bank-handelsbanken' }),
    [Bank.Nordea]: formatMessage({ id: 'finances-info-bank-nordea' }),
    [Bank.OP]: formatMessage({ id: 'finances-info-bank-op' }),
    [Bank.Saastopankki]: formatMessage({ id: 'finances-info-bank-saastopankki' }),
    [Bank.Alandsbanken]: formatMessage({ id: 'finances-info-bank-alandsbanken' }),
    [Bank.Other]: formatMessage({ id: 'finances-info-bank-other' }),
  }

  const maritalStatusTexts: Record<MaritalStatus, string> = {
    [MaritalStatus.Married]: formatMessage({ id: 'common-marital-status-married' }),
    [MaritalStatus.Unmarried]: formatMessage({ id: 'common-marital-status-unmarried' }),
    [MaritalStatus.Partner]: formatMessage({ id: 'common-marital-status-partner' }),
    [MaritalStatus.Divorced]: formatMessage({ id: 'common-marital-status-divorced' }),
    [MaritalStatus.Widow]: formatMessage({ id: 'common-marital-status-widow' }),
  }

  const isCopiedText = new Map([
    [true, formatMessage({ id: 'common-copied-text' })],
    [false, formatMessage({ id: 'common-copy-text' })],
  ])

  return {
    isCopiedText,
    yesNo,
    csatDifficultyLevels,
    bankTexts,
    maritalStatusTexts,
  }
}
