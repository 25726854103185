export type { NumberOfApplicantsInfoStepData, NumberOfApplicantsInfoStepDataType } from '../../../types'
import {
  BaseWizardContext,
  HierarchicalApplicationData,
  NumberOfApplicantsInfoStepData,
  NumberOfApplicantsInfoStepDataType,
} from '../../../types'

export interface NumberOfApplicantsInfoStepInputData extends BaseWizardContext {
  numberOfApplicantsInfoData?: NumberOfApplicantsInfoStepData
}

export interface NumberOfApplicantsInfoStepContext extends NumberOfApplicantsInfoStepDataType, BaseWizardContext {}

export enum SecLoansAloneOrTogether {
  Alone = 'alone',
  Together = 'together',
}

export interface NumberOfApplicantsInfoFormData extends HierarchicalApplicationData {
  aloneOrTogether: SecLoansAloneOrTogether
}
