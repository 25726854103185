import { LoanCostEstimateInfoDataType, SecLoansConstructionLotOwnership } from '@sec-loans-types'

import { BaseWizardContext, HierarchicalApplicationData } from '../../../types'

export interface LoanCostEstimateInfoStepContext extends LoanCostEstimateInfoDataType, BaseWizardContext {}

export type LoanCostEstimateInfoFormData = HierarchicalApplicationData & {
  plotOwnership?: SecLoansConstructionLotOwnership
  costEstimate: string
  ownFinancingShare: string
  ownWorkShare?: string
  plotValue?: string
  plotSellingPrice?: string
}

export enum CostEstimationDataTypes {
  PlotOwnership = 'Plot ownership',
  PlotValue = 'Plot Value',
  PlotSellingPrice = 'Plot Selling Price',
  CostEstimate = 'Cost estimate ',
  OwnFinancingShare = 'Own financing share',
  OwnWorkShare = 'Own work share',
  InTotal = 'In total',
}

export enum CostEstimationAdditionalUiDataTypes {
  RenovationCostEstimation = 'Renovation cost estimation',
  RenovationLoanAmount = 'Lainan määrä',
}
