import { SecLoanApplicationType, SecLoanPurposeType, SecLoansHousingLoanTargetType } from '@shared'
import {
  STEP_ADDRESS_INFO,
  STEP_CONSTRUCTION_LOAN_TARGET_INFO_STEP,
  STEP_LIVING_EXPENSES_INFO,
  STEP_LOAN_COST_ESTIMATE_INFO,
  STEP_LOAN_TARGET_TYPE_INFO,
  STEP_LOCATION_INFO,
  STEP_PRICE_INFO,
  STEP_PURCHASE_LOAN_INFO,
  STEP_REAL_ESTATE_LIVING_EXPENSES,
  STEP_RENOVATIONS_INFO,
  STEP_RESIDENCE_INFO,
  STEP_TARGET_ADDITIONAL_INFO,
  STEP_TARGET_TYPE_INFO,
  TargetInfoPage,
} from '@ui-common-sec-loans'
import { assign, setup } from 'xstate'

import { TargetInfoWizardDataType } from '../../types'
import { STEP_ABORTED, STEP_SUCCEEDED } from '../loan-info-phase/loanInfoWizardMachine'
import { TargetInfoWizardContext, TargetInfoWizardEvent } from './types'

export const TARGET_INFO_WIZARD_MACHINE_ID = 'targetInfoWizardMachine'

export {
  STEP_ABORTED,
  STEP_ADDRESS_INFO,
  STEP_CONSTRUCTION_LOAN_TARGET_INFO_STEP,
  STEP_LIVING_EXPENSES_INFO,
  STEP_LOAN_COST_ESTIMATE_INFO,
  STEP_LOAN_TARGET_TYPE_INFO,
  STEP_LOCATION_INFO,
  STEP_PRICE_INFO,
  STEP_PURCHASE_LOAN_INFO,
  STEP_REAL_ESTATE_LIVING_EXPENSES,
  STEP_RENOVATIONS_INFO,
  STEP_RESIDENCE_INFO,
  STEP_SUCCEEDED,
  STEP_TARGET_ADDITIONAL_INFO,
  STEP_TARGET_TYPE_INFO,
  type TargetInfoPage,
  TargetInfoStep,
} from '@ui-common-sec-loans'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const targetWizardMachine = (initialStep: TargetInfoPage = STEP_TARGET_TYPE_INFO) =>
  setup({
    types: {
      context: {} as TargetInfoWizardContext,
      input: {} as TargetInfoWizardContext,
      events: {} as TargetInfoWizardEvent,
    },
    actions: {
      saveFormData: assign(({ context }, data: TargetInfoWizardDataType | undefined) => {
        return {
          ...context,
          data: {
            ...context.data,
            ...data?.targetInfo, // null when returning from edit without saving
          },
        }
      }),
    },
    guards: {
      isTargetTypeUnknown: ({ context }) => {
        return context.data.housingLoanInfo?.targetTypeInfo?.targetType === SecLoansHousingLoanTargetType.NotKnown
      },
      isLoanTransferPurpose: ({ context }) => {
        return context.applicationMetadata?.applicationPurpose === SecLoanPurposeType.LoanTransferToSbank
      },
      isConstructionPurpose: ({ context }) => {
        return context.applicationMetadata?.applicationPurpose === SecLoanPurposeType.Construction
      },
      isTargetTypeRealEstate: ({ context }) => {
        return context.data.housingLoanInfo?.targetTypeInfo?.targetType === SecLoansHousingLoanTargetType.RealEstate
      },
      isNotRenovationPurpose: ({ context }) => {
        return context.applicationMetadata?.applicationPurpose !== SecLoanPurposeType.Renovation
      },
      isRenovationPurpose: ({ context }) => {
        return context.applicationMetadata?.applicationPurpose === SecLoanPurposeType.Renovation
      },
      isCottageLoanTransferPurpose: ({ context }) => {
        return (
          context.applicationMetadata?.applicationType === SecLoanApplicationType.CottageLoan &&
          context.applicationMetadata.applicationPurpose === SecLoanPurposeType.LoanTransferToSbank
        )
      },
      isInvestmentLoanTransferPurpose: ({ context }) => {
        return (
          context.applicationMetadata?.applicationType === SecLoanApplicationType.PropertyInvestment &&
          context.applicationMetadata.applicationPurpose === SecLoanPurposeType.LoanTransferToSbank
        )
      },
      isCottageLoanTransferPurposeAndTypeIsRealEstate: ({ context }) => {
        return (
          context.applicationMetadata?.applicationType === SecLoanApplicationType.CottageLoan &&
          context.applicationMetadata.applicationPurpose === SecLoanPurposeType.LoanTransferToSbank &&
          context.data.housingLoanInfo?.targetTypeInfo?.targetType === SecLoansHousingLoanTargetType.RealEstate
        )
      },
      isInvestmentLoanTransferPurposeAndTypeIsRealEstate: ({ context }) => {
        return (
          context.applicationMetadata?.applicationType === SecLoanApplicationType.PropertyInvestment &&
          context.applicationMetadata.applicationPurpose === SecLoanPurposeType.LoanTransferToSbank &&
          context.data.housingLoanInfo?.targetTypeInfo?.targetType === SecLoansHousingLoanTargetType.RealEstate
        )
      },
    },
  }).createMachine({
    id: TARGET_INFO_WIZARD_MACHINE_ID,
    context: ({ input }) => input || { data: {}, applicationMetadata: undefined },
    initial: initialStep,
    states: {
      [STEP_LOAN_TARGET_TYPE_INFO]: {
        id: STEP_LOAN_TARGET_TYPE_INFO,
        on: {
          NEXT_STEP: {
            target: STEP_ADDRESS_INFO,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
        },
      },
      [STEP_LOAN_COST_ESTIMATE_INFO]: {
        id: STEP_LOAN_COST_ESTIMATE_INFO,
        on: {
          NEXT_STEP: [
            {
              target: STEP_TARGET_ADDITIONAL_INFO,
              guard: 'isRenovationPurpose',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_CONSTRUCTION_LOAN_TARGET_INFO_STEP,
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
          ],
          PREVIOUS_STEP: {
            target: STEP_RESIDENCE_INFO,
            guard: { type: 'isRenovationPurpose' },
          },
        },
      },
      [STEP_CONSTRUCTION_LOAN_TARGET_INFO_STEP]: {
        id: STEP_CONSTRUCTION_LOAN_TARGET_INFO_STEP,
        on: {
          NEXT_STEP: {
            target: STEP_LOCATION_INFO,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
          PREVIOUS_STEP: { target: STEP_LOAN_COST_ESTIMATE_INFO },
        },
      },
      [STEP_TARGET_TYPE_INFO]: {
        id: STEP_TARGET_TYPE_INFO,
        on: {
          NEXT_STEP: {
            target: STEP_PRICE_INFO,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
        },
      },
      [STEP_PRICE_INFO]: {
        id: STEP_PRICE_INFO,
        on: {
          NEXT_STEP: [
            {
              target: STEP_LOCATION_INFO,
              guard: 'isTargetTypeUnknown',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_ADDRESS_INFO,
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
          ],
          PREVIOUS_STEP: {
            target: STEP_TARGET_TYPE_INFO,
          },
        },
      },
      [STEP_LOCATION_INFO]: {
        id: STEP_LOCATION_INFO,
        on: {
          NEXT_STEP: [
            {
              target: STEP_REAL_ESTATE_LIVING_EXPENSES,
              guard: 'isConstructionPurpose',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_RESIDENCE_INFO,
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
          ],
          PREVIOUS_STEP: [
            { target: STEP_CONSTRUCTION_LOAN_TARGET_INFO_STEP, guard: 'isConstructionPurpose' },
            { target: STEP_PRICE_INFO },
          ],
        },
      },
      [STEP_ADDRESS_INFO]: {
        id: STEP_ADDRESS_INFO,
        on: {
          NEXT_STEP: {
            target: STEP_RESIDENCE_INFO,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
          PREVIOUS_STEP: [
            { target: STEP_LOAN_TARGET_TYPE_INFO, guard: 'isLoanTransferPurpose' },
            { target: STEP_PRICE_INFO, guard: 'isNotRenovationPurpose' },
          ],
        },
      },
      [STEP_RESIDENCE_INFO]: {
        id: STEP_RESIDENCE_INFO,
        on: {
          NEXT_STEP: [
            {
              target: STEP_LOAN_COST_ESTIMATE_INFO,
              guard: 'isRenovationPurpose',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_TARGET_ADDITIONAL_INFO,
              guard: 'isTargetTypeUnknown',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_REAL_ESTATE_LIVING_EXPENSES,
              guard: 'isTargetTypeRealEstate',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_LIVING_EXPENSES_INFO,
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
          ],

          PREVIOUS_STEP: [
            { target: STEP_ADDRESS_INFO, guard: 'isRenovationPurpose' },
            { target: STEP_LOCATION_INFO, guard: 'isTargetTypeUnknown' },

            { target: STEP_ADDRESS_INFO },
          ],
        },
      },
      [STEP_LIVING_EXPENSES_INFO]: {
        id: STEP_LIVING_EXPENSES_INFO,
        on: {
          NEXT_STEP: [
            {
              target: STEP_TARGET_ADDITIONAL_INFO,
              guard: 'isCottageLoanTransferPurpose',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_TARGET_ADDITIONAL_INFO,
              guard: 'isInvestmentLoanTransferPurpose',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_RENOVATIONS_INFO,
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
          ],
          PREVIOUS_STEP: { target: STEP_RESIDENCE_INFO },
        },
      },
      [STEP_REAL_ESTATE_LIVING_EXPENSES]: {
        id: STEP_REAL_ESTATE_LIVING_EXPENSES,
        on: {
          NEXT_STEP: [
            {
              target: STEP_TARGET_ADDITIONAL_INFO,
              guard: 'isCottageLoanTransferPurpose',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_TARGET_ADDITIONAL_INFO,
              guard: 'isInvestmentLoanTransferPurpose',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_TARGET_ADDITIONAL_INFO,
              guard: 'isConstructionPurpose',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            {
              target: STEP_RENOVATIONS_INFO,
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
          ],
          PREVIOUS_STEP: [
            { target: STEP_LOCATION_INFO, guard: 'isConstructionPurpose' },
            { target: STEP_RESIDENCE_INFO },
          ],
        },
      },
      [STEP_RENOVATIONS_INFO]: {
        id: STEP_RENOVATIONS_INFO,
        on: {
          NEXT_STEP: {
            target: STEP_TARGET_ADDITIONAL_INFO,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
          PREVIOUS_STEP: [
            { target: STEP_REAL_ESTATE_LIVING_EXPENSES, guard: 'isTargetTypeRealEstate' },
            { target: STEP_LIVING_EXPENSES_INFO },
          ],
        },
      },
      [STEP_TARGET_ADDITIONAL_INFO]: {
        id: STEP_TARGET_ADDITIONAL_INFO,
        on: {
          NEXT_STEP: {
            target: STEP_SUCCEEDED,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
          PREVIOUS_STEP: [
            {
              target: STEP_REAL_ESTATE_LIVING_EXPENSES,
              guard: 'isConstructionPurpose',
            },
            {
              target: STEP_LOAN_COST_ESTIMATE_INFO,
              guard: 'isRenovationPurpose',
            },
            {
              target: STEP_RESIDENCE_INFO,
              guard: 'isTargetTypeUnknown',
            },
            {
              target: STEP_REAL_ESTATE_LIVING_EXPENSES,
              guard: 'isCottageLoanTransferPurposeAndTypeIsRealEstate',
            },
            {
              target: STEP_REAL_ESTATE_LIVING_EXPENSES,
              guard: 'isInvestmentLoanTransferPurposeAndTypeIsRealEstate',
            },
            {
              target: STEP_LIVING_EXPENSES_INFO,
              guard: 'isCottageLoanTransferPurpose',
            },
            {
              target: STEP_LIVING_EXPENSES_INFO,
              guard: 'isInvestmentLoanTransferPurpose',
            },

            { target: STEP_RENOVATIONS_INFO },
          ],
        },
      },
      [STEP_PURCHASE_LOAN_INFO]: {
        id: STEP_PURCHASE_LOAN_INFO,
        on: {
          NEXT_STEP: {
            target: STEP_SUCCEEDED,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
        },
      },
      [STEP_SUCCEEDED]: {
        id: STEP_SUCCEEDED,
        type: 'final',
      },
      [STEP_ABORTED]: {
        id: STEP_ABORTED,
        type: 'final',
      },
    },
    on: {
      EXIT_WIZARD: `.${STEP_ABORTED}`,
      GOTO_SUMMARY: {
        target: `.${STEP_SUCCEEDED}`,
        actions: { type: 'saveFormData', params: ({ event }) => event.data },
      },
    },
  })
