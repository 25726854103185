import { Language } from '@shared'
import { createContext } from 'react'
import * as R from 'remeda'

import { CommonQueryParam, StorageKey } from '../types'
import { resolveAndStoreParam } from './helpers'
import { getLocalStorageItem } from './storage'

export const resolveAndStoreLanguage = (): Language => {
  return resolveAndStoreParam(
    CommonQueryParam.InitialLanguage,
    Object.values(Language),
    Language.Fi,
    StorageKey.Language,
  )
}

export const LanguageCtx = createContext({
  currentLang: Language.Fi,
  setCurrentLang: R.doNothing(),
})

export const getLanguage = (): Language => {
  return getLocalStorageItem<StorageKey, Language>(StorageKey.Language) ?? Language.Fi
}
