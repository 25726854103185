import { NumberOfApplicantsInfoData, OptionalAndNullable } from '@shared'

import { WizardData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { NumberOfApplicantsInfoFormData, SecLoansAloneOrTogether } from './types'

export const convertToFormValues = (
  numberOfApplicantsInfoData: NumberOfApplicantsInfoData | undefined,
): OptionalAndNullable<NumberOfApplicantsInfoFormData> => {
  const hasCoApplicant = numberOfApplicantsInfoData?.hasCoApplicant
  if (hasCoApplicant === undefined) {
    return {
      aloneOrTogether: null,
    }
  }
  return {
    aloneOrTogether: hasCoApplicant ? SecLoansAloneOrTogether.Together : SecLoansAloneOrTogether.Alone,
  }
}

export const getAndValidateNumberOfApplicantsInfoTypeData = (
  validationData: WizardData,
): NumberOfApplicantsInfoData | undefined => {
  const numberOfApplicantsInfoData = validationData.applicantInfo?.numberOfApplicantsInfoData
  return isFormDataValid(convertToFormValues(numberOfApplicantsInfoData), createFormValidator)
    ? validationData.applicantInfo?.numberOfApplicantsInfoData
    : undefined
}
