import { CommonMunicipality, getMunicipality, isCommonMunicipality } from './municipalities'

export const resolveOtherLocation = (municipality: string | undefined): string | undefined => {
  const location = getMunicipality(municipality)
  if (location && !isCommonMunicipality(location.id)) {
    return location.id
  }
  return undefined
}

export const resolveLocation = (municipality: string | undefined): CommonMunicipality | undefined => {
  const location = getMunicipality(municipality)
  if (location) {
    if (isCommonMunicipality(location.id)) {
      return location.id as CommonMunicipality
    }
    return CommonMunicipality.Other
  }
  return undefined
}
