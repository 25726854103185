import { SecLoanCarOwnershipType, SecLoanChildrenAgeGroup } from '@sec-loans-types'
import { testIsValidMonetaryValue, YesNo } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { ExpensesInfoFormData as FormData } from './ExpensesInfoStep'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: FormData, changedField?: Path<FormData>) => void> =>
  create((data, changedField) => {
    only(changedField)

    test('hasCarOwnership', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasCarOwnership).inside(Object.values(YesNo))
    })

    omitWhen(data.hasCarOwnership === YesNo.No, () => {
      test('carOwnershipType', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.carOwnershipType).inside(Object.values(SecLoanCarOwnershipType))
      })
    })

    test('hasAlimony', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasAlimony).inside(Object.values(YesNo))
    })

    omitWhen(data.hasAlimony === YesNo.No, () => {
      data.childAlimony?.forEach((childAlimony, index) => {
        test(`childAlimony.${index}.ageGroup`, formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(childAlimony.ageGroup).inside(Object.values(SecLoanChildrenAgeGroup))
        })
        testIsValidMonetaryValue(`childAlimony.${index}.alimonyAmount`, childAlimony.alimonyAmount, formatMessage)
      })
    })
  })
