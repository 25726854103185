import { sendCustomDataEventToAnalytics, sendErrorEventToAnalytics } from '@components/common/analytics'
import { isAuthenticationServerError, parseParams } from '@utils/authentication'
import { useEffect } from 'react'

export const useReportLandingUrl = (): void => {
  useEffect(() => {
    const url = window.location.href
    sendCustomDataEventToAnalytics({ url })

    const params = parseParams(window.location.hash)

    if (isAuthenticationServerError(params.error)) {
      sendErrorEventToAnalytics(`Authentication failed: ${params.error}`, new Error(`URL: ${url}`))
    }
  }, [])
}
