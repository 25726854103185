import { MachineContext, sendParent, setup } from 'xstate'

import { PromptCancelledEvent, PromptConfirmedEvent } from './types'

type ConfirmEvent = { type: 'CONFIRM' }
type CancelEvent = { type: 'CANCEL' }
export type PromptEvent = ConfirmEvent | CancelEvent

export const promptMachine = setup({
  types: {} as {
    context: MachineContext
    events: PromptEvent
  },
  actions: {
    cancel: sendParent<MachineContext, PromptEvent, undefined, PromptCancelledEvent, PromptEvent>(() => ({
      type: 'PROMPT_CANCELLED',
    })),
    confirm: sendParent<MachineContext, PromptEvent, undefined, PromptConfirmedEvent, PromptEvent>(() => ({
      type: 'PROMPT_CONFIRMED',
    })),
  },
}).createMachine({
  id: 'promptMachine',
  initial: 'prompted',
  predictableActionArguments: true,
  states: {
    prompted: {
      on: {
        CANCEL: { actions: { type: 'cancel' } },
        CONFIRM: { actions: { type: 'confirm' } },
      },
    },
  },
})
