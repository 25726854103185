import { NextStepEvent } from '@components/common/events'
import { sendParent, setup } from 'xstate'

import { ContinueEvent } from './types'

const INITIAL = 'initial'

export type SimpleStepEvent = NextStepEvent<undefined> | ContinueEvent

export const simpleStepMachine = setup({
  types: {
    events: {} as SimpleStepEvent,
  },
  actions: {
    proceedToNextStep: sendParent(() => ({
      type: 'NEXT_STEP',
      data: undefined,
    })),
  },
}).createMachine({
  predictableActionArguments: true,
  initial: INITIAL,
  states: {
    [INITIAL]: {
      on: {
        CONTINUE: { actions: { type: 'proceedToNextStep' } },
      },
    },
  },
})
