import { OptionalAndNullable } from '@shared'
import { convertUndefinedToNull } from '@ui-common'
import { IntlShape } from 'react-intl'

import { getCompletenessForTwoApplicants, isEntrepreneur } from '../../../../utils/helpers'
import { ApplicantStepDataValidator, EntrepreneurInfoStepBase, EntrepreneurInfoStepData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { EntrepreneurInfoFormData } from './EntrepreneurInfoStep'
import { createFormValidator } from './formValidator'

export const convertToFormValues = (
  entrepreneurInfo: EntrepreneurInfoStepBase | undefined,
): OptionalAndNullable<EntrepreneurInfoFormData> => {
  return convertUndefinedToNull({
    companyName: entrepreneurInfo?.companyName,
    businessId: entrepreneurInfo?.businessId,
    legalFormOfTheCompany: entrepreneurInfo?.legalFormOfTheCompany,
    industrySector: entrepreneurInfo?.industrySector,
    entrepreneurshipStart: entrepreneurInfo?.entrepreneurshipStart,
    previousEmploymentType: entrepreneurInfo?.previousEmploymentType,
    previousEmployerName: entrepreneurInfo?.previousEmployment?.previousEmployerName,
    previousCompanyName: entrepreneurInfo?.previousEmployment?.previousCompanyName,
    previousJobTitle: entrepreneurInfo?.previousEmployment?.previousJobTitle,
    previousEmploymentStartDate: entrepreneurInfo?.previousEmployment?.previousEmploymentStartDate,
    previousEmploymentEndDate: entrepreneurInfo?.previousEmployment?.previousEmploymentEndDate,
  })
}

export const getAndValidateEntrepreneurInfoTypeData: ApplicantStepDataValidator<EntrepreneurInfoStepData> = ({
  applicantInfo,
}) => {
  const validatedBackgroundInfoData = applicantInfo?.backgroundInfoData
  const entrepreneurInfoData = applicantInfo?.entrepreneurInfoData
  const hasCoApplicant = !!applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant

  const isFirstApplicantEntrepreneur = isEntrepreneur(validatedBackgroundInfoData, false)
  const isSecondApplicantEntrepreneur = hasCoApplicant && isEntrepreneur(validatedBackgroundInfoData, true)

  if (!isFirstApplicantEntrepreneur && !isSecondApplicantEntrepreneur) {
    return null
  }

  const validateApplicant = (isSecondApplicant: boolean) => {
    const applicantData = isSecondApplicant
      ? entrepreneurInfoData?.secondApplicant
      : entrepreneurInfoData?.firstApplicant
    return isFormDataValid(convertToFormValues(applicantData), (formatMessage: IntlShape['formatMessage']) =>
      createFormValidator(formatMessage),
    )
  }

  const isFirstApplicantValid = !isFirstApplicantEntrepreneur || validateApplicant(false)
  const isSecondApplicantValid = !isSecondApplicantEntrepreneur || (hasCoApplicant && validateApplicant(true))

  if (!isFirstApplicantValid && !isSecondApplicantValid) {
    return undefined
  }

  return {
    data: {
      firstApplicant:
        isFirstApplicantEntrepreneur && isFirstApplicantValid ? entrepreneurInfoData?.firstApplicant : undefined,
      secondApplicant:
        isSecondApplicantEntrepreneur && isSecondApplicantValid ? entrepreneurInfoData?.secondApplicant : undefined,
    },
    isComplete: getCompletenessForTwoApplicants({ hasCoApplicant, isFirstApplicantValid, isSecondApplicantValid }),
  }
}
