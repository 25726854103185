import { GlobalServiceProvider } from '@components/state'
import { AppId, Language } from '@shared'
import { useLocalizedTitle } from '@ui-common/hooks/useLocalizedTitle'
import { useReportLandingUrl } from '@ui-common/hooks/useReportLandingUrl'
import { getLanguage, LanguageCtx, resolveAndStoreLanguage, richTextConfig } from '@ui-common/utils'
import { BankBrandWrapper } from '@ui-components/brand/BankBrandWrapper'
import { GlobalStyle } from '@ui-components/global/CommonStyling'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { IntlProvider, MessageFormatElement } from 'react-intl'
import { Reset } from 'styled-modern-css-reset'

import { AppErrorBoundary } from '../AppErrorBoundary'

const SiteTitle = ({ appId }: { appId: AppId }) => {
  const language = getLanguage()
  useLocalizedTitle(language, appId)

  return null
}

export type CommonBankAppProps = PropsWithChildren<{
  appId: AppId
  translations: Record<Language, Record<string, string> | Record<string, MessageFormatElement[]>>
}>

export const CommonBankApp = (props: CommonBankAppProps): JSX.Element => {
  const { appId, translations, children } = props
  const [currentLang, setCurrentLang] = useState<Language>(resolveAndStoreLanguage)

  useEffect(() => {
    document.documentElement.lang = currentLang
  }, [currentLang])

  useReportLandingUrl()

  const languageProviderValue = useMemo(() => ({ currentLang, setCurrentLang }), [currentLang])

  return (
    <BankBrandWrapper>
      <AppErrorBoundary>
        <LanguageCtx.Provider value={languageProviderValue}>
          <IntlProvider
            locale={currentLang}
            messages={translations[currentLang]}
            defaultRichTextElements={richTextConfig}
          >
            <GlobalServiceProvider>
              <Reset />
              <GlobalStyle />
              <SiteTitle appId={appId} />
              {children}
            </GlobalServiceProvider>
          </IntlProvider>
        </LanguageCtx.Provider>
      </AppErrorBoundary>
    </BankBrandWrapper>
  )
}
