import { ImplicitlySubmittableForm as Form } from '@ui-common/components/common/ImplicitlySubmittableForm'
import { useWizardFormShouldUnregisterAsFalse } from '@ui-common/hooks/useWizardForm'
import { IndentationText } from '@ui-components/decorative/IndentationText'
import { FormRadioGroup } from '@ui-components/form/FormRadioGroup'
import { FormTextArea } from '@ui-components/form/FormTextArea'
import { FlexColumnContainer } from '@ui-components/global/CommonStyling'
import { FormGroupOption } from '@ui-components/global/ComponentProps'
import { ChangeEvent, forwardRef, useCallback, useImperativeHandle } from 'react'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { CsatDifficultyLevel } from '../../../types'
import { sendCustomDataEventToAnalytics, SnowPlowData } from '../analytics'

export interface CsatFormData {
  difficultyLevel?: CsatDifficultyLevel
  exitReason?: string
  feedback?: string
}

type FormId = 'difficultyLevel' | 'exitReason'

interface CsatFormProps {
  options: FormGroupOption[]
  formId: FormId
  formLabel: string
  feedbackLabel: string
  error?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  getAnalyticsData: (data: CsatFormData) => SnowPlowData | undefined
  onContinue?: () => void
}

export interface CsatFormHandle {
  submit: () => unknown
}

const CsatContainer = styled.div`
  margin-top: 32px;
`

const TextAreaSection = styled(FlexColumnContainer)`
  align-items: flex-end;
`
const FeedbackTextArea = styled(FormTextArea)`
  max-width: unset;
  height: 8em;
`

const CSAT_FEEDBACK_MAX_CHARACTERS = 250

export const CsatQuestionnaire = forwardRef<CsatFormHandle, CsatFormProps>((props, ref) => {
  const { options, formId, formLabel, feedbackLabel, error, onChange, getAnalyticsData, onContinue } = props

  const intl = useIntl()

  const { register, handleSubmit, watch } = useWizardFormShouldUnregisterAsFalse<CsatFormData>({})

  const selectedFormOptionLevel = watch(formId)

  const onSubmit = useCallback(
    (data: CsatFormData) => {
      const analyticsData = getAnalyticsData(data)
      !!analyticsData && sendCustomDataEventToAnalytics(analyticsData)
      onContinue?.()
    },
    [onContinue, getAnalyticsData],
  )

  useImperativeHandle(ref, () => {
    return {
      async submit() {
        await handleSubmit(onSubmit)()
      },
    }
  }, [handleSubmit, onSubmit])

  return (
    <CsatContainer>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => {
          e.key === 'Enter' && e.preventDefault()
        }}
      >
        <FormRadioGroup label={formLabel} options={options} {...register(formId, { onChange })} error={error} />

        {!!selectedFormOptionLevel && (
          <IndentationText>
            <TextAreaSection>
              <FeedbackTextArea
                maxLength={CSAT_FEEDBACK_MAX_CHARACTERS}
                label={feedbackLabel}
                additionalDescription={intl.formatMessage(
                  { id: 'common-max-characters' },
                  { limit: CSAT_FEEDBACK_MAX_CHARACTERS },
                )}
                {...register('feedback')}
              />
            </TextAreaSection>
          </IndentationText>
        )}
      </Form>
    </CsatContainer>
  )
})

CsatQuestionnaire.displayName = 'CsatQuestionnaire'
