import {
  assertValue,
  CurrentResidenceInfoBase,
  CurrentResidenceInfoData,
  HousingInfoData,
  SecLoansApplicants,
  SecLoansApplicationMetadata,
  SecLoansCurrentResidenceSellingPlan,
  SecLoansExpenseShareForNewFlat,
} from '@shared'
import { convertUndefinedToNull, getYesNoDefault, isNumber, numberToString, toNumber, YesNo } from '@ui-common'
import { IntlShape } from 'react-intl'

import { getCompletenessForTwoApplicants, getValidatedData, isHomeOwner } from '../../../../utils/helpers'
import { ApplicantStepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { getIsBuyOrConstructHousingLoan } from '../housing-info-step/housingInfoStepUtils'
import { createFormValidator } from './formValidator'
import { CurrentResidenceInfoStepData, CurrentResidenceInfoStepFormData } from './types'

export const resolveExpenseShareForNewFlat = (
  data: CurrentResidenceInfoBase | undefined | null,
): undefined | SecLoansExpenseShareForNewFlat => {
  if (!data) {
    return undefined
  }
  const { expenseShareForNewFlatAmount } = data

  if (expenseShareForNewFlatAmount === null) {
    return undefined
  }

  if (isNumber(expenseShareForNewFlatAmount) && expenseShareForNewFlatAmount >= 0) {
    return SecLoansExpenseShareForNewFlat.OtherAmount
  }
  return SecLoansExpenseShareForNewFlat.All
}

export const getApplicantFormValues = (
  currentResidenceInfoStepData: CurrentResidenceInfoStepData | undefined | null,
  isSecondApplicant: boolean,
): CurrentResidenceInfoStepFormData => {
  const data = isSecondApplicant ? currentResidenceInfoStepData?.secondApplicant : currentResidenceInfoStepData
  const isPlanNotForSale = data?.sellingPlan === SecLoansCurrentResidenceSellingPlan.NotForSale
  return {
    sellingPlan: data?.sellingPlan,
    price: isPlanNotForSale ? undefined : numberToString(data?.price),
    hasRemainingLoan: getYesNoDefault(!!data, data?.remainingLoanAmount, isPlanNotForSale ? undefined : YesNo.No),
    remainingLoanAmount: numberToString(data?.remainingLoanAmount),
    remainingLoanInstallment: numberToString(data?.remainingLoanInstallment),
    lender: data?.lender,
    expenseShareForNewFlat: resolveExpenseShareForNewFlat(data),
    expenseShareForNewFlatAmount: numberToString(data?.expenseShareForNewFlatAmount),
  }
}

export const getApplicantSubmitDataValues = (
  formData: CurrentResidenceInfoStepFormData,
  isSecondApplicant: boolean,
): CurrentResidenceInfoStepData => {
  const data = isSecondApplicant ? formData.secondApplicant : formData
  const {
    sellingPlan,
    price,
    hasRemainingLoan,
    remainingLoanAmount,
    remainingLoanInstallment,
    lender,
    expenseShareForNewFlatAmount,
    expenseShareForNewFlat,
  } = data ?? {}

  return {
    sellingPlan,
    price: toNumber(price),
    remainingLoanAmount: hasRemainingLoan === YesNo.Yes ? toNumber(remainingLoanAmount) : undefined,
    remainingLoanInstallment: hasRemainingLoan === YesNo.Yes ? toNumber(remainingLoanInstallment) : undefined,
    lender: hasRemainingLoan === YesNo.Yes ? lender : undefined,
    expenseShareForNewFlatAmount:
      sellingPlan !== SecLoansCurrentResidenceSellingPlan.NotForSale &&
      expenseShareForNewFlat === SecLoansExpenseShareForNewFlat.OtherAmount
        ? toNumber(expenseShareForNewFlatAmount)
        : undefined,
  }
}

export const convertToCurrentResidenceInfoSubmitData = (
  formData: CurrentResidenceInfoStepFormData,
  hasCoApplicant: boolean,
  housingData: HousingInfoData | undefined,
): CurrentResidenceInfoStepData => {
  return {
    ...getApplicantSubmitDataValues(formData, false),
    secondApplicant:
      hasCoApplicant && isHomeOwner(housingData, true) ? getApplicantSubmitDataValues(formData, true) : undefined,
  }
}

export const convertToFormValues = (
  currentResidenceInfoStepData: CurrentResidenceInfoStepData | undefined,
  hasCoApplicant: boolean,
): CurrentResidenceInfoStepFormData => {
  return convertUndefinedToNull({
    ...getApplicantFormValues(currentResidenceInfoStepData, false),
    secondApplicant: hasCoApplicant ? getApplicantFormValues(currentResidenceInfoStepData, true) : undefined,
  })
}

export const isApplicantNotHomeOwner = (
  applicantWithHomeOwnership: SecLoansApplicants,
  isSecondApplicant: boolean,
): boolean =>
  ![
    SecLoansApplicants.BothApplicants,
    isSecondApplicant ? SecLoansApplicants.SecondApplicant : SecLoansApplicants.FirstApplicant,
  ].includes(applicantWithHomeOwnership)

export const bothAreHomeOwnersDifferentHouseholds = (
  hasCoApplicant: boolean,
  housingInfoData: HousingInfoData | undefined,
): boolean => hasCoApplicant && isHomeOwner(housingInfoData, false) && isHomeOwner(housingInfoData, true)

export const resolveApplicantWithHomeOwnership = (
  hasCoApplicant: boolean,
  housingInfoData: HousingInfoData | undefined,
): SecLoansApplicants => {
  if (hasCoApplicant) {
    if (bothAreHomeOwnersDifferentHouseholds(hasCoApplicant, housingInfoData)) {
      //In this case both applicants are home owners but live in different households
      return SecLoansApplicants.BothApplicants
    }
    // In this case only one applicant is home owner
    return isHomeOwner(housingInfoData, false) ? SecLoansApplicants.FirstApplicant : SecLoansApplicants.SecondApplicant
  } else {
    // In this case the applicant is applying alone and has to be homeOwner, otherwise the step is skipped
    return SecLoansApplicants.FirstApplicant
  }
}

export const getAndValidateCurrentResidenceInfoTypeData: ApplicantStepDataValidator<CurrentResidenceInfoData> = (
  validationData,
  applicationMetadata,
) => {
  const metadata = assertValue(applicationMetadata, 'applicationMetadata')
  let currentResidenceInfoData = validationData.applicantInfo?.currentResidenceInfoData
  const housingInfoData = validationData.applicantInfo?.housingInfoData
  const hasCoApplicant = validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant
  const isFirstApplicantHomeOwner = isHomeOwner(housingInfoData, false)
  const isSecondApplicantHomeOwner = isHomeOwner(housingInfoData, true)

  const isBuyOrConstructHousingLoan = getIsBuyOrConstructHousingLoan(metadata)

  if (!isBuyOrConstructHousingLoan || (!isFirstApplicantHomeOwner && !isSecondApplicantHomeOwner)) {
    return null
  }

  if (!isFirstApplicantHomeOwner && isSecondApplicantHomeOwner) {
    currentResidenceInfoData = { secondApplicant: currentResidenceInfoData?.secondApplicant }
  } else if (isFirstApplicantHomeOwner && !isSecondApplicantHomeOwner) {
    currentResidenceInfoData = { ...currentResidenceInfoData, secondApplicant: hasCoApplicant ? null : undefined }
  }

  const formValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, resolveApplicantWithHomeOwnership(!!hasCoApplicant, housingInfoData))
  const isValid = isFormDataValid(convertToFormValues(currentResidenceInfoData, !!hasCoApplicant), formValidator)

  const data = getValidatedData<CurrentResidenceInfoData>(currentResidenceInfoData, isValid)

  data.isComplete = getCompletenessForTwoApplicants({
    hasCoApplicant: !!hasCoApplicant,
    isFirstApplicantValid: isValid,
    isSecondApplicantValid: !isSecondApplicantHomeOwner || !!currentResidenceInfoData?.secondApplicant,
  })

  return data
}

export const applicantOfTheOnlyHomeOwned = (
  hasCoApplicant: boolean,
  housingInfoData: HousingInfoData,
): SecLoansApplicants =>
  hasCoApplicant && !housingInfoData?.secondApplicant
    ? SecLoansApplicants.BothApplicants
    : resolveApplicantWithHomeOwnership(hasCoApplicant, housingInfoData)

export const isCurrentResidenceInfoComplete = (
  currentResidenceInfo: CurrentResidenceInfoStepData | undefined,
  applicantWithHomeOwnership: SecLoansApplicants,
): boolean => {
  switch (applicantWithHomeOwnership) {
    case SecLoansApplicants.FirstApplicant:
      return !!currentResidenceInfo?.sellingPlan
    case SecLoansApplicants.BothApplicants:
      return !!currentResidenceInfo?.sellingPlan && !!currentResidenceInfo?.secondApplicant?.sellingPlan
    case SecLoansApplicants.SecondApplicant:
      return !!currentResidenceInfo?.secondApplicant?.sellingPlan
  }
}

export const shouldShowCurrentResidenceInfo = (
  housingInfoData: HousingInfoData,
  metadata: SecLoansApplicationMetadata,
): boolean => {
  const hasOwnedHome = isHomeOwner(housingInfoData, false) || isHomeOwner(housingInfoData, true)
  const isBuyOrConstructHousingLoan = getIsBuyOrConstructHousingLoan(metadata)
  return hasOwnedHome && isBuyOrConstructHousingLoan
}
