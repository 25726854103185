import { SecLoansFlow, SecLoansSource } from '@shared'

export const assertSecLoansFlow = (value: string | null): SecLoansFlow => {
  if (isSecLoansFlow(value)) {
    return value
  }
  throw new Error(`Value should be SecLoansFlow. Value was: ${value}`)
}

export const assertSecLoansSourceChannel = (source: string | null): SecLoansSource => {
  if (isSecLoansSourceChannel(source)) {
    return source
  }
  throw new Error(`Value should be SecLoansSourceChannel. Values was: ${source}`)
}

export const isSecLoansFlow = (value: string | null): value is SecLoansFlow => {
  return Object.values(SecLoansFlow).includes(value as SecLoansFlow)
}

export const isSecLoansSourceChannel = (value: string | null): value is SecLoansSource => {
  return Object.values(SecLoansSource).includes(value as SecLoansSource)
}
