import {
  assertValue,
  HousingInfoBase,
  HousingInfoData,
  Maybe,
  OptionalAndNullable,
  SecLoanApplicationType,
  SecLoanHousingType,
  SecLoanPurposeType,
  SecLoansApplicants,
  SecLoansApplicationMetadata,
  typedBoolean,
} from '@shared'
import { convertUndefinedToNull, getYesNoDefault, numberToString, toNumber, YesNo } from '@ui-common'
import { IntlShape } from 'react-intl'

import {
  getCompletenessForTwoApplicants,
  getOptionAndOtherFormValues,
  getValidatedData,
  resolveValueFromFormFields,
} from '../../../../utils/helpers'
import { ApplicantStepDataValidator, HousingInfoStepData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { HousingInfoFormData } from './HousingInfoStep'
import { HousingInfoDataTypes, HousingInfoText, MAX_NUMBER_OF_CHILDREN_OPTION } from './types'

export const isHousingTypeWithApplicantOwnership = (housingType: Maybe<SecLoanHousingType>): boolean =>
  !!housingType &&
  [SecLoanHousingType.RightOfResidence, SecLoanHousingType.SharedOwnership, SecLoanHousingType.HomeOwnership].includes(
    housingType,
  )

export const convertToHousingInfoData = (
  hasCoApplicant: boolean,
  isSameHouseHold: YesNo | undefined,
  data: HousingInfoFormData,
  applicationMetadata: SecLoansApplicationMetadata,
): HousingInfoStepData => {
  const isBuyOrConstructHousingLoan = getIsBuyOrConstructHousingLoan(applicationMetadata)
  const isSameHouseholdNo = isBuyOrConstructHousingLoan ? false : null
  const numberOfAdults = assertValue(toNumber(data?.numberOfAdults), 'data.numberOfAdults')
  const numberOfChildren = resolveValueFromFormFields(
    assertValue(data?.numberOfChildren, 'data.numberOfChildren'),
    data?.numberOfChildrenOther,
  )

  return {
    isSameHousehold: hasCoApplicant ? data.isSameHousehold === YesNo.Yes : isSameHouseholdNo,
    housingType: data?.housingType,
    homeOwnershipType: data?.housingType === SecLoanHousingType.HomeOwnership ? data?.homeOwnershipType : undefined,
    numberOfAdults,
    numberOfChildren: assertValue(numberOfChildren, 'numberOfChildren'),
    ownership: isHousingTypeWithApplicantOwnership(data?.housingType)
      ? (data?.ownership ?? SecLoansApplicants.FirstApplicant)
      : undefined,
    moveInDate: data?.moveInDate,
    monthlyLivingExpenses: toNumber(data?.monthlyLivingExpenses),
    secondApplicant:
      hasCoApplicant && isSameHouseHold === YesNo.No
        ? {
            housingType: assertValue(data?.secondApplicant?.housingType, 'data.secondApplicant.housingType'),
            homeOwnershipType:
              data?.secondApplicant?.housingType === SecLoanHousingType.HomeOwnership
                ? data?.secondApplicant?.homeOwnershipType
                : undefined,
          }
        : undefined,
  }
}

export const convertToHousingInfoFormData = (
  hasCoApplicant: boolean,
  data: HousingInfoStepData | undefined,
  applicationMetadata: SecLoansApplicationMetadata,
): OptionalAndNullable<HousingInfoFormData> => {
  const isBuyOrConstructHousingLoan = getIsBuyOrConstructHousingLoan(applicationMetadata)
  const isSameHouseholdDefault = getYesNoDefault(!!data, !!data?.isSameHousehold)
  const numberOfChildren = getOptionAndOtherFormValues(data?.numberOfChildren, 0, MAX_NUMBER_OF_CHILDREN_OPTION)
  return convertUndefinedToNull({
    housingType: data?.housingType,
    homeOwnershipType: data?.housingType === SecLoanHousingType.HomeOwnership ? data?.homeOwnershipType : undefined,
    ownership:
      data?.housingType && isHousingTypeWithApplicantOwnership(data?.housingType) ? data?.ownership : undefined,
    moveInDate: data?.moveInDate,
    monthlyLivingExpenses: numberToString(data?.monthlyLivingExpenses),
    secondApplicant:
      hasCoApplicant && isSameHouseholdDefault === YesNo.No
        ? {
            housingType: data?.secondApplicant?.housingType,
            homeOwnershipType:
              data?.secondApplicant?.housingType === SecLoanHousingType.HomeOwnership
                ? data?.secondApplicant?.homeOwnershipType
                : undefined,
          }
        : undefined,
    isSameHousehold: hasCoApplicant && isBuyOrConstructHousingLoan ? isSameHouseholdDefault : null,
    numberOfAdults: numberToString(data?.numberOfAdults),
    numberOfChildren: numberOfChildren.option,
    numberOfChildrenOther: numberOfChildren.other,
  })
}

export const getAndValidateHousingInfoTypeData: ApplicantStepDataValidator<HousingInfoData> = (
  validationData,
  applicationMetadata,
) => {
  const housingInfoData = validationData.applicantInfo?.housingInfoData
  const hasCoApplicant = !!validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant
  const isBuyOrConstructHousingLoan = getIsBuyOrConstructHousingLoan(applicationMetadata)

  const formValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, hasCoApplicant, assertValue(applicationMetadata, 'applicationMetadata'))

  const isValid = isFormDataValid(
    convertToHousingInfoFormData(
      hasCoApplicant,
      housingInfoData,
      assertValue(applicationMetadata, 'applicationMetadata'),
    ),
    formValidator,
  )
  const data = getValidatedData<HousingInfoData>(housingInfoData, isValid)
  data.isComplete = getCompletenessForTwoApplicants({
    hasCoApplicant,
    isFirstApplicantValid: isValid,
    isSecondApplicantValid: getCompletenessForSecondApplicantData(
      isBuyOrConstructHousingLoan,
      housingInfoData?.isSameHousehold,
      housingInfoData?.secondApplicant,
    ),
  })

  return data
}

export const getCompletenessForSecondApplicantData = (
  isBuyOrConstructHousingLoan: boolean,
  isSameHousehold: boolean | undefined | null,
  secondApplicantData: HousingInfoBase | undefined,
): boolean => {
  return !isBuyOrConstructHousingLoan || isSameHousehold === true || (!isSameHousehold && !!secondApplicantData)
}

export const getHousingInfoPhrases = (
  intl: IntlShape,
  isBuyOrConstructHousingLoan: boolean,
): Record<HousingInfoText, string> => {
  return {
    [HousingInfoText.Header]: intl.formatMessage({
      id: 'sec-loans-housing-info-header',
    }),
    [HousingInfoText.IsSameHouseHoldLabel]: intl.formatMessage({
      id: 'sec-loans-housing-info-is-same-household-label',
    }),
    [HousingInfoText.MoveInDateLabel]: intl.formatMessage({
      id: 'sec-loans-housing-info-move-in-label',
    }),
    [HousingInfoText.LivingExpensesLabel]: intl.formatMessage({
      id: 'sec-loans-housing-info-living-expenses-label',
    }),
    [HousingInfoText.LivingExpensesDescription]: intl.formatMessage({
      id: 'sec-loans-housing-info-living-expenses-description',
    }),
    [HousingInfoText.NumberOfAdultsLabel]: isBuyOrConstructHousingLoan
      ? intl.formatMessage({ id: 'sec-loans-housing-info-moving-adult-label' })
      : intl.formatMessage({ id: 'sec-loans-housing-info-household-adult-label' }),
    [HousingInfoText.NumberOfAdultsDescription]: intl.formatMessage({
      id: 'sec-loans-housing-info-moving-adult-description',
    }),
    [HousingInfoText.NumberOfChildrenLabel]: isBuyOrConstructHousingLoan
      ? intl.formatMessage({ id: 'sec-loans-housing-info-moving-children-label' })
      : intl.formatMessage({ id: 'sec-loans-housing-info-household-children-label' }),
    [HousingInfoText.NumberOfPeopleHeading]: isBuyOrConstructHousingLoan
      ? intl.formatMessage({ id: 'sec-loans-housing-info-moving-label' })
      : intl.formatMessage({ id: 'sec-loans-housing-info-household-heading' }),
  }
}

export const getIsBuyOrConstructHousingLoan = (
  applicationMetadata: SecLoansApplicationMetadata | undefined,
): boolean => {
  const isHousingLoan = applicationMetadata?.applicationType === SecLoanApplicationType.HousingLoan
  const isBuyingOrConstructing =
    !!applicationMetadata?.applicationPurpose &&
    [SecLoanPurposeType.Buy, SecLoanPurposeType.Construction].includes(applicationMetadata?.applicationPurpose)
  return isHousingLoan && isBuyingOrConstructing
}

export const getIsTransferHousingLoan = (applicationMetadata: SecLoansApplicationMetadata | undefined): boolean => {
  return (
    applicationMetadata?.applicationType === SecLoanApplicationType.HousingLoan &&
    applicationMetadata?.applicationPurpose === SecLoanPurposeType.LoanTransferToSbank
  )
}

export const getShownInformation = (
  applicationMetadata: SecLoansApplicationMetadata,
  hasCoApplicant: boolean,
): HousingInfoDataTypes[] => {
  let shouldAskIsSameHouseHold = false
  let shouldAskMoveInDate = true
  let shouldAskMonthlyLivingExpenses = true
  const shouldAskNumberOfAdults = true
  const shouldAskNumberOfChildren = true

  const { applicationPurpose, applicationType } = applicationMetadata

  const isBuyOrConstructHousingLoan = getIsBuyOrConstructHousingLoan(applicationMetadata)
  const isRenovationPurpose = applicationPurpose === SecLoanPurposeType.Renovation
  const isHousingLoanTransfer =
    applicationType === SecLoanApplicationType.HousingLoan &&
    applicationPurpose === SecLoanPurposeType.LoanTransferToSbank

  if (isBuyOrConstructHousingLoan) {
    if (hasCoApplicant) {
      shouldAskIsSameHouseHold = true
    }
    shouldAskMoveInDate = false
    shouldAskMonthlyLivingExpenses = false
  }

  if (isRenovationPurpose) {
    shouldAskIsSameHouseHold = false
    shouldAskMoveInDate = true
    shouldAskMonthlyLivingExpenses = true
  }

  if (isHousingLoanTransfer) {
    shouldAskMonthlyLivingExpenses = false
  }

  return getDataTypes(
    shouldAskIsSameHouseHold,
    shouldAskMoveInDate,
    shouldAskMonthlyLivingExpenses,
    shouldAskNumberOfAdults,
    shouldAskNumberOfChildren,
  )
}

const getDataTypes = (
  shouldAskIsSameHouseHold: boolean,
  shouldAskMoveInDate: boolean,
  shouldAskMonthlyLivingExpenses: boolean,
  shouldAskNumberOfAdults: boolean,
  shouldAskNumberOfChildren: boolean,
): HousingInfoDataTypes[] => {
  return [
    shouldAskIsSameHouseHold && HousingInfoDataTypes.IsSameHouseHold,
    shouldAskMoveInDate && HousingInfoDataTypes.MoveInDate,
    shouldAskMonthlyLivingExpenses && HousingInfoDataTypes.MonthlyLivingExpenses,
    shouldAskNumberOfAdults && HousingInfoDataTypes.NumberOfAdults,
    shouldAskNumberOfChildren && HousingInfoDataTypes.NumberOfChildren,
  ].filter(typedBoolean)
}
