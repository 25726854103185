import { assertValue, isCollateralRequired } from '@shared'
import {
  LoanInfoPage,
  STEP_ABORTED,
  STEP_COLLATERAL_INFO,
  STEP_ECONOMIC_ASSESSMENT_INFO,
  STEP_INSURANCE_INFO,
  STEP_LOAN_ADDITIONAL_INFO,
  STEP_LOAN_BACKGROUND_INFO,
  STEP_MONTHLY_SAVINGS_INFO,
  STEP_PAYMENT_PLAN_INFO,
  STEP_REFERENCE_RATE_INFO,
  STEP_SUCCEEDED,
} from '@ui-common-sec-loans'
import { assign, setup } from 'xstate'

import { LoanInfoWizardDataType, WizardData } from '../../types'
import { LoanInfoWizardContext, LoanInfoWizardEvent } from './types'

export const LOAN_INFO_WIZARD_MACHINE_ID = 'loanInfoWizardMachine'

export {
  type LoanInfoPage,
  LoanInfoStep,
  STEP_ABORTED,
  STEP_COLLATERAL_INFO,
  STEP_ECONOMIC_ASSESSMENT_INFO,
  STEP_INSURANCE_INFO,
  STEP_LOAN_ADDITIONAL_INFO,
  STEP_LOAN_BACKGROUND_INFO,
  STEP_MONTHLY_SAVINGS_INFO,
  STEP_PAYMENT_PLAN_INFO,
  STEP_REFERENCE_RATE_INFO,
  STEP_SUCCEEDED,
} from '@ui-common-sec-loans'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const loanInfoWizardMachine = (initialStep: LoanInfoPage = STEP_LOAN_BACKGROUND_INFO) =>
  setup({
    types: {
      context: {} as LoanInfoWizardContext,
      input: {} as LoanInfoWizardContext,
      events: {} as LoanInfoWizardEvent,
    },
    actions: {
      saveFormData: assign(({ context }, data?: LoanInfoWizardDataType) => {
        return {
          ...context,
          data: {
            ...context.data,
            ...data?.loanInfo, // null when returning from edit without saving
          },
        }
      }),
    },
    guards: {
      isCollateralRequired: ({ context }) => {
        const { applicationType, applicationPurpose } = assertValue(
          context.applicationMetadata,
          'context.applicationMetadata',
        )
        const wizardData: WizardData = {
          loanInfo: context.data,
          targetInfo: context.targetInfoData,
          applicantInfo: context.applicantInfoData,
        }

        // deepcode ignore WrongNumberOfArguments: function does take three arguments
        return isCollateralRequired(applicationType, applicationPurpose, wizardData)
      },
    },
  }).createMachine({
    id: LOAN_INFO_WIZARD_MACHINE_ID,
    context: ({ input }) => input,
    initial: initialStep,
    states: {
      [STEP_LOAN_BACKGROUND_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_PAYMENT_PLAN_INFO,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
        },
      },
      [STEP_PAYMENT_PLAN_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_ECONOMIC_ASSESSMENT_INFO,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
          PREVIOUS_STEP: { target: STEP_LOAN_BACKGROUND_INFO },
        },
      },
      [STEP_ECONOMIC_ASSESSMENT_INFO]: {
        on: {
          NEXT_STEP: { target: STEP_REFERENCE_RATE_INFO, actions: ['saveFormData'] },
          PREVIOUS_STEP: { target: STEP_PAYMENT_PLAN_INFO },
        },
      },
      [STEP_REFERENCE_RATE_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_INSURANCE_INFO,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
          PREVIOUS_STEP: { target: STEP_ECONOMIC_ASSESSMENT_INFO },
        },
      },
      [STEP_INSURANCE_INFO]: {
        on: {
          NEXT_STEP: [
            {
              target: STEP_COLLATERAL_INFO,
              guard: 'isCollateralRequired',
              actions: { type: 'saveFormData', params: ({ event }) => event.data },
            },
            { target: STEP_MONTHLY_SAVINGS_INFO, actions: { type: 'saveFormData', params: ({ event }) => event.data } },
          ],
          PREVIOUS_STEP: { target: STEP_REFERENCE_RATE_INFO },
        },
      },
      [STEP_COLLATERAL_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_MONTHLY_SAVINGS_INFO,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
          PREVIOUS_STEP: { target: STEP_INSURANCE_INFO },
        },
      },
      [STEP_MONTHLY_SAVINGS_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_LOAN_ADDITIONAL_INFO,
            actions: { type: 'saveFormData', params: ({ event }) => event.data },
          },
          PREVIOUS_STEP: [
            { target: STEP_COLLATERAL_INFO, guard: 'isCollateralRequired' },
            { target: STEP_INSURANCE_INFO },
          ],
        },
      },
      [STEP_LOAN_ADDITIONAL_INFO]: {
        on: {
          NEXT_STEP: { target: STEP_SUCCEEDED, actions: { type: 'saveFormData', params: ({ event }) => event.data } },
          PREVIOUS_STEP: { target: STEP_MONTHLY_SAVINGS_INFO },
        },
      },
      [STEP_SUCCEEDED]: {
        id: STEP_SUCCEEDED,
        type: 'final',
      },
      [STEP_ABORTED]: {
        id: STEP_ABORTED,
        type: 'final',
      },
    },
    on: {
      EXIT_WIZARD: `.${STEP_ABORTED}`,
      GOTO_SUMMARY: {
        target: `.${STEP_SUCCEEDED}`,
        actions: { type: 'saveFormData', params: ({ event }) => event.data },
      },
    },
  })
