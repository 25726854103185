import { assertValue, getDifference, PriceInfoData, SecLoansHousingLoanTargetType } from '@shared'
import {
  convertUndefinedToNull,
  formatFinnishCurrencyValue,
  getYesNoDefault,
  isNumber,
  numberToString,
  toNumber,
  YesNo,
} from '@ui-common'
import { COMMON_PLACEHOLDER } from '@ui-components'
import { IntlShape } from 'react-intl'

import { PriceInfoStepData, StepDataValidator, WizardData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { PriceInfoFormData } from './PriceInfoStep'

export const convertToFormValues = (priceInfo: PriceInfoStepData | undefined): PriceInfoFormData => {
  return convertUndefinedToNull({
    price: numberToString(priceInfo?.price),
    netSellingPrice: numberToString(priceInfo?.netSellingPrice),
    isPayingCompanyLoanShare: getYesNoDefault(!!priceInfo, priceInfo?.isPayingCompanyLoanShare),
  })
}

export const convertToPriceInfoSubmitData = (formData: PriceInfoFormData): PriceInfoStepData => {
  const price = assertValue(toNumber(formData.price), 'price')
  const netSellingPrice = toNumber(formData.netSellingPrice)
  return {
    price: assertValue(price, 'price'),
    netSellingPrice,
    isPayingCompanyLoanShare:
      isNumber(netSellingPrice) && hasCompanyLoanShare(netSellingPrice, price)
        ? formData.isPayingCompanyLoanShare === YesNo.Yes
        : undefined,
  }
}

export const hasCompanyLoanShare = (netSellingPrice: number, price: number): boolean => {
  return !!netSellingPrice && !!price && price < netSellingPrice
}

export const resolveCompanyLoanShare = (netSellingPrice: number | undefined, price: number | undefined): string => {
  return !!netSellingPrice && !!price && hasCompanyLoanShare(netSellingPrice, price)
    ? formatFinnishCurrencyValue(getDifference(netSellingPrice, price))
    : COMMON_PLACEHOLDER
}

export const getAndValidatePriceTypeData: StepDataValidator<PriceInfoData | undefined> = (
  validationData: WizardData,
) => {
  const createValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, validationData)

  const { targetInfo } = validationData

  const isTargetTypeUnknown =
    targetInfo?.housingLoanInfo?.targetTypeInfo?.targetType === SecLoansHousingLoanTargetType.NotKnown

  let priceInfo = targetInfo?.priceInfo

  if (isTargetTypeUnknown && priceInfo?.netSellingPrice) {
    priceInfo = { price: assertValue(targetInfo?.priceInfo?.price, 'targetInfo.priceInfo.price') }
  }

  const isValidData = isFormDataValid(convertToFormValues(priceInfo), createValidator)

  return isValidData ? priceInfo : undefined
}
