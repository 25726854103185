import {
  calculateLoanTimeInMonths,
  OptionalAndNullable,
  SecLoansApplicationMetadata,
  SecLoansMethodOfRepayment,
} from '@shared'
import { isNumber, testIsValidMonetaryValue, toNumber } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import {
  MAX_CONSTANT_DURATION_LOAN_TIME_IN_YEARS,
  MAX_LOAN_TIME_IN_YEARS_CONSTANT_AMOUNT_REPAYMENT,
  MIN_CONSTANT_DURATION_LOAN_TIME_IN_YEARS,
} from '../../../../constants/constants'
import { PaymentPlanInfoFormData as FormData } from './PaymentPlanInfoStep'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  metadata: SecLoansApplicationMetadata,
  totalLoanAmount?: number,
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    test('methodOfRepayment', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.methodOfRepayment).inside(Object.values(SecLoansMethodOfRepayment))
    })

    test('repaymentFreeMonthsAmount', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.repaymentFreeMonthsAmount).isBetween(0, 12)
    })

    omitWhen(data.methodOfRepayment === SecLoansMethodOfRepayment.ConstantAmount, () => {
      test('loanTimeInYears', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.loanTimeInYears).isNotEmpty()
      })

      test('loanTimeInYears', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.loanTimeInYears).isBetween(
          MIN_CONSTANT_DURATION_LOAN_TIME_IN_YEARS,
          MAX_CONSTANT_DURATION_LOAN_TIME_IN_YEARS,
        )
      })
    })

    omitWhen(data.methodOfRepayment !== SecLoansMethodOfRepayment.ConstantAmount, () => {
      testIsValidMonetaryValue('approximatedRepayment', data.approximatedRepayment, formatMessage)

      test(
        'approximatedRepayment',
        formatMessage(
          { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
          {
            firstValue: formatMessage({
              id: 'sec-loans-payment-plan-monthly-repayment-approximation-without-euro-per-month',
            }),
            secondValue: formatMessage({ id: 'sec-loans-loans-and-credits-info-loan-credit-amount-form-label' }),
          },
        ),
        () => {
          enforce(toNumber(data.approximatedRepayment)).lessThan(totalLoanAmount)
        },
      )

      const approximatedRepayment = toNumber(data.approximatedRepayment)
      const estimatedLoanTimeInMonths =
        isNumber(totalLoanAmount) && isNumber(approximatedRepayment)
          ? calculateLoanTimeInMonths(totalLoanAmount, metadata.interestRate, approximatedRepayment)
          : undefined

      test(
        'approximatedRepayment',
        formatMessage(
          { id: 'sec-loans-payment-plan-validate-monthly-repayment-approximation-give-bigger' },
          { maxYears: MAX_LOAN_TIME_IN_YEARS_CONSTANT_AMOUNT_REPAYMENT },
        ),
        () => {
          enforce(estimatedLoanTimeInMonths).isNotEmpty()
          if (isNumber(estimatedLoanTimeInMonths)) {
            enforce(Math.ceil(estimatedLoanTimeInMonths)).lessThanOrEquals(
              MAX_LOAN_TIME_IN_YEARS_CONSTANT_AMOUNT_REPAYMENT * 12,
            )
          }
        },
      )
    })
  })
}
