import { ConstructionLoanTargetInfoData, LoanCostEstimateInfoData } from '@sec-loans-types'
import { getOptionAndOtherFormValues, resolveValueFromFormFields } from '@sec-loans-ui/utils/helpers'
import { StepDataValidator } from '@sec-loans-ui/wizard/types'
import { isFormDataValid } from '@sec-loans-ui/wizard/wizardData'
import { assertValue, getSquarePrice, OptionalAndNullable, SecLoansValidationConstants } from '@shared'
import { convertUndefinedToNull, formatFinnishCurrencyValue, isNumber, numberToString, toNumber } from '@ui-common'
import { IntlShape } from 'react-intl'

import { createFormValidator } from './formValidator'
import { ConstructionLoanTargetDataTypes, ConstructionLoanTargetInfoFormData } from './types'

export const getFormattedSquarePrice = (
  loanCostEstimateInfo: LoanCostEstimateInfoData | undefined,
  totalSquareArea: string | number | undefined,
): string | undefined => {
  const result = getSquarePrice(loanCostEstimateInfo, toNumber(totalSquareArea))
  return isNumber(result) ? formatFinnishCurrencyValue(result) : undefined
}

export const convertToConstructionLoanTargetInfoSubmitData = (
  formData: ConstructionLoanTargetInfoFormData,
): ConstructionLoanTargetInfoData => {
  const numberOfFloors = resolveValueFromFormFields(formData.numberOfFloors, formData.numberOfFloorsOther)
  const livingSquareArea = toNumber(formData.livingSquareArea)
  const totalSquareArea = toNumber(formData.totalSquareArea)

  return {
    constructionMaterial: formData.constructionMaterial,
    numberOfFloors: assertValue(numberOfFloors, 'numberOfFloors'),
    desiredCompletionDate: formData.desiredCompletionDate,
    livingSquareArea: assertValue(livingSquareArea, 'formData.livingSquareArea'),
    totalSquareArea: assertValue(totalSquareArea, 'formData.totalSquareArea'),
  }
}

export const convertToFormValues = (
  stepData: ConstructionLoanTargetInfoData | undefined,
): OptionalAndNullable<ConstructionLoanTargetInfoFormData> => {
  const numberOfFloors = getOptionAndOtherFormValues(
    stepData?.numberOfFloors,
    SecLoansValidationConstants.MIN_NUMBER_OF_FLOORS,
    SecLoansValidationConstants.MAX_NUMBER_OF_FLOORS_OPTION,
  )
  return convertUndefinedToNull({
    constructionMaterial: stepData?.constructionMaterial,
    numberOfFloors: numberOfFloors.option,
    desiredCompletionDate: stepData?.desiredCompletionDate,
    livingSquareArea: numberToString(stepData?.livingSquareArea),
    totalSquareArea: numberToString(stepData?.totalSquareArea),
    numberOfFloorsOther: numberOfFloors.other,
  })
}

export const getAndValidateConstructionLoanTargetTypeData: StepDataValidator<
  ConstructionLoanTargetInfoData | undefined
> = (validationData) => {
  const isValidData = isFormDataValid(
    convertToFormValues(validationData.targetInfo?.constructionLoanTargetInfo),
    createFormValidator,
  )
  return isValidData ? validationData.targetInfo?.constructionLoanTargetInfo : undefined
}

export const constructionLoanTargetLabel = (intl: IntlShape): Record<ConstructionLoanTargetDataTypes, string> => {
  return {
    [ConstructionLoanTargetDataTypes.ConstructionMaterial]: intl.formatMessage({
      id: 'sec-loans-construction-loan-target-info-construction-material-label',
    }),
    [ConstructionLoanTargetDataTypes.NumberOfFloors]: intl.formatMessage({
      id: 'sec-loans-construction-loan-target-info-number-of-floors-label',
    }),
    [ConstructionLoanTargetDataTypes.DesiredCompletionDate]: intl.formatMessage({
      id: 'sec-loans-construction-loan-target-info-desired-completion-date-label',
    }),
    [ConstructionLoanTargetDataTypes.LivingSquareArea]: intl.formatMessage({
      id: 'sec-loans-construction-loan-target-info-living-area-label',
    }),
    [ConstructionLoanTargetDataTypes.TotalSquareArea]: intl.formatMessage({
      id: 'sec-loans-construction-loan-target-info-total-area-label',
    }),
    [ConstructionLoanTargetDataTypes.PricePerSquareMeter]: intl.formatMessage({
      id: 'sec-loans-construction-loan-target-info-estimated-price-per-square-meter-label',
    }),
  }
}
