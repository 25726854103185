import { SecLoansReferenceRate } from '@sec-loans-types'
import { OptionalAndNullable } from '@shared'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, only, Suite, test } from 'vest'

import { ReferenceRateInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    test('referenceRate', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.referenceRate).inside(Object.values(SecLoansReferenceRate))
    })
  })
}
