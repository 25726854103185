import { FormTextArea } from '@ui-components/form/FormTextArea'
import { FormTextInputProps } from '@ui-components/global/ComponentProps'
import { PropsWithChildren } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

export interface TextAreaInputProps<T extends object> extends PropsWithChildren, Omit<FormTextInputProps, 'name'> {
  label: string
  fieldName: keyof T
  description?: string
  error: string | undefined
  maxValidationLength: number
  isRequired: boolean
}

export const TextAreaInput = <T extends object>(props: TextAreaInputProps<T>) => {
  const { children, fieldName, isRequired, maxValidationLength, ...rest } = props
  const intl = useIntl()

  const methods = useFormContext()

  const { register } = methods

  return (
    <FormTextArea
      maxLength={null}
      width="100%"
      additionalDescription={intl.formatMessage({ id: 'common-max-characters' }, { limit: maxValidationLength })}
      required={isRequired}
      {...register(fieldName as string, { shouldUnregister: true })}
      {...rest}
    >
      {children}
    </FormTextArea>
  )
}

TextAreaInput.displayName = 'TextAreaInput'
