import { DataProcessingLink } from '@components/common/DataProcessingLink'
import { getEntranceStepTopBarProps, simpleStepMachine, useLanguage } from '@ui-common'
import {
  ConfirmButtonProps,
  EntranceStep as CommonEntranceStep,
  LogoIconType,
  MediumText,
  NotificationProperties,
} from '@ui-components'
import { ReactElement } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { styled } from 'styled-components'
import { Actor } from 'xstate'

import heroImageAvif from '../../../assets/images/entrance-hero.avif'
import heroImage from '../../../assets/images/entrance-hero.jpg'
import { useSendSecLoansPageViewEvent } from '../../../hooks/useSendSecLoansPageViewEvent'
import { useAnalyticsService } from '../../../state/analyticsContext'
import { SimpleWizardStepProps } from '../../types'
import { RootStepType } from '../../wizardSteps'

const Footer = styled.div`
  p {
    margin-bottom: 16px;
  }
`

export interface SecLoansSimpleWizardStepProps extends SimpleWizardStepProps {
  showProcessingTimeNotification?: boolean
}

export type EntranceStepService = Actor<typeof simpleStepMachine>

export const EntranceStep = ({
  service,
  showAbortPromptModal,
  showProcessingTimeNotification,
}: SecLoansSimpleWizardStepProps): ReactElement => {
  const intl = useIntl()
  const { changeLanguage } = useLanguage()
  const { analyticsService } = useAnalyticsService()

  useSendSecLoansPageViewEvent(RootStepType.Entrance)

  const topBarProps = getEntranceStepTopBarProps(intl, showAbortPromptModal, changeLanguage)

  const confirmButtonProps: ConfirmButtonProps = {
    confirmLabel: intl.formatMessage({ id: 'sec-loans-entrance-continue-label' }),
    onConfirm: () => {
      analyticsService.sendStepCustomEvent(RootStepType.Entrance, 'ContinueButton')
      service.send({ type: 'CONTINUE' })
    },
  }

  const footerContent = (
    <Footer>
      <MediumText $color="primary">
        <FormattedMessage id="sec-loans-entrance-notice-text" values={{ 'link-location': DataProcessingLink }} />
      </MediumText>
    </Footer>
  )

  const showNotification: NotificationProperties | undefined = showProcessingTimeNotification
    ? {
        iconType: 'info',
        header: intl.formatMessage({ id: 'sec-loans-entrance-extended-time-text' }),
        status: 'info',
      }
    : undefined

  return (
    <CommonEntranceStep
      notificationProps={showNotification}
      icon={LogoIconType.SPankki}
      heroImage={{ fallback: heroImage, avif: heroImageAvif }}
      heading={intl.formatMessage({ id: 'sec-loans-entrance-heading' })}
      leadText={intl.formatMessage({ id: 'sec-loans-entrance-lead-text' })}
      infoText={intl.formatMessage({ id: 'sec-loans-entrance-language-info-text' })}
      confirmButtonProps={confirmButtonProps}
      footerContent={footerContent}
      {...topBarProps}
    />
  )
}
