import { ChangeEvent } from 'react'

export interface Month {
  index: number
  name: string
}

export const formatDate = (dateStr: string, locale: string): string => {
  const date = new Date(dateStr)
  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
  return dateTimeFormat.format(date)
}

export const getMonth = (index: number, locale: string): Month => {
  const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat(locale, { month: 'long' })
  const year = new Date().getFullYear()
  let name: string
  try {
    name = formatter.format(new Date(year, index))
  } catch (e) {
    name = 'Unknown month'
  }
  return { index, name }
}

export const getMonths = (
  locale: string,
  excludedMonths: number[] = [],
  excludeAlsoConsecutiveMonths = true,
): Month[] => {
  const months = Array.from({ length: 12 }, (_x, i) => i)
  if (excludeAlsoConsecutiveMonths) {
    excludedMonths.forEach((month) => {
      excludedMonths.push(month - 1 === -1 ? 11 : month - 1)
      excludedMonths.push(month + 1 === 12 ? 0 : month + 1)
    })
  }
  return months.filter((month) => excludedMonths.indexOf(month) === -1).map((index) => getMonth(index, locale))
}

export const fullDateStringToDate = (dateString: string | null | undefined): Date | null => {
  const dateParts = dateString?.trim().split('.') ?? []

  if (dateParts.length !== 3) {
    return null
  }

  const year = Number(dateParts[2])
  const month = Number(dateParts[1]) - 1 // Jan = 0
  const day = Number(dateParts[0])
  return new Date(year, month, day)
}

const BFF_DATE_SEPARATOR = '-'
const FE_DATE_SEPARATOR = '.'

const ensureDoubleDigit = (value: number): string => `0${value}`.slice(-2)

/**
 * Converts Date to FE date string
 */
export const dateToFormDateString = (date: Date): string =>
  [ensureDoubleDigit(date.getDate()), ensureDoubleDigit(date.getMonth() + 1), date.getFullYear()].join(
    FE_DATE_SEPARATOR,
  )

/**
 * Converts BFF date string to FE date string 2020-01-21 -> 21.01.2020
 */
export const bffDateStringToFormDateString = (dateString?: string): string | undefined =>
  dateString ? convertDate(dateString, FE_DATE_SEPARATOR, BFF_DATE_SEPARATOR) : undefined

/**
 * Converts FE form date string to bff date string: 21.01.2020 -> 2020-01-21
 */
export const formDateStringToBffDateString = (dateString?: string): string | undefined =>
  dateString ? convertDate(dateString, BFF_DATE_SEPARATOR, FE_DATE_SEPARATOR) : undefined

const convertDate = (dateString: string, targetSeparator: string, sourceSeparator: string) => {
  if (
    !dateString ||
    dateString.indexOf(sourceSeparator) < 0 ||
    dateString.length !== 10 ||
    dateString.split(sourceSeparator).length !== 3
  ) {
    throw new Error(`Date is not in correct format: ${dateString}`)
  }
  const dateParts = dateString.trim().split(sourceSeparator)
  return dateParts.reverse().join(targetSeparator)
}

export const textInputToDate = (dateString: string): Date => {
  const dateParts = dateString.trim().split('/')
  return new Date(Number(dateParts[1]), Number(dateParts[0]) - 1, 1, 0, 0, 0, 0)
}

export const getFirstOfCurrentMonth = (): Date => {
  const date = new Date()
  date.setDate(1)
  date.setHours(0, 0, 0, 0)
  return date
}

export const getDateDifferenceInFullYears = (date1: Date, date2: Date): number => {
  return Math.floor(Math.abs((date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24 * 365)))
}

export const getDateDifferenceInFullMonths = (date1: Date, date2: Date): number => {
  const diffYear = date2.getFullYear() - date1.getFullYear()
  const diffMonth = date2.getMonth() - date1.getMonth()

  return Math.abs(diffYear * 12 + diffMonth)
}

export function onDateInputChange(
  event: ChangeEvent<HTMLInputElement>,
  previousValue: string | undefined | null,
  wrapperSetValue: (val: string) => void,
  method?: (splitValue: string[]) => void,
): void {
  const newValue = event.target.value
  const trimmedValue = newValue.trim()

  if (/^[0-1]\d\/\d{4}$/.test(trimmedValue)) {
    // user pasted 02/2000 (no spaces, valid date or not)
    const split = trimmedValue.split('/')
    wrapperSetValue(`${split[0]} / ${split[1]}`)
  } else if (/^\d{2}$/.test(trimmedValue) && trimmedValue.length === 2) {
    // user pasted two digit 02
    wrapperSetValue(`${trimmedValue} / `)
  } else if (/^\d{3,}$/.test(trimmedValue)) {
    // user pasted 022000 or any number with more than 3 digits (valid date or not)
    wrapperSetValue(`${trimmedValue.substring(0, 2)} / ${trimmedValue.substring(2, 6)}`)
  } else if (/^[2-9]$/.test(newValue)) {
    // Single-digit February to September
    wrapperSetValue(`0${newValue} / `)
  } else if (/^1[\s/]$/.test(newValue)) {
    // Single-digit January special cases
    wrapperSetValue(`01 / `)
  } else if (
    typeof previousValue !== 'undefined' &&
    previousValue !== null &&
    previousValue.length === 1 &&
    /^(0[1-9]|10|11|12)$/.test(newValue)
  ) {
    // Handle double digits
    wrapperSetValue(`${newValue} / `)
  }

  if (method) {
    const splitValue = newValue.split(' / ')
    method(splitValue)
  }
}

export function onDateInputKeyDown(
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  wrapperSetValue: (val: string) => void,
): void {
  const { value } = e.currentTarget
  if (value === '1') {
    if (e.key === 'Tab' || e.key === 'ArrowRight') {
      e.preventDefault()
      wrapperSetValue(`0${value} / `)
    }
  } else if (/^(0[1-9]|10|11|12)\s\/\s$/.test(value) || value.endsWith(' / ')) {
    if (e.key === 'Backspace') {
      // prevent unwanted onDateInputChange 'additions' when removing characters
      wrapperSetValue('')
    }
  }
}
/**
 * @param startDateString - MM / YYYY
 * @param yearLimit - years
 * **/
export const isDateLessThanXYearsAgo = (startDateString: string, yearLimit: number): boolean => {
  const startDate = textInputToDate(startDateString)
  const currentMonth = getFirstOfCurrentMonth()
  const years = getDateDifferenceInFullYears(startDate, currentMonth)

  return years < yearLimit
}

/**
 * @param startDateString - MM / YYYY
 * @param monthLimit - months
 * **/
export const isDateLessThanXMonthsAgo = (startDateString: string, monthLimit: number): boolean => {
  const startDate = textInputToDate(startDateString)
  const currentMonth = getFirstOfCurrentMonth()
  const months = getDateDifferenceInFullMonths(startDate, currentMonth)

  return months < monthLimit
}
