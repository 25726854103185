import { assertValue, OptionalAndNullable, SecLoansApplicants, SecLoansInvestment, SecLoansProperty } from '@shared'
import { convertUndefinedToNull, getYesNoDefault, numberToString, toNumber, YesNo } from '@ui-common'
import { IntlShape } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

import { getValidatedData } from '../../../../utils/helpers'
import { ApplicantStepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { WealthInfoStepData } from './types'
import { WealthInfoStepFormData } from './WealthInfoStep'

export const convertToFormValues = (
  wealthInfoStepData: WealthInfoStepData | undefined,
): OptionalAndNullable<WealthInfoStepFormData> => {
  return convertUndefinedToNull({
    hasSavings: getYesNoDefault(!!wealthInfoStepData, wealthInfoStepData?.savingsAmount, YesNo.No),
    savingsAmount: numberToString(wealthInfoStepData?.savingsAmount),
    ownership: wealthInfoStepData?.ownership,
    hasProperties: getYesNoDefault(!!wealthInfoStepData, wealthInfoStepData?.properties?.length, YesNo.No),
    properties:
      wealthInfoStepData?.properties?.map((p) => ({
        ...p,
        id: uuidv4(),
        hasCompanyDebt: getYesNoDefault(!!p, p?.companyDebtAmount, YesNo.No),
        companyDebtAmount: numberToString(p.companyDebtAmount),
        monthlyPaymentToCompanyDebt: numberToString(p.monthlyPaymentToCompanyDebt),
        value: numberToString(p.value),
      })) || [],
    hasInvestments: getYesNoDefault(!!wealthInfoStepData, wealthInfoStepData?.investments?.length, YesNo.No),
    investments:
      wealthInfoStepData?.investments?.map((i) => ({
        ...i,
        id: uuidv4(),
        value: numberToString(i.value),
      })) || [],
  })
}

export const convertToWealthInfoSubmitData = (formData: WealthInfoStepFormData): WealthInfoStepData => {
  const properties =
    formData.hasProperties === YesNo.Yes
      ? formData.properties?.map((property): SecLoansProperty => {
          const hasCompanyDebt = property?.hasCompanyDebt === YesNo.Yes
          const value = assertValue(toNumber(property.value), 'property.value')
          return {
            propertyType: property.propertyType,
            companyDebtAmount: hasCompanyDebt ? toNumber(property.companyDebtAmount) : undefined,
            monthlyPaymentToCompanyDebt: hasCompanyDebt ? toNumber(property.monthlyPaymentToCompanyDebt) : undefined,
            ownership: property.ownership ?? SecLoansApplicants.FirstApplicant,
            value,
          }
        })
      : undefined
  const investments =
    formData.hasInvestments === YesNo.Yes
      ? formData.investments?.map((investment): SecLoansInvestment => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...rest } = investment
          const value = assertValue(toNumber(investment.value), 'investment.value')
          return {
            ...rest,
            value,
            ownership: investment.ownership ?? SecLoansApplicants.FirstApplicant,
          }
        })
      : undefined

  if (formData.savingsAmount) {
    return {
      savingsAmount: toNumber(formData.savingsAmount),
      ownership: formData.ownership ?? SecLoansApplicants.FirstApplicant,
      properties,
      investments,
    }
  }
  return {
    properties,
    investments,
  }
}

export const getAndValidateWealthInfoTypeData: ApplicantStepDataValidator<WealthInfoStepData> = (validationData) => {
  const wealthInfoData = validationData.applicantInfo?.wealthInfoData
  const hasCoApplicant = !!validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant

  const formValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, hasCoApplicant)
  const isValid = isFormDataValid(convertToFormValues(wealthInfoData), formValidator)

  return getValidatedData(wealthInfoData, isValid)
}
