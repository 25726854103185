import {
  assertValue,
  BackgroundInfoCommon,
  BackgroundInfoData,
  BackgroundInfoSecondApplicantData,
  Occupation,
  OptionalAndNullable,
  OtherOccupation,
  SecLoanApplicationType,
  SecLoanPurposeType,
  SecLoansHousingLoanTargetType,
} from '@shared'
import {
  convertUndefinedToNull,
  getYesNoDefault,
  numberToString,
  PHONE_COUNTRY_CODE,
  toNumber,
  trimPhoneNumber,
  YesNo,
} from '@ui-common'
import { IntlShape } from 'react-intl'

import { getAndValidateApplicantData } from '../../../../utils/helpers'
import { ApplicantStepDataValidator, BackgroundInfoStepBase, ValidatedData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { BackgroundInfoFormData, BackgroundInfoStepData, SecondApplicantBackgroundInfoFormData } from './types'

function sharedFields(formData: BackgroundInfoFormData): BackgroundInfoStepBase
function sharedFields(formData: SecondApplicantBackgroundInfoFormData): BackgroundInfoCommon
function sharedFields(
  formData: BackgroundInfoFormData | SecondApplicantBackgroundInfoFormData,
): BackgroundInfoStepBase | BackgroundInfoCommon {
  const {
    isFinnishCitizen,
    maritalStatus,
    highestDegree,
    isFirstHomeBuyer,
    isAspSaver,
    aspAmount,
    occupation,
    employmentType,
    hasValidEmploymentRelationship,
    phoneNumber,
    email,
  } = formData
  return {
    isFinnishCitizen: isFinnishCitizen === YesNo.Yes,
    maritalStatus,
    highestDegree,
    isFirstHomeBuyer: isFirstHomeBuyer === YesNo.Yes,
    aspAmount: isFirstHomeBuyer === YesNo.Yes && isAspSaver === YesNo.Yes ? toNumber(aspAmount) : undefined,
    occupation,
    employmentType: occupation === Occupation.Employed ? employmentType : undefined,
    hasValidEmploymentRelationship:
      occupation === OtherOccupation.OnFamilyLeave || occupation === OtherOccupation.Other
        ? hasValidEmploymentRelationship === YesNo.Yes
        : undefined,
    phoneNumber: `${PHONE_COUNTRY_CODE}${trimPhoneNumber(phoneNumber)}`,
    email: email || undefined,
    ...('bestTimeToReach' in formData ? { bestTimeToReach: formData.bestTimeToReach } : {}),
  }
}

export const convertToFormValues = (
  backgroundInfo: BackgroundInfoStepData | undefined,
  isSecondApplicant: boolean,
): OptionalAndNullable<BackgroundInfoFormData> => {
  const stepData = isSecondApplicant ? backgroundInfo?.secondApplicant : backgroundInfo
  const secondApplicantData =
    isSecondApplicant && backgroundInfo?.secondApplicant && 'firstName' in backgroundInfo.secondApplicant
      ? backgroundInfo.secondApplicant
      : undefined

  return convertUndefinedToNull({
    ...(isSecondApplicant
      ? {}
      : { bestTimeToReach: stepData && 'bestTimeToReach' in stepData ? stepData?.bestTimeToReach : undefined }),
    isFinnishCitizen: getYesNoDefault(!!stepData, stepData?.isFinnishCitizen),
    maritalStatus: stepData?.maritalStatus,

    highestDegree: stepData?.highestDegree,
    isFirstHomeBuyer: getYesNoDefault(!!stepData, stepData?.isFirstHomeBuyer),
    isAspSaver: getYesNoDefault(!!stepData, stepData?.aspAmount, YesNo.No),
    aspAmount: numberToString(stepData?.aspAmount),
    occupation: stepData?.occupation,
    hasValidEmploymentRelationship: getYesNoDefault(!!stepData, stepData?.hasValidEmploymentRelationship),
    employmentType: stepData?.employmentType,
    phoneNumber: stepData?.phoneNumber ? stepData?.phoneNumber?.replace(PHONE_COUNTRY_CODE, '') : undefined,
    email: stepData?.email,
    isSecondApplicant,
    firstName: secondApplicantData?.firstName,
    lastName: secondApplicantData?.lastName,
    ssn: secondApplicantData?.ssn,
    streetAddress: secondApplicantData?.streetAddress,
    postcode: secondApplicantData?.postcode,
    postOffice: secondApplicantData?.postOffice,
    serviceLanguage: secondApplicantData?.serviceLanguage,
  })
}

export const convertToFirstApplicantBackgroundInfoSubmitData = (
  formData: BackgroundInfoFormData,
): BackgroundInfoStepBase => {
  return sharedFields(formData)
}

export const convertToSecondApplicantBackgroundInfoSubmitData = (
  formData: BackgroundInfoFormData | SecondApplicantBackgroundInfoFormData,
): BackgroundInfoSecondApplicantData => {
  const commonFields = sharedFields(formData)
  const { firstName, lastName, ssn, streetAddress, postcode, postOffice, serviceLanguage } = formData
  if ('bestTimeToReach' in commonFields) {
    delete commonFields.bestTimeToReach
  }
  return {
    ...commonFields,
    firstName: assertValue(firstName, 'firstName'),
    lastName: assertValue(lastName, 'lastName'),
    serviceLanguage: assertValue(serviceLanguage, 'serviceLanguage'),
    ssn: assertValue(ssn, 'ssn').toUpperCase(),
    streetAddress: assertValue(streetAddress, 'streetAddress'),
    postcode: assertValue(postcode, 'postCode'),
    postOffice: assertValue(postOffice, 'postOffice'),
  }
}

export const getAndValidateBackgroundInfoTypeData: ApplicantStepDataValidator<BackgroundInfoData> = (
  validationData,
  applicationMetadata,
) => {
  const { applicantInfo, targetInfo } = validationData
  const backgroundInfoData = applicantInfo?.backgroundInfoData
  const hasCoApplicant = applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant
  const targetType = targetInfo?.housingLoanInfo?.targetTypeInfo?.targetType
  const { applicationType, applicationPurpose } = applicationMetadata ?? {}
  const isFirstTimeHomeBuyerQuestionRequired = getIsFirstTimeHomeBuyerQuestionRequired(
    assertValue(applicationType, 'applicationType'),
    assertValue(applicationPurpose, 'applicationPurpose'),
    targetType,
  )

  const validateApplicant = (isSecondApplicant: boolean) => {
    return isFormDataValid(
      convertToFormValues(backgroundInfoData, isSecondApplicant),
      (formatMessage: IntlShape['formatMessage']) =>
        createFormValidator(formatMessage, isFirstTimeHomeBuyerQuestionRequired, isSecondApplicant),
    )
  }

  // Typing is trickier here as secondApplicant has different data structure in BackgroundInfoData
  // compared to primary applicant and there is a mismatch with current generic types (type assertion needed).
  return getAndValidateApplicantData(
    validateApplicant,
    hasCoApplicant,
    backgroundInfoData,
  ) as ValidatedData<BackgroundInfoData>
}

export const getIsFirstTimeHomeBuyerQuestionRequired = (
  applicationType: SecLoanApplicationType,
  applicationPurpose: SecLoanPurposeType,
  targetType?: SecLoansHousingLoanTargetType,
): boolean => {
  const isHousingLoan = applicationType === SecLoanApplicationType.HousingLoan
  const isNotRightOfResidence = targetType !== SecLoansHousingLoanTargetType.RightOfResidence

  return (
    isHousingLoan &&
    isNotRightOfResidence &&
    [SecLoanPurposeType.Buy, SecLoanPurposeType.Construction].includes(applicationPurpose)
  )
}
