import {
  assertValue,
  HousingLoanInfo,
  OptionalAndNullable,
  SecLoanApplicationType,
  SecLoanPurposeType,
  SecLoansHousingLoanTargetType,
} from '@shared'
import {
  convertUndefinedToNull,
  numberToString,
  resolveBooleanInputToYesNo,
  resolveYesNoToBooleanInput,
  toNumber,
} from '@ui-common'
import { IntlShape } from 'react-intl'

import { StepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { LoanTransferTargetInfoFormData, LoanTransferTargetInputs } from './types'

export { targetTypeOptionsForLoanType } from '../target-type-info-step/targetTypeInfoStepUtils'

export const getAndValidateLoanTransferData: StepDataValidator<HousingLoanInfo | undefined> = (
  validationData,
  applicationMetadata,
) => {
  const { applicationType, applicationPurpose } = assertValue(applicationMetadata, 'applicationMetadata')
  const isFirstTimeHomeBuyerQuestionRequired = getIsFirstTimeHomeBuyerQuestionRequired(
    applicationType,
    applicationPurpose,
  )

  return isFormDataValid(
    convertToFormValues(validationData.targetInfo?.housingLoanInfo),
    (formatMessage: IntlShape['formatMessage']) =>
      createFormValidator({ formatMessage, isFirstTimeHomeBuyerQuestionRequired }),
  )
    ? validationData.targetInfo?.housingLoanInfo
    : undefined
}

export const convertToFormValues = (
  loanTransferStepData: HousingLoanInfo | undefined,
): OptionalAndNullable<LoanTransferTargetInfoFormData> => {
  const loanInfo = loanTransferStepData?.transferredLoanTargetData
  const loanTarget = loanTransferStepData?.targetTypeInfo
  return convertUndefinedToNull({
    ...loanInfo,
    loanTargetType: loanTarget?.targetType,
    loanAmount: numberToString(loanInfo?.loanAmount),
    resaleEstimate: numberToString(loanInfo?.resaleEstimate),
    purchasePrice: numberToString(loanInfo?.purchasePrice),
    loanMarginPercentage: numberToString(loanInfo?.loanMarginPercentage),
    loanMonthlyPayment: numberToString(loanInfo?.loanMonthlyPayment),
    isLoanForFirstTimePurchase: resolveBooleanInputToYesNo(loanInfo?.isLoanForFirstTimePurchase),
    isAspLoan: resolveBooleanInputToYesNo(loanInfo?.isAspLoan),
  })
}

export const convertFormToSubmitData = (formData: LoanTransferTargetInfoFormData): HousingLoanInfo => {
  const {
    loanIssuer,
    loanTargetType,
    moveInMonth,
    loanAmount,
    resaleEstimate,
    purchasePrice,
    loanMarginPercentage,
    loanMonthlyPayment,
    isLoanForFirstTimePurchase,
    isAspLoan,
  } = formData

  return {
    targetTypeInfo: { targetType: loanTargetType },
    transferredLoanTargetData: {
      loanIssuer: assertValue(loanIssuer, 'loanIssuer'),
      moveInMonth,
      loanAmount: assertValue(toNumber(loanAmount), 'loanAmount'),
      resaleEstimate: assertValue(toNumber(resaleEstimate), 'resaleEstimate'),
      purchasePrice: assertValue(toNumber(purchasePrice), 'purchasePrice'),
      loanMarginPercentage: toNumber(loanMarginPercentage),
      loanMonthlyPayment: assertValue(toNumber(loanMonthlyPayment), 'loanMonthlyPayment'),
      isLoanForFirstTimePurchase: resolveYesNoToBooleanInput(isLoanForFirstTimePurchase) ?? false,
      isAspLoan: resolveYesNoToBooleanInput(isAspLoan),
    },
  }
}

export const getLoanTargetInfoLabels = (
  intl: IntlShape,
  applicationType: SecLoanApplicationType,
): Record<LoanTransferTargetInputs, string> => {
  const isCottageOrPropertyInvestmentLoan = [
    SecLoanApplicationType.CottageLoan,
    SecLoanApplicationType.PropertyInvestment,
  ].includes(applicationType)

  const resaleEstimateLabel = isCottageOrPropertyInvestmentLoan
    ? intl.formatMessage({
        id: 'sec-loans-loan-transfer-target-item-resale-price-form-label',
      })
    : intl.formatMessage({
        id: 'sec-loans-loan-transfer-target-resale-price-form-label',
      })

  const purchasePriceLabel = isCottageOrPropertyInvestmentLoan
    ? intl.formatMessage({
        id: 'sec-loans-loan-transfer-target-item-purchase-price-form-label',
      })
    : intl.formatMessage({
        id: 'sec-loans-loan-transfer-target-purchase-price-form-label',
      })

  const moveInMonthLabel = isCottageOrPropertyInvestmentLoan
    ? intl.formatMessage({
        id: 'sec-loans-loan-transfer-target-item-move-in-date-form-label',
      })
    : intl.formatMessage({
        id: 'sec-loans-loan-transfer-target-move-in-date-form-label',
      })

  return {
    [LoanTransferTargetInputs.Header]: intl.formatMessage({
      id: 'sec-loans-loan-transfer-target-header',
    }),
    [LoanTransferTargetInputs.LoanTargetType]: intl.formatMessage({
      id: 'sec-loans-loan-transfer-target-type-form-label',
    }),
    [LoanTransferTargetInputs.LoanAmount]: intl.formatMessage({
      id: 'sec-loans-loan-transfer-target-loan-amount-form-label',
    }),
    [LoanTransferTargetInputs.ResaleEstimate]: resaleEstimateLabel,
    [LoanTransferTargetInputs.PurchasePrice]: purchasePriceLabel,
    [LoanTransferTargetInputs.MoveInMonth]: moveInMonthLabel,
    [LoanTransferTargetInputs.LoanIssuer]: intl.formatMessage({
      id: 'sec-loans-loan-transfer-target-loan-issuer-form-label',
    }),
    [LoanTransferTargetInputs.LoanMargin]: intl.formatMessage({
      id: 'sec-loans-loan-transfer-target-loan-margin-form-label',
    }),
    [LoanTransferTargetInputs.LoanPayment]: intl.formatMessage({
      id: 'sec-loans-loan-transfer-target-loan-montly-installment-form-label',
    }),
    [LoanTransferTargetInputs.IsFirstTimeBuyerLoan]: intl.formatMessage({
      id: 'sec-loans-loan-transfer-target-is-first-time-buyer-loan-form-label',
    }),
    [LoanTransferTargetInputs.isAspLoan]: intl.formatMessage({
      id: 'sec-loans-loan-transfer-target-is-asp-loan-form-label',
    }),
  }
}

export const getTargetTypeOptions = (
  applicationType: SecLoanApplicationType,
  applicationPurpose: SecLoanPurposeType,
): SecLoansHousingLoanTargetType[] => {
  const isLoanTransferToSbank = applicationPurpose === SecLoanPurposeType.LoanTransferToSbank
  const isCottageLoan = applicationType === SecLoanApplicationType.CottageLoan
  const isPropertyInvestment = applicationType === SecLoanApplicationType.PropertyInvestment

  if (isLoanTransferToSbank && (isCottageLoan || isPropertyInvestment)) {
    return [SecLoansHousingLoanTargetType.HousingStock, SecLoansHousingLoanTargetType.RealEstate]
  }

  return Object.keys(SecLoansHousingLoanTargetType).filter(
    (optionCandidate) => optionCandidate !== SecLoansHousingLoanTargetType.NotKnown,
  ) as SecLoansHousingLoanTargetType[]
}

export const getIsFirstTimeHomeBuyerQuestionRequired = (
  applicationType: SecLoanApplicationType,
  applicationPurpose: SecLoanPurposeType,
): boolean => {
  const isLoanTransferToSbank = applicationPurpose === SecLoanPurposeType.LoanTransferToSbank
  const isHousingLoan = applicationType === SecLoanApplicationType.HousingLoan
  return isLoanTransferToSbank && isHousingLoan
}
