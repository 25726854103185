import { LoanCostEstimateInfoData, SecLoanPurposeType } from '@sec-loans-types'
import { StepDataValidator } from '@sec-loans-ui/wizard/types'
import { isFormDataValid } from '@sec-loans-ui/wizard/wizardData'
import { assertValue, OptionalAndNullable, typedBoolean } from '@shared'
import { convertUndefinedToNull, formatFinnishCurrencyValue, isNumber, numberToString, toNumber } from '@ui-common'
import { IntlShape } from 'react-intl'

import { createFormValidator } from './formValidator'
import { CostEstimationDataTypes, LoanCostEstimateInfoFormData } from './types'

export const getTotalEstimate = (
  applicationPurpose: SecLoanPurposeType,
  loanCostEstimateInfoData: LoanCostEstimateInfoData | undefined,
): number | undefined => {
  const { costEstimate, ownFinancingShare, ownWorkShare, plotSellingPrice } = loanCostEstimateInfoData ?? {}
  if (applicationPurpose === SecLoanPurposeType.Construction) {
    return getConstructionTotalEstimate(
      costEstimate,
      ownFinancingShare,
      assertValue(ownWorkShare, 'ownWorkShare'),
      plotSellingPrice,
    )
  }

  if (applicationPurpose === SecLoanPurposeType.Renovation && isNumber(costEstimate) && isNumber(ownFinancingShare)) {
    return getRenovationLoanAmount(costEstimate, ownFinancingShare)
  }
}

export const getConstructionTotalEstimate = (
  costEstimate: number = 0,
  ownFinancingShare: number = 0,
  ownWorkShare: number = 0,
  plotSellingPrice: number = 0,
): number => {
  const totalPlotCost = costEstimate + plotSellingPrice
  const ownShare = ownFinancingShare + ownWorkShare
  const loanAmount = totalPlotCost - ownShare
  return loanAmount > 0 ? loanAmount : 0
}

export const getFormattedConstructionTotalEstimate = (
  costEstimate: number | undefined,
  ownFinancingShare: number | undefined,
  ownWorkShare: number | undefined,
  plotSellingPrice?: number,
): string | undefined => {
  const costEstimateNumber = toNumber(costEstimate)
  const ownFinancingShareNumber = toNumber(ownFinancingShare)
  const ownWorkShareNumber = toNumber(ownWorkShare)
  const plotSellingPriceNumber = toNumber(plotSellingPrice)
  if (!isNumber(costEstimateNumber) || !isNumber(ownFinancingShareNumber) || !isNumber(ownWorkShareNumber)) {
    return undefined
  }
  const result = getConstructionTotalEstimate(
    costEstimateNumber,
    ownFinancingShareNumber,
    ownWorkShareNumber,
    plotSellingPriceNumber,
  )
  return isNumber(result) ? formatFinnishCurrencyValue(result) : undefined
}

export const getRenovationLoanAmount = (costEstimate: number, ownFinancingShare: number): number => {
  const result = costEstimate - ownFinancingShare
  return result > 0 ? result : 0
}

export const getFormattedRenovationTotalEstimate = (
  costEstimate: number | undefined,
  ownFinancingShare: number | undefined,
): string | undefined => {
  if (!isNumber(costEstimate) || !isNumber(ownFinancingShare)) {
    return undefined
  }

  const loanAmount = getRenovationLoanAmount(costEstimate, ownFinancingShare)

  return formatFinnishCurrencyValue(loanAmount)
}

export const convertToFormValues = (
  stepData: LoanCostEstimateInfoData | undefined,
): OptionalAndNullable<LoanCostEstimateInfoFormData> => {
  return convertUndefinedToNull({
    plotOwnership: stepData?.plotOwnership,
    costEstimate: numberToString(stepData?.costEstimate),
    ownFinancingShare: numberToString(stepData?.ownFinancingShare),
    ownWorkShare: numberToString(stepData?.ownWorkShare),
    plotValue: numberToString(stepData?.plotValue),
    plotSellingPrice: numberToString(stepData?.plotSellingPrice),
  })
}

export const convertToLoanCostEstimateInfoSubmitData = (
  formData: LoanCostEstimateInfoFormData,
  purposeType: SecLoanPurposeType,
): LoanCostEstimateInfoData => {
  const costEstimate = toNumber(formData?.costEstimate)
  const ownFinancingShare = toNumber(formData?.ownFinancingShare)
  const ownWorkShare = toNumber(formData?.ownWorkShare)
  const plotSellingPrice = toNumber(formData?.plotSellingPrice)

  if (purposeType === SecLoanPurposeType.Renovation) {
    return {
      costEstimate: assertValue(costEstimate, 'costEstimate'),
      ownFinancingShare: assertValue(ownFinancingShare, 'ownFinancingShare'),
    }
  }

  return {
    plotOwnership: formData?.plotOwnership,
    costEstimate: assertValue(costEstimate, 'costEstimate'),
    ownFinancingShare: assertValue(ownFinancingShare, 'ownFinancingShare'),
    ownWorkShare,
    plotValue: toNumber(formData?.plotValue),
    plotSellingPrice,
  }
}

export const getAndValidateLoanCostEstimateTypeData: StepDataValidator<LoanCostEstimateInfoData | undefined> = (
  validationData,
  applicationMetadata,
) => {
  const metadata = assertValue(applicationMetadata, 'applicationMetadata')
  const isValidData = isFormDataValid(
    convertToFormValues(validationData.targetInfo?.loanCostEstimateInfo),
    (formatMessage: IntlShape['formatMessage']) => createFormValidator(formatMessage, metadata.applicationPurpose),
  )
  return isValidData ? validationData.targetInfo?.loanCostEstimateInfo : undefined
}

export const getShownInformation = (purposeType: SecLoanPurposeType, summary?: boolean): CostEstimationDataTypes[] => {
  let shouldAskPlotOwnership = false
  let shouldAskCostEstimate = false
  let shouldAskOwnFinancingShare = false
  let shouldAskOwnWorkShare = false
  let shouldShowInTotal = false

  const isConstruction = purposeType === SecLoanPurposeType.Construction
  const isRenovation = purposeType === SecLoanPurposeType.Renovation

  if (isConstruction) {
    shouldAskPlotOwnership = true
    shouldAskCostEstimate = true
    shouldAskOwnFinancingShare = true
    shouldAskOwnWorkShare = true
    shouldShowInTotal = !summary
  }
  if (isRenovation) {
    shouldAskPlotOwnership = false
    shouldAskCostEstimate = true
    shouldAskOwnFinancingShare = true
    shouldAskOwnWorkShare = false
    shouldShowInTotal = true
  }

  return getDataTypes(
    shouldAskPlotOwnership,
    shouldAskCostEstimate,
    shouldAskOwnFinancingShare,
    shouldAskOwnWorkShare,
    shouldShowInTotal,
  )
}

const getDataTypes = (
  shouldAskPlotOwnership: boolean,
  shouldAskCostEstimate: boolean,
  shouldAskOwnFinancingShare: boolean,
  shouldAskOwnWorkShare: boolean,
  shouldShowInTotal: boolean,
): CostEstimationDataTypes[] => {
  return [
    shouldAskPlotOwnership && CostEstimationDataTypes.PlotOwnership,
    shouldAskCostEstimate && CostEstimationDataTypes.CostEstimate,
    shouldAskOwnFinancingShare && CostEstimationDataTypes.OwnFinancingShare,
    shouldAskOwnWorkShare && CostEstimationDataTypes.OwnWorkShare,
    shouldShowInTotal && CostEstimationDataTypes.InTotal,
  ].filter(typedBoolean)
}

export const getLoanCostEstimationLabel = (
  intl: IntlShape,
  purposeType: SecLoanPurposeType,
): Record<CostEstimationDataTypes, string> => {
  return {
    [CostEstimationDataTypes.PlotOwnership]: intl.formatMessage({ id: 'sec-loans-cost-estimate-plot-ownership-label' }),
    [CostEstimationDataTypes.CostEstimate]:
      purposeType === SecLoanPurposeType.Renovation
        ? intl.formatMessage({
            id: 'sec-loans-renovation-loan-cost-estimate-cost-estimate-label',
          })
        : intl.formatMessage({
            id: 'sec-loans-construction-loan-cost-estimate-cost-estimate-label',
          }),
    [CostEstimationDataTypes.OwnFinancingShare]: intl.formatMessage({
      id: 'sec-loans-cost-estimate-own-finance-share-label',
    }),
    [CostEstimationDataTypes.OwnWorkShare]: intl.formatMessage({
      id: 'sec-loans-cost-estimate-own-work-share-label',
    }),
    [CostEstimationDataTypes.InTotal]:
      purposeType === SecLoanPurposeType.Renovation
        ? intl.formatMessage({ id: 'sec-loans-purchase-loan-amount-label' })
        : intl.formatMessage({
            id: 'sec-loans-loan-cost-estimate-preliminary-loan-amount',
          }),
    [CostEstimationDataTypes.PlotValue]: intl.formatMessage({
      id: 'sec-loans-cost-estimate-plot-value-label',
    }),
    [CostEstimationDataTypes.PlotSellingPrice]: intl.formatMessage({
      id: 'sec-loans-cost-estimate-plot-selling-price',
    }),
  }
}
