import { AuthenticationMode, Endpoint, ErrorCode, ErrorResponse, Language, VerifyAuthCodeResponse } from '@shared-types'

export type VerifyAuthCodeEndpoint = Endpoint<
  VerifyAuthCodeResponse,
  {
    authCode: string
    sourceChannel: string
    uiLanguage: Language
  },
  string,
  string
>

export type AuthenticationState = {
  isCancelled: boolean
  isLoading: boolean
  hasError: boolean
  error?: ErrorResponse<ErrorCode>
  abort: () => void
  retry: () => void
}

export interface AuthenticateOutputActions {
  onExit: () => void
  onAuthenticated: (authToken: string) => void
  onAuthenticationCancelled?: () => void
}

export interface AuthenticateInput extends AuthenticateOutputActions {
  authMode: AuthenticationMode
  verifyAuthCodeCallback: (authCode: string) => Promise<VerifyAuthCodeResponse>
}

export const AuthenticationCancelled = 'AUTHENTICATION_CANCELLED'
export type AuthenticationCancelledEvent = {
  type: typeof AuthenticationCancelled
}
