import { SecLoansLoanOrCredit } from '@shared'
import { YesNo } from '@ui-common'

import {
  BaseWizardContext,
  HierarchicalApplicationData,
  LoansAndCreditsInfoStepData,
  LoansAndCreditsInfoStepDataType,
} from '../../../types'

export const MAX_NUMBER_OF_QUICK_LOANS_OPTION = 4

export interface LoansAndCreditsInfoStepContext extends LoansAndCreditsInfoStepDataType, BaseWizardContext {}

export interface SecondApplicantFormData
  extends Omit<
    LoansAndCreditsInfoStepData,
    'loansAndCredits' | 'quickLoansAmount' | 'quickLoansInstallment' | 'numberOfQuickLoans'
  > {
  hasQuickLoans?: YesNo
  numberOfQuickLoans?: string
  numberOfQuickLoansOther?: string | null
  quickLoansAmount?: string
  quickLoansInstallment?: string
}

export type SecLoansLoanOrCreditForm = Omit<SecLoansLoanOrCredit, 'amount' | 'installment' | 'ownership'> & {
  id: string
  amount?: string
  installment?: string | null
  isTransferredLoan?: boolean
  hasStartedPayingBackStudentLoan?: YesNo
}

export type LoansAndCreditsInfoStepFormData = Omit<
  LoansAndCreditsInfoStepData,
  'secondApplicant' | 'quickLoansAmount' | 'quickLoansInstallment' | 'loansAndCredits' | 'numberOfQuickLoans'
> &
  HierarchicalApplicationData & {
    numberOfQuickLoans?: string | null
    numberOfQuickLoansOther?: string | null
    hasQuickLoans?: YesNo
    secondApplicant?: SecondApplicantFormData
    quickLoansAmount?: string
    quickLoansInstallment?: string

    firstApplicantHasLoansAndCredits?: YesNo
    secondApplicantHasLoansAndCredits?: YesNo
    hasSharedLoansAndCredits?: YesNo

    firstApplicantLoansAndCredits?: SecLoansLoanOrCreditForm[]
    secondApplicantLoansAndCredits?: SecLoansLoanOrCreditForm[]
    sharedLoansAndCredits?: SecLoansLoanOrCreditForm[]
  }
