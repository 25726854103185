import { AppName, ENDPOINT_STATUS } from '@shared'

import { CommonQueryParam, MaintenancePageQueryParams } from '../types'
import { debugLog, getSourceChannel, skipBffStatusCheck } from './helpers'
import { callEndpoint } from './http/client'

export const PROD_MAINTENANCE_PAGE_URL = 'https://huolto.digialusta.s-cloud.fi'
export const TEST_MAINTENANCE_PAGE_URL = 'https://maintenance-proxy.digipl-dev.int-aw.s-cloud.fi'
export const LOCAL_MAINTENACE_PAGE_URL = 'http://localhost:3010'

function maintenancePageUrl(envId: string) {
  switch (envId) {
    case 'prod':
      return PROD_MAINTENANCE_PAGE_URL
    case 'local':
      return LOCAL_MAINTENACE_PAGE_URL
    default:
      return TEST_MAINTENANCE_PAGE_URL
  }
}

export const redirectToMaintenancePageIfNeeded = async (envId: string, appName: AppName): Promise<void> => {
  if (!skipBffStatusCheck()) {
    try {
      await callEndpoint(ENDPOINT_STATUS, { requestConfig: { maxRetryAfterMs: 5000 } })
    } catch (e) {
      debugLog('BFF status is not ok', e)

      const origUrl = new URL(window.location.href)
      const redirectUrl = new URL(maintenancePageUrl(envId))
      for (const [key, value] of origUrl.searchParams) {
        redirectUrl.searchParams.append(key, value)
      }

      const sourceChannel = getSourceChannel()
      if (sourceChannel) {
        redirectUrl.searchParams.set(CommonQueryParam.SourceChannel, sourceChannel)
      }
      redirectUrl.searchParams.append(MaintenancePageQueryParams.DigiPlatformAppName, appName)
      window.location.replace(redirectUrl)

      // Delay resolve to prevent state machine from continuing before redirect
      // which could cause this service call being interrupted
      return new Promise((resolve) => setTimeout(() => resolve(), 500))
    }
  }
}
