import { OptionalAndNullable, SecLoansValidationConstants } from '@shared'
import { hasPostalCodeOnly, validateShortTextInput } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { AddressInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    validateShortTextInput(
      'street',
      formatMessage,
      data.street,
      SecLoansValidationConstants.SHORT_TEXT_INPUT_MAX_LENGTH,
    )
    test('postCode', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.postCode).isNotEmpty()
    })
    test('postCode', formatMessage({ id: 'validate-common-finnish-postcode-length' }), () => {
      enforce(data.postCode).hasPostalCodeOnly()
    })
    omitWhen(!hasPostalCodeOnly(data.postCode ?? '') || data.postOffice === undefined, () => {
      test('postCode', formatMessage({ id: 'common-unknown-postcode' }), () => {
        enforce(data.postOffice).isNotNull()
        enforce(data.postOffice).longerThan(0)
      })
    })
  })
}
