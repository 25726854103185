import { OptionalAndNullable, SecLoansCollateralType, SecLoansValidationConstants } from '@shared'
import { validateTextArea, YesNo } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { CollateralInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    test('hasCollaterals', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasCollaterals).inside(Object.values(YesNo))
    })

    omitWhen(data.hasCollaterals !== YesNo.Yes, () => {
      data.collaterals?.forEach((collateral, index) => {
        test(`collaterals.${index}.collateralType`, formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(collateral?.collateralType).inside(Object.values(SecLoansCollateralType))
        })
        omitWhen(!collateral?.additionalInfo, () => {
          const isAdditionalInfoRequired = true

          validateTextArea(
            formatMessage,
            `collaterals.${index}.additionalInfo`,
            collateral?.additionalInfo,
            SecLoansValidationConstants.COLLATERAL_ADDITIONAL_INFO_MAX_LENGTH,
            isAdditionalInfoRequired,
          )
        })
      })
    })
  })
}
