import { SecLoansCreditCardTypes } from '@sec-loans-types'
import { assertValue } from '@shared'
import { testIsValidMonetaryValue, toInteger, validateIntegerFromListOrOther, YesNo } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { CreditInfoStepFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  hasCoApplicant: boolean,
): Suite<string, string, (data: FormData, changedField?: Path<FormData>) => void> => {
  return create((data, changeField) => {
    only(changeField)

    test('hasCreditCards', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasCreditCards).inside(Object.values(YesNo))
    })

    omitWhen(data.hasCreditCards !== YesNo.Yes, () => {
      validateIntegerFromListOrOther(
        'numberOfCreditCards',
        formatMessage,
        data.numberOfCreditCards,
        data.numberOfCreditCardsOther,
      )
      testIsValidMonetaryValue('creditLimit', data.creditLimit, formatMessage)
      test('cardTypes', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.cardTypes).isNotEmpty()
      })
      omitWhen(!data.cardTypes || !data.numberOfCreditCards, () => {
        test(
          'cardTypes',
          formatMessage(
            { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
            {
              firstValue: formatMessage({ id: 'sec-loans-applicant-info-credit-card-types-label' }),
              secondValue: formatMessage({ id: 'common-number-of-pieces' }),
            },
          ),
          () => {
            const numberOfCards =
              data.numberOfCreditCardsOther ?? assertValue(data.numberOfCreditCards, 'data.numberOfCreditCards')
            const cardTypes = assertValue(data.cardTypes, 'cardTypes')
            enforce(toInteger(numberOfCards) >= cardTypes.length).isTruthy()
          },
        )

        Object.keys(data.cardTypes ?? []).forEach((_key, index) => {
          test(`cardTypes.${index}`, formatMessage({ id: 'validate-required-field-missing' }), () => {
            enforce(data.cardTypes?.[index]).inside(Object.values(SecLoansCreditCardTypes))
          })
        })
      })
    })

    omitWhen(!hasCoApplicant, () => {
      test('secondApplicant.hasCreditCards', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.secondApplicant?.hasCreditCards).inside(Object.values(YesNo))
      })
      omitWhen(data.secondApplicant?.hasCreditCards !== YesNo.Yes, () => {
        validateIntegerFromListOrOther(
          'secondApplicant.numberOfCreditCards',
          formatMessage,
          data.secondApplicant?.numberOfCreditCards,
          data.secondApplicant?.numberOfCreditCardsOther,
        )
        testIsValidMonetaryValue('secondApplicant.creditLimit', data.secondApplicant?.creditLimit, formatMessage)
        test('secondApplicant.cardTypes', formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(data.secondApplicant?.cardTypes).isNotEmpty()
        })
        omitWhen(!data.secondApplicant?.cardTypes || !data.secondApplicant?.numberOfCreditCards, () => {
          test(
            'secondApplicant.cardTypes',
            formatMessage(
              { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
              {
                firstValue: formatMessage({ id: 'sec-loans-applicant-info-credit-card-types-label' }),
                secondValue: formatMessage({ id: 'common-number-of-pieces' }),
              },
            ),
            () => {
              const numberOfCards =
                data.secondApplicant?.numberOfCreditCardsOther ??
                assertValue(data.secondApplicant?.numberOfCreditCards, 'data.secondApplicant.numberOfCreditCards')
              const cardTypes = assertValue(data.secondApplicant?.cardTypes, 'data.secondApplicant.cardTypes')
              enforce(toInteger(numberOfCards) >= cardTypes.length).isTruthy()
            },
          )

          Object.keys(data.secondApplicant?.cardTypes ?? []).forEach((_key, index) => {
            test(`secondApplicant.cardTypes.${index}`, formatMessage({ id: 'validate-required-field-missing' }), () => {
              enforce(data.secondApplicant?.cardTypes?.[index]).inside(Object.values(SecLoansCreditCardTypes))
            })
          })
        })
      })
    })
  })
}
