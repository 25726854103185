import { PromptCancelledEvent, PromptConfirmedEvent } from '@components/state'
import { assertValue, AuthenticateResponse, ENDPOINT_AUTHENTICATE, ErrorCode, ErrorResponse } from '@shared'
import {
  AuthCallbackResult,
  cleanUpAuthQueryParams,
  isCancelledCallbackResult,
  parseAuthCode,
} from '@utils/authentication'
import { debugLog, isErrorResponse } from '@utils/helpers'
import { callEndpoint } from '@utils/http'
import { getLanguage } from '@utils/language'
import { setAuthorizationHeaderBearerToken } from '@utils/network'
import axios from 'axios'
import { assign, ErrorActorEvent, fromPromise, setup } from 'xstate'

import { AuthenticateInput } from './types'

type AbortEvent = { type: 'ABORT' }
interface AuthenticateStepContext {
  authUrl: string
  authCode: string
  error?: ErrorResponse<ErrorCode>
}

export type AuthenticateStepEvent =
  | PromptCancelledEvent
  | PromptConfirmedEvent
  | AbortEvent
  | { type: 'AUTHENTICATE' }
  | { type: 'PARSE_AUTH_CODE' }

export const GET_AUTH_URL = 'getAuthUrl'
export const GET_AUTH_CODE = 'getAuthCode'
export const PARSE_AUTH_CODE = 'parseAuthCode'
export const VERIFY_AUTH_CODE = 'verifyAuthCode'
export const AUTH_ERROR = 'authError'
export const AUTH_CANCELLED = 'authCancelled'

export const authenticateStepMachine = (
  { verifyAuthCodeCallback, onExit, onAuthenticated, onAuthenticationCancelled, authMode }: AuthenticateInput,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) =>
  setup({
    types: {} as {
      context: AuthenticateStepContext
      events: AuthenticateStepEvent
      input: AuthenticateStepContext
    },
    actors: {
      getAuthUrl: fromPromise((): Promise<AuthenticateResponse> => {
        debugLog('Service: authenticate')
        const lang = getLanguage()
        return callEndpoint(ENDPOINT_AUTHENTICATE, { queryParams: { lang, authMode } })
      }),
      parseAuthCode: fromPromise((): Promise<AuthCallbackResult> => {
        debugLog('Service: parseAuthCode')
        return parseAuthCode()
      }),
      verifyAuthCode: fromPromise(({ input }: { input: string }) => {
        debugLog('Service: verifyAuthCode')
        return verifyAuthCodeCallback(input)
      }),
    },
    actions: {
      storeAuthUrl: assign((_, authUrl: string) => {
        debugLog('Action: Store auth url')
        return {
          authUrl,
        }
      }),
      storeAuthCode: assign((_, authCode?: string) => {
        assertValue(authCode)
        debugLog('Action: Store auth code')
        return {
          authCode,
        }
      }),
      exit: () => onExit(),
      checkAuthCancelCallback: () => {
        onAuthenticationCancelled?.()
      },
      proceedToNextStep: (_, authToken: string) => onAuthenticated(authToken),
      setAuthorizationHeaderBearerToken: (_, authToken: string) => {
        debugLog('Action: setAuthorizationHeaderBearerToken')
        setAuthorizationHeaderBearerToken(axios, authToken)
      },
      cleanupAuthParam: () => {
        debugLog('Action: setAuthorizationHeaderBearerToken')
        cleanUpAuthQueryParams()
      },
      redirectToAuth: (_, authUrl: string) => {
        debugLog('Action: redirectToAuth')
        window.location.replace(authUrl)
      },
      storeError: assign((_, error: unknown) => {
        debugLog(error)
        return { error: isErrorResponse(error) ? error : undefined }
      }),
    },
    guards: {
      authCodeWasFound: (_, authCode?: string) => {
        const result = !!authCode
        debugLog(`Guard: authCodeWasFound: ${result}`)
        return result
      },
      isCancelledByUser: (_, data: AuthCallbackResult): boolean => isCancelledCallbackResult(data),
    },
  }).createMachine({
    id: 'authenticateStepMachine',
    context: ({ input }) => input,
    initial: GET_AUTH_URL,
    states: {
      [GET_AUTH_URL]: {
        invoke: {
          src: 'getAuthUrl',
          onDone: {
            actions: { type: 'storeAuthUrl', params: ({ event }) => event.output.authUrl },
            target: GET_AUTH_CODE,
          },
          onError: {
            target: AUTH_ERROR,
          },
        },
      },
      [GET_AUTH_CODE]: {
        entry: { type: 'redirectToAuth', params: ({ context }) => context.authUrl },
        on: {
          PARSE_AUTH_CODE,
        },
      },
      [PARSE_AUTH_CODE]: {
        invoke: {
          src: 'parseAuthCode',
          onDone: {
            guard: { type: 'authCodeWasFound', params: ({ event }) => event.output.authCode },
            target: VERIFY_AUTH_CODE,
            actions: [
              {
                type: 'storeAuthCode',
                params: ({ event }) => event.output.authCode,
              },
              { type: 'cleanupAuthParam' },
            ],
          },
          onError: [
            {
              guard: {
                type: 'isCancelledByUser',
                params: ({ event }) => event.error as AuthCallbackResult,
              },
              target: AUTH_CANCELLED,
              actions: [{ type: 'cleanupAuthParam' }],
            },
            {
              target: AUTH_ERROR,
              actions: [
                { type: 'cleanupAuthParam' },
                {
                  type: 'storeError',
                  params: ({ event }: { event: ErrorActorEvent }) => event.error,
                },
              ],
            },
          ],
        },
      },
      [AUTH_CANCELLED]: {
        id: AUTH_CANCELLED,
        entry: [{ type: 'checkAuthCancelCallback' }],
        on: {
          AUTHENTICATE: GET_AUTH_URL,
          ABORT: { actions: { type: 'exit' } },
        },
      },
      [AUTH_ERROR]: {
        id: AUTH_ERROR,
        on: {
          AUTHENTICATE: GET_AUTH_URL,
          ABORT: { actions: { type: 'exit' } },
        },
      },
      [VERIFY_AUTH_CODE]: {
        invoke: {
          src: 'verifyAuthCode',
          input: ({ context }) => context.authCode,
          onDone: {
            actions: [
              {
                type: 'setAuthorizationHeaderBearerToken',
                params: ({ event }) => event.output.authToken,
              },
              {
                type: 'proceedToNextStep',
                params: ({ event }) => event.output.authToken,
              },
            ],
          },
          onError: {
            target: AUTH_ERROR,
            actions: {
              type: 'storeError',
              params: ({ event }: { event: ErrorActorEvent }) => event.error,
            },
          },
        },
      },
    },
  })
