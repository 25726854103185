import { assertValue } from '@shared'
import { usePrefersReducedMotion } from '@ui-components/hooks/usePrefersReducedMotion'
import { useCallback, useEffect } from 'react'
import type { FieldErrors, FieldErrorsImpl, FieldValues } from 'react-hook-form'
import * as R from 'remeda'

type UseScrollToFormErrorReturn = {
  triggerScroll: () => void
}
export function useScrollToFormError<T extends FieldValues>(errors: FieldErrors<T>): UseScrollToFormErrorReturn {
  const { isReducedMotion } = usePrefersReducedMotion()

  const scrollToFirstErroredFormElement = useCallback(() => {
    const erroredElements: HTMLElement[] = []
    const erroredInputNames = Object.keys(errors)
    erroredInputNames.forEach((name) => {
      const error = errors[name]
      populateErroredElementList(erroredElements, name, error)
    })
    if (erroredElements.length > 0) {
      const topElement = R.firstBy(erroredElements, (element) => element.getBoundingClientRect().y)
      topElement?.focus({ preventScroll: true })
      topElement?.scrollIntoView({ behavior: isReducedMotion ? 'instant' : 'smooth', block: 'center' })
    }
  }, [errors, isReducedMotion])

  useEffect(() => {
    scrollToFirstErroredFormElement()
  }, [scrollToFirstErroredFormElement])

  return { triggerScroll: scrollToFirstErroredFormElement }
}

function populateErroredElementList(
  erroredElements: HTMLElement[],
  name: string,
  error: DynamicFieldErrorArray | FieldErrors[string],
) {
  if (!error) {
    return
  }
  if (isDynamicFieldErrorArray(error)) {
    // Empty entries exist in dynamic error array to represent non-errored fields
    const firstDynamicFieldErrorIdx = error.findIndex((entries) => !!entries)
    const fieldError = assertValue(error[firstDynamicFieldErrorIdx], `error[${firstDynamicFieldErrorIdx}]'`)
    const erroredFieldNames = Object.keys(fieldError)
    erroredFieldNames.forEach((fieldName) => {
      const dynamicElementName = name + '.' + firstDynamicFieldErrorIdx + '.' + fieldName
      populateErroredElementList(
        erroredElements,
        dynamicElementName,
        assertValue(error[firstDynamicFieldErrorIdx], `error[${firstDynamicFieldErrorIdx}]`)[fieldName],
      )
    })
  } else if (!isLeafError(error)) {
    const nestedErrorNames = Object.keys(error)
    nestedErrorNames.forEach((nestedName) => {
      const pathName = name + '.' + nestedName
      // gross casting used as some weird typing errors on, but safe enough as we are getting the keys from the object
      populateErroredElementList(erroredElements, pathName, (error as unknown as Record<string, object>)[nestedName])
    })
  } else {
    const element = getErroredElementByName(name)
    // element can be undefined if it hasn't been added to DOM yet
    // ie) when dynamic array children show after first item filled
    //      see PaymentInfoStep > hasAbroadPaymentsIn > monthlyCount shown when countryName filled
    if (element) {
      erroredElements.push(element)
    }
  }
}

type DynamicFieldErrorArray = Record<string, FieldErrorsImpl>[]
function isDynamicFieldErrorArray(error: unknown): error is DynamicFieldErrorArray {
  return R.isArray(error)
}

function isLeafError(error: object): error is FieldErrorsImpl {
  return error.hasOwnProperty('message') && error.hasOwnProperty('ref')
}

function getErroredElementByName(name: string): HTMLElement | undefined {
  // element name attribute is set to the input's registered value
  return document.getElementsByName(name)[0]
}
