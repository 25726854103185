import { SecLoanPurposeType, TargetAdditionalInfoData } from '@shared'
import { convertUndefinedToNull, resolveFormEmptyString } from '@ui-common'
import { IntlShape } from 'react-intl'

import { StepDataValidator, TargetAdditionalInfoStepData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { TargetAdditionalInfoFormData } from './types'

export const getAndValidateAdditionalInfoTypeData: StepDataValidator<TargetAdditionalInfoData | undefined> = (
  validationData,
) => {
  let additionalInfo = validationData.targetInfo?.additionalInfoData
  const hasRenovationCosts =
    validationData.targetInfo?.renovationsInfoData?.renovationsAmount ||
    validationData.targetInfo?.renovationsInfoData?.otherPurchasesAmount

  if (!hasRenovationCosts && !!additionalInfo?.additionalRenovationsInfo) {
    additionalInfo = { ...validationData.targetInfo?.additionalInfoData, additionalRenovationsInfo: undefined }
  }

  const createValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, validationData)

  const isValid = isFormDataValid(additionalInfo, createValidator)
  return isValid ? additionalInfo : undefined
}

export const convertToFormValues = (
  targetAdditionalInfoStepData: TargetAdditionalInfoStepData | undefined,
): TargetAdditionalInfoFormData => {
  const data = {
    additionalInfo: targetAdditionalInfoStepData?.additionalInfo,
    additionalRenovationsInfo: targetAdditionalInfoStepData?.additionalRenovationsInfo,
  }
  return convertUndefinedToNull(data)
}

export const convertToSubmitData = (formData: TargetAdditionalInfoFormData): TargetAdditionalInfoStepData => {
  return {
    additionalInfo: resolveFormEmptyString(formData?.additionalInfo),
    additionalRenovationsInfo: resolveFormEmptyString(formData?.additionalRenovationsInfo),
  }
}

export const getAdditionalInfoDescription = (intl: IntlShape, purpose: SecLoanPurposeType): string | undefined => {
  const isConstructionPurpose = purpose === SecLoanPurposeType.Construction
  const isRenovationPurpose = purpose === SecLoanPurposeType.Renovation

  if (isConstructionPurpose) {
    return intl.formatMessage({ id: 'sec-loans-additional-target-info-construction-purpose-description' })
  }
  if (isRenovationPurpose) {
    return intl.formatMessage({ id: 'sec-loans-additional-target-info-renovation-purpose-description' })
  }
  return intl.formatMessage({ id: 'sec-loans-additional-target-info-description' })
}
