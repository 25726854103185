import { OptionalAndNullable, SecLoansValidationConstants } from '@shared'
import { validateTextArea } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, only, Suite } from 'vest'

import { AdditionalInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)
    const isAdditionalInfoRequired = false

    validateTextArea(
      formatMessage,
      'additionalInfo',
      data.additionalInfo,
      SecLoansValidationConstants.ADDITIONAL_INFO_MAX_LENGTH,
      isAdditionalInfoRequired,
    )
  })
}
