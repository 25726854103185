import { OptionalAndNullable, ReferenceRateInfoData } from '@shared'
import { convertUndefinedToNull } from '@ui-common'

import { ReferenceRateStepInfoData, StepDataValidator, WizardData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { ReferenceRateInfoFormData } from './types'

export const getAndValidateReferenceRateInfoTypeData: StepDataValidator<ReferenceRateInfoData | undefined> = (
  validationData: WizardData,
) => {
  return isFormDataValid(validationData.loanInfo?.referenceRateInfoData, createFormValidator)
    ? validationData.loanInfo?.referenceRateInfoData
    : undefined
}

export const convertToFormValues = (
  referenceRateInfoData: ReferenceRateStepInfoData | undefined,
): OptionalAndNullable<ReferenceRateInfoFormData> => {
  const data = {
    referenceRate: referenceRateInfoData?.referenceRate,
  }
  return convertUndefinedToNull(data)
}
