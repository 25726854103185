import { assertValue, CreditInfoBase } from '@shared'
import { convertUndefinedToNull, getYesNoDefault, numberToString, toNumber, YesNo } from '@ui-common'
import { IntlShape } from 'react-intl'

import {
  getCompletenessForTwoApplicants,
  getOptionAndOtherFormValues,
  getValidatedData,
  resolveValueFromFormFields,
} from '../../../../utils/helpers'
import { ApplicantStepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import {
  CreditInfoStepData,
  CreditInfoStepFormData,
  IncomeFormDataBase,
  MAX_NUMBER_OF_CREDIT_CARDS_OPTION,
} from './types'

export const convertToFormValues = (
  creditInfoData: CreditInfoStepData | undefined,
  hasCoApplicant: boolean,
): CreditInfoStepFormData => {
  return convertUndefinedToNull({
    ...resolveNumberOfCreditCards(creditInfoData?.numberOfCreditCards),
    hasCreditCards: getYesNoDefault(!!creditInfoData, creditInfoData?.numberOfCreditCards, YesNo.No),
    creditLimit: numberToString(creditInfoData?.creditLimit),
    cardTypes: creditInfoData?.cardTypes,
    secondApplicant: hasCoApplicant
      ? {
          hasCreditCards: getYesNoDefault(
            !!creditInfoData?.secondApplicant,
            creditInfoData?.secondApplicant?.numberOfCreditCards,
            YesNo.No,
          ),
          ...resolveNumberOfCreditCards(creditInfoData?.secondApplicant?.numberOfCreditCards),
          creditLimit: numberToString(creditInfoData?.secondApplicant?.creditLimit),
          cardTypes: creditInfoData?.secondApplicant?.cardTypes,
        }
      : undefined,
  })
}

const resolveNumberOfCreditCards = (numberOfCreditCards: number | undefined) => {
  const formValues = getOptionAndOtherFormValues(numberOfCreditCards, 1, MAX_NUMBER_OF_CREDIT_CARDS_OPTION)
  return {
    numberOfCreditCards: formValues.option,
    numberOfCreditCardsOther: formValues.other,
  }
}

const getCreditInfoData = (formData: IncomeFormDataBase): CreditInfoBase => {
  return formData.hasCreditCards === YesNo.Yes
    ? {
        numberOfCreditCards: resolveValueFromFormFields(
          assertValue(formData.numberOfCreditCards, 'formData.numberOfCreditCards'),
          formData.numberOfCreditCardsOther,
        ),
        creditLimit: toNumber(formData.creditLimit),
        cardTypes: formData.cardTypes,
      }
    : {}
}

export const convertToCreditInfoSubmitData = (
  formData: CreditInfoStepFormData,
  hasCoApplicant: boolean,
): CreditInfoStepData => {
  return {
    ...getCreditInfoData(formData),
    secondApplicant: hasCoApplicant
      ? getCreditInfoData(assertValue(formData.secondApplicant, 'formData.secondApplicant'))
      : undefined,
  }
}

export const getAndValidateCreditInfoTypeData: ApplicantStepDataValidator<CreditInfoStepData> = ({ applicantInfo }) => {
  const creditInfoData = applicantInfo?.creditInfoData
  const hasCoApplicant = assertValue(
    applicantInfo?.numberOfApplicantsInfoData,
    'applicantInfo.numberOfApplicantsInfoData',
  ).hasCoApplicant

  const formValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, hasCoApplicant)
  const isValid = isFormDataValid(convertToFormValues(creditInfoData, hasCoApplicant), formValidator)
  const data = getValidatedData<CreditInfoStepData>(creditInfoData, isValid)
  data.isComplete = getCompletenessForTwoApplicants({
    hasCoApplicant,
    isFirstApplicantValid: isValid,
    isSecondApplicantValid: creditInfoData?.secondApplicant !== undefined,
  })

  return data
}
