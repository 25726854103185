import { BaseWizardContext, HousingInfoStepDataType } from '../../../types'

export type { HousingInfoStepDataType } from '../../../types'

export const MAX_NUMBER_OF_CHILDREN_OPTION = 3

export interface HousingInfoStepContext extends HousingInfoStepDataType, BaseWizardContext {}

export enum HousingInfoText {
  Header = 'header',
  IsSameHouseHoldLabel = 'isSameHouseHoldLabel',
  MoveInDateLabel = 'moveInDateLabel',
  LivingExpensesLabel = 'livingExpensesLabel',
  LivingExpensesDescription = 'livingExpensesDescription',
  NumberOfAdultsLabel = 'numberOfAdultsLabel',
  NumberOfAdultsDescription = 'numberOfAdultsDescription',
  NumberOfChildrenLabel = 'numberOfChildrenLabel',
  NumberOfPeopleHeading = 'numberOfPeopleHeading',
}

export enum HousingInfoDataTypes {
  IsSameHouseHold = 'Is same household',
  MoveInDate = 'Move in date',
  MonthlyLivingExpenses = 'Monthly living expenses',
  NumberOfAdults = 'Number of adults',
  NumberOfChildren = 'Number of children',
}
