import { HousingLoanInfo, OptionalAndNullable, SecLoanApplicationType, SecLoansHousingLoanTargetType } from '@shared'
import { convertUndefinedToNull } from '@ui-common'

import { StepDataValidator, TargetTypeInfoStepData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { TargetTypeInfoFormData } from './TargetTypeInfoStep'

export const getAndValidateTargetTypeData: StepDataValidator<HousingLoanInfo | undefined> = (validationData) => {
  const targetTypeInfo = validationData.targetInfo?.housingLoanInfo?.targetTypeInfo ?? {}
  return isFormDataValid(targetTypeInfo, createFormValidator) ? validationData.targetInfo?.housingLoanInfo : undefined
}

export const targetTypeOptionsForLoanType: Record<SecLoanApplicationType, SecLoansHousingLoanTargetType[]> = {
  [SecLoanApplicationType.HousingLoan]: Object.values(SecLoansHousingLoanTargetType),
  [SecLoanApplicationType.CottageLoan]: [
    SecLoansHousingLoanTargetType.HousingStock,
    SecLoansHousingLoanTargetType.RealEstate,
    SecLoansHousingLoanTargetType.NotKnown,
  ],
  [SecLoanApplicationType.PurchaseLoan]: [],
  [SecLoanApplicationType.PropertyInvestment]: [
    SecLoansHousingLoanTargetType.HousingStock,
    SecLoansHousingLoanTargetType.RealEstate,
    SecLoansHousingLoanTargetType.NotKnown,
  ],
}

export const convertToFormValues = (
  targetTypeInfo: TargetTypeInfoStepData | undefined,
): OptionalAndNullable<TargetTypeInfoFormData> => {
  const data = {
    targetType: targetTypeInfo?.targetType,
  }
  return convertUndefinedToNull(data)
}
