import { OptionalAndNullable } from '@shared'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, only, Suite, test } from 'vest'

import { NumberOfApplicantsInfoFormData as FormData, SecLoansAloneOrTogether } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changeField) => {
    only(changeField)

    test('aloneOrTogether', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.aloneOrTogether).inside(Object.values(SecLoansAloneOrTogether))
    })
  })
}
