import {
  AdditionalInfoData,
  assertValue,
  BackgroundInfoData,
  CreditInfoData,
  CurrentResidenceInfoData,
  EmploymentInfoData,
  EntrepreneurInfoData,
  ExpensesInfoData,
  GuaranteesInfoData,
  HousingInfoData,
  IncomeInfoData,
  InstallmentInfoData,
  LoansAndCreditsInfoData,
  SecLoansApplicationMetadata,
  WealthInfoData,
} from '@shared'

import {
  ApplicantInfoWizardData,
  ApplicantInfoWizardDataType,
  ApplicantPhaseDataHandler,
  WizardData,
} from '../../types'
import { getAndValidateAdditionalInfoTypeData } from './additional-info-step/additionalInfoStepUtils'
import { getAndValidateBackgroundInfoTypeData } from './background-info-step/backgroundInfoStepUtils'
import { getAndValidateCreditInfoTypeData } from './credit-info-step/creditInfoStepUtils'
import { getAndValidateCurrentResidenceInfoTypeData } from './current-residence-info-step/currentResidenceInfoStepUtils'
import { getAndValidateEmploymentInfoTypeData } from './employment-info-step/employmentInfoStepUtils'
import { getAndValidateEntrepreneurInfoTypeData } from './entrepreneur-info-step/entrepreneurInfoStepUtils'
import { getAndValidateExpensesInfoTypeData } from './expenses-info-step/expensesInfoStepUtils'
import { getAndValidateGuaranteesInfoTypeData } from './guarantees-info-step/guaranteesInfoStepUtils'
import { getAndValidateHousingInfoTypeData } from './housing-info-step/housingInfoStepUtils'
import { getAndValidateIncomeInfoTypeData } from './income-info-step/incomeInfoStepUtils'
import { getValidatedInstallmentInfoStepData } from './installment-info-step/InstallmentInfoStepUtils'
import { getAndValidateLoansAndCreditsInfoTypeData } from './loans-and-credits-info-step/loansAndCreditsInfoStepUtils'
import { getAndValidateNumberOfApplicantsInfoTypeData } from './number-of-applicants-info-step/numberOfApplicantsInfoStepUtils'
import { getAndValidateWealthInfoTypeData } from './wealth-info-step/wealthInfoStepUtils'

type ApplicantInfoApplicationPhaseData =
  | BackgroundInfoData
  | EmploymentInfoData
  | EntrepreneurInfoData
  | IncomeInfoData
  | ExpensesInfoData
  | HousingInfoData
  | CurrentResidenceInfoData
  | WealthInfoData
  | CreditInfoData
  | LoansAndCreditsInfoData
  | InstallmentInfoData
  | GuaranteesInfoData
  | AdditionalInfoData

export const getApplicantInfoPhaseData = (
  applicationData: WizardData,
  applicationMetadata: SecLoansApplicationMetadata,
): ApplicantInfoWizardDataType | undefined => {
  if (!applicationData.applicantInfo) {
    return
  }
  const numberOfApplicantsInfoData = getAndValidateNumberOfApplicantsInfoTypeData(applicationData) ?? {
    hasCoApplicant: false,
  }
  const applicantInfoDataHandlers: ApplicantPhaseDataHandler<ApplicantInfoApplicationPhaseData>[] = [
    {
      location: { applicantInfo: { backgroundInfoData: undefined } },
      validate: getAndValidateBackgroundInfoTypeData,
    },
    {
      location: { applicantInfo: { employmentInfoData: undefined } },
      validate: getAndValidateEmploymentInfoTypeData,
    },
    {
      location: { applicantInfo: { entrepreneurInfoData: undefined } },
      validate: getAndValidateEntrepreneurInfoTypeData,
    },
    {
      location: { applicantInfo: { incomeInfoData: undefined } },
      validate: getAndValidateIncomeInfoTypeData,
    },
    {
      location: { applicantInfo: { expensesInfoData: undefined } },
      validate: getAndValidateExpensesInfoTypeData,
    },
    {
      location: { applicantInfo: { housingInfoData: undefined } },
      validate: getAndValidateHousingInfoTypeData,
    },
    {
      location: { applicantInfo: { currentResidenceInfoData: undefined } },
      validate: getAndValidateCurrentResidenceInfoTypeData,
    },
    {
      location: { applicantInfo: { wealthInfoData: undefined } },
      validate: getAndValidateWealthInfoTypeData,
    },
    {
      location: { applicantInfo: { creditInfoData: undefined } },
      validate: getAndValidateCreditInfoTypeData,
    },
    {
      location: { applicantInfo: { loansAndCreditsInfoData: undefined } },
      validate: getAndValidateLoansAndCreditsInfoTypeData,
    },
    {
      location: { applicantInfo: { installmentInfoData: undefined } },
      validate: getValidatedInstallmentInfoStepData,
    },
    {
      location: { applicantInfo: { guaranteesInfoData: undefined } },
      validate: getAndValidateGuaranteesInfoTypeData,
    },
    {
      location: { applicantInfo: { additionalInfoData: undefined } },
      validate: getAndValidateAdditionalInfoTypeData,
    },
  ]
  const initialApplicantInfo: ApplicantInfoWizardDataType = {
    applicantInfo: { numberOfApplicantsInfoData, isComplete: true },
  }

  return applicantInfoDataHandlers.reduce((validatedData, currentStep) => {
    const applicantInfo = assertValue(currentStep.location.applicantInfo, 'currentStep.location.applicantInfo')
    const location = Object.keys(applicantInfo)[0] as keyof ApplicantInfoWizardData
    const dataCandidate = currentStep.validate(
      {
        targetInfo: applicationData.targetInfo,
        applicantInfo: {
          ...validatedData.applicantInfo,
          [location]: applicationData.applicantInfo?.[location],
        },
      },
      applicationMetadata,
    )
    const isComplete =
      validatedData.applicantInfo?.isComplete && (dataCandidate === null || !!dataCandidate?.isComplete)
    return {
      applicantInfo: {
        ...validatedData.applicantInfo,
        [location]: dataCandidate?.data ?? undefined,
        isComplete,
      },
    }
  }, initialApplicantInfo)
}
