import { ErrorCode, ErrorResponse } from '@shared-types'
import { ErrorVisualMode } from '@ui-components/containers/ErrorComponentTypes'
import { Section } from '@ui-components/global/LayoutStyling'
import { SimpleStep } from '@ui-components/layout/SimpleStep'
import { PrimaryTopBarActionButtonOptions } from '@ui-components/navigation/PrimaryTopBar/types'
import { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import * as R from 'remeda'

import { TransitionErrorIndicator, TransitionErrorIndicatorProps } from '../TransitionErrorIndicator'
import { TransitionLoadingIndicator, TransitionLoadingIndicatorProps } from '../TransitionLoadingIndicator'

interface TransitionStepProps extends TransitionLoadingIndicatorProps {
  isLoading: boolean
  isErrored: boolean
  error: ErrorResponse<ErrorCode> | undefined
  onRetry: (() => void) | undefined
  onAbort?: () => void
  errorPropertiesOverride?: Partial<TransitionErrorIndicatorProps>
  largeScreenSupport?: boolean
}

export const TransitionStep = (props: PropsWithChildren<TransitionStepProps>): JSX.Element => {
  const intl = useIntl()

  const { errorPropertiesOverride, onRetry, error, heading, text, largeScreenSupport, children } = props

  const defaultProps: TransitionErrorIndicatorProps = {
    action: onRetry
      ? {
          onClick: onRetry,
          label: intl.formatMessage({ id: 'common-retry' }),
        }
      : undefined,
    heading: intl.formatMessage({ id: 'common-error-temporary-heading' }),
    text: intl.formatMessage({ id: 'common-error-temporary-text' }),
    mode: ErrorVisualMode.TechnicalError,
    requestId: error?.requestId,
  }

  const actionButtonOptions: PrimaryTopBarActionButtonOptions | undefined =
    props.onAbort && props.isErrored
      ? { onClick: props.onAbort, label: intl.formatMessage({ id: 'common-exit' }) }
      : undefined

  const errorProperties = R.merge(defaultProps, errorPropertiesOverride) as TransitionErrorIndicatorProps // maintenance page build fails without cast

  return (
    <SimpleStep actionButtonOptions={actionButtonOptions} largeScreenSupport={largeScreenSupport}>
      {props.isLoading && <TransitionLoadingIndicator heading={heading} text={text} />}
      {props.isErrored && (
        <TransitionErrorIndicator {...errorProperties} requestId={error?.requestId}>
          {errorProperties.children}
        </TransitionErrorIndicator>
      )}
      {children && <Section>{children}</Section>}
    </SimpleStep>
  )
}
