import { AppName } from '@shared/types'
import { AxiosError, AxiosStatic } from 'axios'
import axiosRetry, { exponentialDelay, IAxiosRetryConfig, isNetworkError } from 'axios-retry'

import { getCustomRequestConfig } from './http'

export const setAuthorizationHeaderBearerToken = (axios: AxiosStatic, token: string): void => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const unsetAuthorizationHeaderBearerToken = (axios: AxiosStatic): void => {
  delete axios.defaults.headers.common.Authorization
}

export const MAX_RETRIES = 3
const METHODS_TO_RETRY = ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT']

const getRetryDelay = (retryNumber: number, error: AxiosError): number => {
  const config = getCustomRequestConfig(error)
  if (config?.retryDelayMs !== undefined) {
    return config.retryDelayMs
  }
  return exponentialDelay(retryNumber)
}

const isRetryable = (error: AxiosError) => {
  if (isNetworkError(error) || error.code === AxiosError.ECONNABORTED) {
    return true
  }
  const maxRetryAfterMs = getCustomRequestConfig(error)?.maxRetryAfterMs ?? 0
  const retryAfterSeconds = error.response?.headers?.['Retry-After']
  if (!!retryAfterSeconds && maxRetryAfterMs > 0 && maxRetryAfterMs < parseInt(retryAfterSeconds) * 1000) {
    return false
  }
  try {
    const body = typeof error.response?.data === 'string' ? JSON.parse(error.response.data) : error.response?.data
    if (typeof body === 'object' && !!body && !Array.isArray(body) && 'retryable' in body) {
      return !!body.retryable
    }
  } catch (e) {
    // when body json can not be parsed, fallback to logic based on response status code
  }
  const statusCode = error.response?.status ?? (error.code && parseInt(error.code))
  const method = error.config?.method?.toUpperCase()
  if (!statusCode || !method) {
    return false
  }
  return (statusCode >= 500 || statusCode === 429) && METHODS_TO_RETRY.includes(method)
}

export const configureRetries = (axios: AxiosStatic): void => {
  const defaultRetryConfig: IAxiosRetryConfig = {
    retries: MAX_RETRIES,
    retryDelay: getRetryDelay,
    shouldResetTimeout: true,
    retryCondition: isRetryable,
  }
  axiosRetry(axios, defaultRetryConfig)
}

export const configureAxios = (axios: AxiosStatic, env: ImportMetaEnv, appName: AppName): void => {
  const { VITE_BFF_BASE_URL, VITE_APP_VERSION, PROD } = env
  axios.defaults.baseURL = PROD ? VITE_BFF_BASE_URL : '/api'
  axios.defaults.headers.common['X-Digi-App-Name'] = appName
  axios.defaults.headers.common['X-Digi-App-Version'] = VITE_APP_VERSION
  configureRetries(axios)
}
