import { convertUndefinedToNull, resolveFormEmptyString } from '@ui-common'

import { LoanAdditionalInfoStepData, StepDataValidator, WizardData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { LoanAdditionalInfoFormData } from './types'

export const getAndValidateAdditionalInfoTypeData: StepDataValidator<LoanAdditionalInfoFormData | undefined> = (
  validationData: WizardData,
) => {
  const additionalInfoData = validationData.loanInfo?.loanAdditionalInfoData

  return isFormDataValid(additionalInfoData, createFormValidator) ? additionalInfoData : undefined
}

export const convertToFormValues = (
  additionalInfoStepData: LoanAdditionalInfoStepData | undefined,
): LoanAdditionalInfoFormData => {
  const data = {
    additionalInfo: additionalInfoStepData?.additionalInfo,
    campaignCode: additionalInfoStepData?.campaignCode,
  }
  return convertUndefinedToNull(data)
}

export const convertToSubmitData = (formData: LoanAdditionalInfoFormData): LoanAdditionalInfoStepData => {
  return {
    additionalInfo: resolveFormEmptyString(formData?.additionalInfo),
    campaignCode: resolveFormEmptyString(formData?.campaignCode),
  }
}
