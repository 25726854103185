import { SecLoansGuaranteeLiability, SecLoansGuarantor } from '@sec-loans-types'
import { testIsValidMonetaryValue, YesNo } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { GuaranteesInfoStepFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  hasCoApplicant: boolean,
): Suite<string, string, (data: FormData, changedField?: Path<FormData>) => void> => {
  return create((data, changeField) => {
    only(changeField)

    test('firstApplicantHasGuarantees', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.firstApplicantHasGuarantees).inside(Object.values(YesNo))
    })

    omitWhen(!hasCoApplicant, () => {
      test('secondApplicantHasGuarantees', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.secondApplicantHasGuarantees).inside(Object.values(YesNo))
      })
    })

    function validateGuarantees(key: 'firstApplicant' | 'secondApplicant') {
      data[`${key}Guarantees`]?.forEach((guarantee, index) => {
        test(`${key}Guarantees.${index}.liability`, formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(guarantee.liability).inside(Object.values(SecLoansGuaranteeLiability))
        })

        testIsValidMonetaryValue(`${key}Guarantees.${index}.amount`, guarantee.amount, formatMessage)

        omitWhen(!hasCoApplicant, () => {
          test(`${key}Guarantees.${index}.guarantor`, formatMessage({ id: 'validate-required-field-missing' }), () => {
            enforce(guarantee.guarantor).inside(Object.values(SecLoansGuarantor))
          })
        })
      })
    }

    omitWhen(data.firstApplicantHasGuarantees !== YesNo.Yes, () => {
      validateGuarantees('firstApplicant')
    })

    omitWhen(!hasCoApplicant || data.secondApplicantHasGuarantees !== YesNo.Yes, () => {
      validateGuarantees('secondApplicant')
    })
  })
}
