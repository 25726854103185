import './utils/network'

import { initializeSnowplow } from '@components/common/analytics'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import App from './App'

console.log(import.meta.env.VITE_APP_VERSION)
initializeSnowplow()

const rootElement = document.getElementById('root')
if (!rootElement) {
  throw new Error('Root element missing')
}

const root = createRoot(rootElement)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
