import { ApplicantInfoWizardData, ApplicantInfoWizardDataType } from '@sec-loans-ui/wizard/types'
import { assertValue } from '@shared'
import { NextStepEvent } from '@ui-common'
import {
  ApplicantInfoPage,
  secondApplicantInfoStep,
  STEP_ABORTED,
  STEP_APPLICANT_ADDITIONAL_INFO,
  STEP_BACKGROUND_INFO,
  STEP_CREDIT_INFO,
  STEP_CURRENT_RESIDENCE_INFO,
  STEP_EMPLOYMENT_INFO,
  STEP_ENTREPRENEUR_INFO,
  STEP_EXPENSES_INFO,
  STEP_GUARANTEES_INFO,
  STEP_HOUSING_INFO,
  STEP_INCOME_INFO,
  STEP_INSTALLMENT_INFO,
  STEP_LOANS_AND_CREDITS_INFO,
  STEP_NUMBER_OF_APPLICANTS,
  STEP_SECOND_APPLICANT_BACKGROUND_INFO,
  STEP_SECOND_APPLICANT_EMPLOYMENT_INFO,
  STEP_SECOND_APPLICANT_ENTREPRENEUR_INFO,
  STEP_SECOND_APPLICANT_EXPENSES_INFO,
  STEP_SECOND_APPLICANT_INCOME_INFO,
  STEP_SUCCEEDED,
  STEP_WEALTH_INFO,
} from '@ui-common-sec-loans'
import { assign, setup } from 'xstate'

import { isEmployee, isEntrepreneur } from '../../../utils/helpers'
import { shouldShowCurrentResidenceInfo } from './current-residence-info-step/currentResidenceInfoStepUtils'
import { ApplicantInfoWizardContext, ApplicantInfoWizardEvent } from './types'

export const APPLICANT_INFO_WIZARD_MACHINE_ID = 'applicantInfoWizardMachine'

export {
  type ApplicantInfoPage,
  ApplicantInfoStep,
  STEP_ABORTED,
  STEP_APPLICANT_ADDITIONAL_INFO,
  STEP_BACKGROUND_INFO,
  STEP_CREDIT_INFO,
  STEP_CURRENT_RESIDENCE_INFO,
  STEP_EMPLOYMENT_INFO,
  STEP_ENTREPRENEUR_INFO,
  STEP_EXPENSES_INFO,
  STEP_GUARANTEES_INFO,
  STEP_HOUSING_INFO,
  STEP_INCOME_INFO,
  STEP_INSTALLMENT_INFO,
  STEP_LOANS_AND_CREDITS_INFO,
  STEP_NUMBER_OF_APPLICANTS,
  STEP_SECOND_APPLICANT_BACKGROUND_INFO,
  STEP_SECOND_APPLICANT_EMPLOYMENT_INFO,
  STEP_SECOND_APPLICANT_ENTREPRENEUR_INFO,
  STEP_SECOND_APPLICANT_EXPENSES_INFO,
  STEP_SECOND_APPLICANT_INCOME_INFO,
  STEP_SUCCEEDED,
  STEP_WEALTH_INFO,
} from '@ui-common-sec-loans'

const saveFormDataAction = {
  type: 'saveFormData',
  params: ({ event }: { event: NextStepEvent<ApplicantInfoWizardDataType> | NextStepEvent<ApplicantInfoWizardData> }) =>
    event.data,
} as const

export const isSecondApplicantStep = (step: string): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return secondApplicantInfoStep.includes(step as any)
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const applicantInfoWizardMachine = (initialState: ApplicantInfoPage = STEP_NUMBER_OF_APPLICANTS) => {
  return setup({
    types: {
      context: {} as ApplicantInfoWizardContext,
      input: {} as ApplicantInfoWizardContext,
      events: {} as ApplicantInfoWizardEvent,
    },
    actions: {
      saveFormData: assign(({ context }, data?: ApplicantInfoWizardDataType | null) => {
        return {
          ...context,
          data: {
            ...context.data,
            ...data?.applicantInfo, // null when returning from edit without saving
          },
        }
      }),
    },
    guards: {
      shouldFillCurrentResidenceData: ({ context }, data?: ApplicantInfoWizardDataType) => {
        let housingInfoData = context.data.housingInfoData

        if (data && 'applicantInfo' in data && data.applicantInfo) {
          housingInfoData = data.applicantInfo.housingInfoData
        }

        return housingInfoData
          ? shouldShowCurrentResidenceInfo(
              housingInfoData,
              assertValue(context.applicationMetadata, 'context.applicationMetadata'),
            )
          : false
      },
      isFirstApplicantEmployee: ({ context, event }, data?: ApplicantInfoWizardDataType) => {
        const { backgroundInfoData } =
          event.type === 'PREVIOUS_STEP' ? context.data : (data?.applicantInfo ?? context.data)
        return isEmployee(backgroundInfoData, false)
      },
      isFirstApplicantEntrepreneur: ({ context, event }, data?: ApplicantInfoWizardDataType) => {
        const { backgroundInfoData } =
          event.type === 'PREVIOUS_STEP' ? context.data : (data?.applicantInfo ?? context.data)
        return isEntrepreneur(backgroundInfoData, false)
      },
      isSecondApplicantEmployee: ({ context, event }, data?: ApplicantInfoWizardDataType) => {
        const { backgroundInfoData } =
          event.type === 'PREVIOUS_STEP' ? context.data : (data?.applicantInfo ?? context.data)
        return isEmployee(backgroundInfoData, true)
      },
      isSecondApplicantEntrepreneur: ({ context, event }, data?: ApplicantInfoWizardDataType) => {
        const { backgroundInfoData } =
          event.type === 'PREVIOUS_STEP' ? context.data : (data?.applicantInfo ?? context.data)
        return isEntrepreneur(backgroundInfoData, true)
      },
      hasTwoApplicants: ({ context }) => {
        return !!context.data.numberOfApplicantsInfoData?.hasCoApplicant
      },
    },
  }).createMachine({
    id: APPLICANT_INFO_WIZARD_MACHINE_ID,
    context: ({ input }) => input || { data: {}, applicationMetadata: undefined },
    initial: initialState,
    states: {
      [STEP_NUMBER_OF_APPLICANTS]: {
        on: {
          NEXT_STEP: {
            target: STEP_BACKGROUND_INFO,
            actions: saveFormDataAction,
          },
        },
      },
      [STEP_BACKGROUND_INFO]: {
        on: {
          NEXT_STEP: [
            {
              target: STEP_EMPLOYMENT_INFO,
              guard: {
                type: 'isFirstApplicantEmployee',
                params: ({ event }) => event.data,
              },
              actions: [saveFormDataAction],
            },
            {
              target: STEP_ENTREPRENEUR_INFO,
              guard: { type: 'isFirstApplicantEntrepreneur', params: ({ event }) => event.data },
              actions: [saveFormDataAction],
            },
            {
              target: STEP_INCOME_INFO,
              actions: [saveFormDataAction],
            },
          ],
          PREVIOUS_STEP: [{ target: STEP_NUMBER_OF_APPLICANTS }],
        },
      },
      [STEP_EMPLOYMENT_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_INCOME_INFO,
            actions: saveFormDataAction,
          },
          PREVIOUS_STEP: { target: STEP_BACKGROUND_INFO },
        },
      },
      [STEP_ENTREPRENEUR_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_INCOME_INFO,
            actions: saveFormDataAction,
          },
          PREVIOUS_STEP: { target: STEP_BACKGROUND_INFO },
        },
      },
      [STEP_INCOME_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_EXPENSES_INFO,
            actions: saveFormDataAction,
          },
          PREVIOUS_STEP: [
            {
              target: STEP_EMPLOYMENT_INFO,
              guard: { type: 'isFirstApplicantEmployee' },
            },
            {
              target: STEP_ENTREPRENEUR_INFO,
              guard: { type: 'isFirstApplicantEntrepreneur' },
            },
            { target: STEP_BACKGROUND_INFO },
          ],
        },
      },
      [STEP_EXPENSES_INFO]: {
        on: {
          NEXT_STEP: [
            {
              target: STEP_SECOND_APPLICANT_BACKGROUND_INFO,
              guard: 'hasTwoApplicants',
              actions: [saveFormDataAction],
            },
            {
              target: STEP_HOUSING_INFO,
              actions: [saveFormDataAction],
            },
          ],
          PREVIOUS_STEP: { target: STEP_INCOME_INFO },
        },
      },
      [STEP_SECOND_APPLICANT_BACKGROUND_INFO]: {
        on: {
          NEXT_STEP: [
            {
              target: STEP_SECOND_APPLICANT_EMPLOYMENT_INFO,
              guard: { type: 'isSecondApplicantEmployee', params: ({ event }) => event.data },
              actions: [saveFormDataAction],
            },
            {
              target: STEP_SECOND_APPLICANT_ENTREPRENEUR_INFO,
              guard: { type: 'isSecondApplicantEntrepreneur', params: ({ event }) => event.data },
              actions: [saveFormDataAction],
            },
            {
              target: STEP_SECOND_APPLICANT_INCOME_INFO,
              actions: [saveFormDataAction],
            },
          ],
          PREVIOUS_STEP: [
            {
              target: STEP_EXPENSES_INFO,
            },
          ],
        },
      },
      [STEP_SECOND_APPLICANT_EMPLOYMENT_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_SECOND_APPLICANT_INCOME_INFO,
            actions: [saveFormDataAction],
          },
          PREVIOUS_STEP: { target: STEP_SECOND_APPLICANT_BACKGROUND_INFO },
        },
      },
      [STEP_SECOND_APPLICANT_ENTREPRENEUR_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_SECOND_APPLICANT_INCOME_INFO,
            actions: [saveFormDataAction],
          },
          PREVIOUS_STEP: { target: STEP_SECOND_APPLICANT_BACKGROUND_INFO },
        },
      },
      [STEP_SECOND_APPLICANT_INCOME_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_SECOND_APPLICANT_EXPENSES_INFO,
            actions: [saveFormDataAction],
          },
          PREVIOUS_STEP: [
            { target: STEP_SECOND_APPLICANT_EMPLOYMENT_INFO, guard: 'isSecondApplicantEmployee' },
            { target: STEP_SECOND_APPLICANT_ENTREPRENEUR_INFO, guard: 'isSecondApplicantEntrepreneur' },
            { target: STEP_SECOND_APPLICANT_BACKGROUND_INFO },
          ],
        },
      },
      [STEP_SECOND_APPLICANT_EXPENSES_INFO]: {
        on: {
          NEXT_STEP: [
            {
              target: STEP_HOUSING_INFO,
              actions: [saveFormDataAction],
            },
          ],
          PREVIOUS_STEP: { target: STEP_SECOND_APPLICANT_INCOME_INFO },
        },
      },
      [STEP_HOUSING_INFO]: {
        on: {
          NEXT_STEP: [
            {
              target: STEP_CURRENT_RESIDENCE_INFO,
              guard: { type: 'shouldFillCurrentResidenceData', params: ({ event }) => event.data },
              actions: saveFormDataAction,
            },
            {
              target: STEP_WEALTH_INFO,
              actions: saveFormDataAction,
            },
          ],
          PREVIOUS_STEP: [
            { target: STEP_SECOND_APPLICANT_EXPENSES_INFO, guard: 'hasTwoApplicants' },
            { target: STEP_EXPENSES_INFO },
          ],
        },
      },
      [STEP_CURRENT_RESIDENCE_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_WEALTH_INFO,
            actions: saveFormDataAction,
          },
          PREVIOUS_STEP: { target: STEP_HOUSING_INFO },
        },
      },
      [STEP_WEALTH_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_CREDIT_INFO,
            actions: saveFormDataAction,
          },
          PREVIOUS_STEP: [
            { target: STEP_CURRENT_RESIDENCE_INFO, guard: { type: 'shouldFillCurrentResidenceData' } },
            { target: STEP_HOUSING_INFO },
          ],
        },
      },
      [STEP_CREDIT_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_LOANS_AND_CREDITS_INFO,
            actions: saveFormDataAction,
          },
          PREVIOUS_STEP: { target: STEP_WEALTH_INFO },
        },
      },
      [STEP_LOANS_AND_CREDITS_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_INSTALLMENT_INFO,
            actions: saveFormDataAction,
          },
          PREVIOUS_STEP: { target: STEP_CREDIT_INFO },
        },
      },
      [STEP_INSTALLMENT_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_GUARANTEES_INFO,
            actions: saveFormDataAction,
          },
          PREVIOUS_STEP: { target: STEP_LOANS_AND_CREDITS_INFO },
        },
      },
      [STEP_GUARANTEES_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_APPLICANT_ADDITIONAL_INFO,
            actions: [saveFormDataAction],
          },
          PREVIOUS_STEP: { target: STEP_INSTALLMENT_INFO },
        },
      },
      [STEP_APPLICANT_ADDITIONAL_INFO]: {
        on: {
          NEXT_STEP: {
            target: STEP_SUCCEEDED,
            actions: saveFormDataAction,
          },
          PREVIOUS_STEP: { target: STEP_GUARANTEES_INFO },
        },
      },
      [STEP_SUCCEEDED]: {
        id: STEP_SUCCEEDED,
        type: 'final',
      },
      [STEP_ABORTED]: {
        id: STEP_ABORTED,
        type: 'final',
      },
    },
    on: {
      EXIT_WIZARD: `.${STEP_ABORTED}`,
      GOTO_SUMMARY: [
        {
          target: `.${STEP_SUCCEEDED}`,
          actions: {
            type: 'saveFormData',
            params: ({ event }) => event.data,
          },
        },
      ],
    },
  })
}
