import { assertValue, SecLoansGuarantee, SecLoansGuarantor } from '@shared'
import { convertUndefinedToNull, getYesNoDefault, numberToString, RenderFieldParams, toNumber, YesNo } from '@ui-common'
import { MouseEvent } from 'react'
import { IntlShape } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

import { getValidatedData } from '../../../../utils/helpers'
import { ApplicantStepDataValidator, GuaranteesInfoStepData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { GuaranteesInfoStepFormData, SecLoansGuaranteeForm } from './types'

export const guaranteesFirstApplicant = {
  hasProp: 'firstApplicantHasGuarantees',
  dataProp: 'firstApplicantGuarantees',
  ownership: SecLoansGuarantor.FirstApplicant,
} as const
type GuaranteesFirstApplicant = typeof guaranteesFirstApplicant

export const guaranteesSecondApplicant = {
  hasProp: 'secondApplicantHasGuarantees',
  dataProp: 'secondApplicantGuarantees',
  ownership: SecLoansGuarantor.SecondApplicant,
} as const

type GuaranteesSecondApplicant = typeof guaranteesSecondApplicant

export type GuaranteesApplicant = GuaranteesFirstApplicant | GuaranteesSecondApplicant
export type RenderableGuaranteesApplicant = GuaranteesApplicant & {
  renderFields: RenderFieldParams<GuaranteesInfoStepFormData>[]
  remove: (event: MouseEvent<HTMLButtonElement>, index: number) => void
  append: () => void
  data: SecLoansGuaranteeForm[]
  has: YesNo
}

export const getApplicantGuarantees = (
  guaranteesInfoData: GuaranteesInfoStepData | undefined,
  ownership: SecLoansGuarantor,
): SecLoansGuarantee[] | undefined => {
  const ret = guaranteesInfoData?.guarantees?.filter((v) =>
    ownership === SecLoansGuarantor.FirstApplicant
      ? !v.guarantor || v.guarantor === SecLoansGuarantor.FirstApplicant
      : ownership === v.guarantor,
  )
  return ret
}

export const convertToFormValues = (
  guaranteesInfoData: GuaranteesInfoStepData | undefined,
): GuaranteesInfoStepFormData => {
  const firstApplicantGuarantees = getApplicantGuarantees(guaranteesInfoData, SecLoansGuarantor.FirstApplicant)?.map(
    (g) => ({
      ...g,
      id: uuidv4(),
      amount: numberToString(g.amount),
    }),
  )
  const secondApplicantGuarantees = getApplicantGuarantees(guaranteesInfoData, SecLoansGuarantor.SecondApplicant)?.map(
    (g) => ({
      ...g,
      id: uuidv4(),
      amount: numberToString(g.amount),
    }),
  )
  return convertUndefinedToNull({
    firstApplicantHasGuarantees: getYesNoDefault(!!guaranteesInfoData, firstApplicantGuarantees?.length, YesNo.No),
    secondApplicantHasGuarantees: getYesNoDefault(!!guaranteesInfoData, !!secondApplicantGuarantees?.length, YesNo.No),
    firstApplicantGuarantees: firstApplicantGuarantees ?? [],
    secondApplicantGuarantees: secondApplicantGuarantees ?? [],
  })
}

const convertFormDataGuarantee = (guarantor: SecLoansGuarantor): ((g: SecLoansGuaranteeForm) => SecLoansGuarantee) => {
  return ({ id: _id, ...g }) => {
    return { ...g, amount: assertValue(toNumber(g.amount), 'g.amount'), guarantor }
  }
}

export const convertToGuaranteesInfoSubmitData = (formData: GuaranteesInfoStepFormData): GuaranteesInfoStepData => {
  if (!formData.firstApplicantGuarantees && !formData.secondApplicantGuarantees) {
    return {}
  }

  return {
    guarantees: [
      ...(formData.firstApplicantGuarantees || []).map(convertFormDataGuarantee(SecLoansGuarantor.FirstApplicant)),
      ...(formData.secondApplicantGuarantees || []).map(convertFormDataGuarantee(SecLoansGuarantor.SecondApplicant)),
    ],
  }
}

export const getAndValidateGuaranteesInfoTypeData: ApplicantStepDataValidator<GuaranteesInfoStepData> = (
  validationData,
) => {
  const guaranteesInfoData = validationData.applicantInfo?.guaranteesInfoData
  const hasCoApplicant = !!validationData.applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant

  const formValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, hasCoApplicant)
  const isValid = isFormDataValid(convertToFormValues(guaranteesInfoData), formValidator)
  return getValidatedData(guaranteesInfoData, isValid)
}
