import { SecLoanPurposeType, SecLoansConstructionLotOwnership } from '@sec-loans-types'
import { OptionalAndNullable } from '@shared'
import { MAX_REASONABLE_NUMBER } from '@shared/validation'
import { isNumber, testIsValidMonetaryValue, toNumber } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { getConstructionTotalEstimate, getRenovationLoanAmount } from './loanCostEstimateInfoStepUtils'
import { LoanCostEstimateInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  purposeType: SecLoanPurposeType,
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    omitWhen(purposeType !== SecLoanPurposeType.Construction, () => {
      test('plotOwnership', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.plotOwnership).inside(Object.values(SecLoansConstructionLotOwnership))
      })

      omitWhen(data.plotOwnership !== SecLoansConstructionLotOwnership.Own, () => {
        testIsValidMonetaryValue('plotValue', data.plotValue, formatMessage)
      })

      omitWhen(data.plotOwnership !== SecLoansConstructionLotOwnership.BuyLater, () => {
        testIsValidMonetaryValue('plotSellingPrice', data.plotSellingPrice, formatMessage)
      })

      testIsValidMonetaryValue('ownWorkShare', data.ownWorkShare, formatMessage, undefined, true, 0)

      testIsValidMonetaryValue('costEstimate', data.costEstimate, formatMessage)

      omitWhen(!data.ownFinancingShare || !data.ownWorkShare, () => {
        const loanAmount = getConstructionTotalEstimate(
          toNumber(data.costEstimate),
          toNumber(data.ownFinancingShare),
          toNumber(data.ownWorkShare),
          toNumber(data.plotSellingPrice),
        )

        test(
          'costEstimate',
          formatMessage({ id: 'sec-loans-loan-cost-estimate-too-small-compared-to-other-info-in-page' }),
          () => {
            enforce(loanAmount).isBetween(1, MAX_REASONABLE_NUMBER)
          },
        )
      })
    })

    omitWhen(purposeType !== SecLoanPurposeType.Renovation, () => {
      omitWhen(!data.costEstimate || !data.ownFinancingShare, () => {
        const costEstimate = toNumber(data.costEstimate)
        const ownFinancingShare = toNumber(data.ownFinancingShare)
        const loanAmount =
          isNumber(costEstimate) && isNumber(ownFinancingShare)
            ? getRenovationLoanAmount(costEstimate, ownFinancingShare)
            : undefined

        test(
          'costEstimate',
          formatMessage({ id: 'sec-loans-loan-cost-estimate-too-small-compared-to-other-info-in-page' }),
          () => {
            enforce(loanAmount).isBetween(1, MAX_REASONABLE_NUMBER)
          },
        )
      })
    })

    testIsValidMonetaryValue('costEstimate', data.costEstimate, formatMessage)
    testIsValidMonetaryValue('ownFinancingShare', data.ownFinancingShare, formatMessage, undefined, true, 0)
  })
}
