import { assign, setup } from 'xstate'

import { ShowModalOptions } from './Modal'

export const CLOSED = 'closed'
export const OPEN = 'open'

export type OpenModalEvent = { type: 'OPEN'; data: ShowModalOptions }
export type ConfirmModalEvent = { type: 'CONFIRM' }
export type DismissModalEvent = { type: 'DISMISS' }

export type ModalEvent = ConfirmModalEvent | DismissModalEvent | OpenModalEvent

export interface ModalContext {
  options?: ShowModalOptions
  isOpen?: boolean
}

export const modalMachine = setup({
  types: {} as {
    context: ModalContext
    events: ModalEvent
  },

  actions: {
    open: assign((_, options: ShowModalOptions) => {
      return { options, isOpen: true }
    }),
    close: assign(() => {
      return { options: undefined, isOpen: false }
    }),
  },
}).createMachine({
  id: 'modalMachine',
  context: { isOpen: false, options: undefined },
  initial: CLOSED,
  predictableActionArguments: true,
  states: {
    [CLOSED]: {
      id: CLOSED,
      on: {
        OPEN: [
          {
            target: OPEN,
            actions: { type: 'open', params: ({ event }) => event.data },
          },
        ],
      },
    },
    [OPEN]: {
      on: {
        DISMISS: { target: CLOSED, actions: { type: 'close' } },
        CONFIRM: { target: CLOSED, actions: { type: 'close' } },
      },
    },
  },
})
