import { ErrorVisualMode } from '@ui-components/containers/ErrorComponentTypes'
import { SimpleStep } from '@ui-components/layout/SimpleStep'
import { PrimaryTopBarActionButtonOptions } from '@ui-components/navigation/PrimaryTopBar/types'
import { useIntl } from 'react-intl'

import { TransitionErrorIndicator } from '../TransitionErrorIndicator'

interface AuthenticationCancelledStepProps {
  onRetry: () => void
  onAbort: () => void
}

export const AuthenticationCancelledStep = ({ onRetry, onAbort }: AuthenticationCancelledStepProps): JSX.Element => {
  const intl = useIntl()

  const actionButtonOptions: PrimaryTopBarActionButtonOptions = {
    label: intl.formatMessage({ id: 'common-exit' }),
    onClick: onAbort,
  }

  return (
    <SimpleStep actionButtonOptions={actionButtonOptions}>
      <TransitionErrorIndicator
        action={{
          onClick: onRetry,
          label: intl.formatMessage({ id: 'common-retry' }),
        }}
        heading={intl.formatMessage({ id: 'common-authentication-cancelled-heading' })}
        text={intl.formatMessage({ id: 'common-authentication-cancelled-text' })}
        mode={ErrorVisualMode.Plain}
      />
    </SimpleStep>
  )
}
