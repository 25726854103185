import { AddressInfoData, OptionalAndNullable, SecLoanPurposeType, SecLoansHousingLoanTargetType } from '@shared'
import { convertUndefinedToNull } from '@ui-common'

import { AddressInfoStepData, StepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { AddressInfoFormData } from './types'

export interface PostCodeAndOffice {
  postOffice: string
  postcode: string
}

export const convertToFormValues = (
  addressData: AddressInfoStepData | undefined,
): OptionalAndNullable<AddressInfoFormData> => {
  return convertUndefinedToNull({
    street: addressData?.street,
    postCode: addressData?.postCode,
    postOffice: addressData?.postOffice,
  })
}

export const convertToSubmitData = (formData: AddressInfoFormData): AddressInfoStepData => {
  return {
    street: formData.street,
    postCode: formData.postCode,
    postOffice: formData.postOffice,
  }
}

export const getAndValidateAddressInfoTypeData: StepDataValidator<AddressInfoData | undefined | null> = (
  validationData,
  metadata,
) => {
  const isKnownTarget =
    validationData.targetInfo?.housingLoanInfo?.targetTypeInfo?.targetType !== SecLoansHousingLoanTargetType.NotKnown
  const isConstructionPurpose = metadata?.applicationPurpose === SecLoanPurposeType.Construction

  if (!isKnownTarget || isConstructionPurpose) {
    return null
  }
  const addressInfoData = validationData.targetInfo?.addressInfoData
  const isValidData = isFormDataValid(convertToFormValues(addressInfoData), createFormValidator)
  return isValidData ? addressInfoData : undefined
}
