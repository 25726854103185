import { SecLoansHousingLoanTargetType } from '@sec-loans-types'
import { OptionalAndNullable } from '@shared'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, only, Suite, test } from 'vest'

import { TargetTypeInfoFormData as FormData } from './TargetTypeInfoStep'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    test('targetType', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.targetType).inside(Object.values(SecLoansHousingLoanTargetType))
    })
  })
}
