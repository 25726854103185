import { ApplicantRole, LoanApplicationResultResponse, SecLoanApplication, SecLoansApplicationMetadata } from '@shared'
import { useSelector } from '@xstate/react'
import { useCallback, useContext } from 'react'

import { ApplicationWizardContext } from '../state/applicationWizardContext'
import { ApplicantInfoPage } from '../wizard/steps/applicant-info-phase/applicantInfoWizardMachine'
import { LoanInfoPage } from '../wizard/steps/loan-info-phase/loanInfoWizardMachine'
import { TargetInfoPage } from '../wizard/steps/loan-target-phase/targetInfoWizardMachine'
import { User, WizardData } from '../wizard/types'

interface UseApplicationWizardReturnType {
  wizardData: WizardData
  applicationMetadata?: SecLoansApplicationMetadata
  user?: User
  stepToEdit: TargetInfoPage | ApplicantInfoPage | LoanInfoPage | undefined
  isEditing: boolean
  isSecondaryApplicant: boolean
  shouldShowSentModal: boolean
  updateApplication: (data: SecLoanApplication) => void
  loanApplicationStatusData: LoanApplicationResultResponse | undefined
  isSecondApplicantMode: boolean
}

export const useApplicationWizard = (): UseApplicationWizardReturnType => {
  const { service } = useContext(ApplicationWizardContext)
  const wizardData = useSelector(service, (state) => state.context.data)
  const applicationMetadata = useSelector(service, (state) => state.context.applicationMetadata)
  const user = useSelector(service, (state) => state.context.user)
  const loanApplicationStatusData = useSelector(service, (state) => state.context.loanApplicationStatusData)
  const isSecondaryApplicant = useSelector(
    service,
    (state) => state.context.applicationMetadata?.applicantRole === ApplicantRole.SecondaryApplicant,
  )
  const isSecondApplicantMode = useSelector(service, (state) => state.context.isSecondApplicantMode)
  const shouldShowSentModal = useSelector(service, (state) => !!state.context.shouldShowSentModal)
  const stepToEdit = useSelector(service, (state) => state.context.stepToEdit)
  const isEditing = !!stepToEdit

  const onUpdateApplication = useCallback(
    (data: SecLoanApplication) => {
      service.send({ type: 'SAVE_APPLICATION', data })
    },
    [service],
  )

  return {
    wizardData,
    user,
    applicationMetadata,
    stepToEdit,
    isEditing,
    isSecondaryApplicant,
    shouldShowSentModal,
    updateApplication: onUpdateApplication,
    loanApplicationStatusData,
    isSecondApplicantMode,
  }
}
