import { AppId, Language } from '@shared'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

export const useLocalizedTitle = (language: Language, appId: AppId): void => {
  const intl = useIntl()

  useEffect(() => {
    const siteHeaders: Record<AppId, string> = {
      [AppId.Bcr]: intl.formatMessage({ id: 'bcr-site-header' }),
      [AppId.CreditCard]: intl.formatMessage({ id: 'credit-card-site-header' }),
      [AppId.DigiLi]: intl.formatMessage({ id: 'digili-site-header' }),
      [AppId.Kyc]: intl.formatMessage({ id: 'kyc-site-header' }),
      [AppId.SecLoans]: intl.formatMessage({ id: 'sec-loans-site-header' }),
      [AppId.CorpKyc]: intl.formatMessage({ id: 'corp-kyc-site-header' }),
    }
    document.title = siteHeaders[appId]
  }, [appId, intl, language])
}
