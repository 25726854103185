import { MAX_REASONABLE_NUMBER, OptionalAndNullable } from '@shared'
import { testIsValidMonetaryValue } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, omitWhen, only, Suite } from 'vest'

import { WizardData } from '../../../types'
import { LivingExpensesInfoFormData as FormData } from './LivingExpensesInfoStep'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  applicationData: WizardData,
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    testIsValidMonetaryValue('maintenanceFee', data.maintenanceFee, formatMessage, MAX_REASONABLE_NUMBER, true, 0)
    omitWhen(applicationData.targetInfo?.priceInfo?.isPayingCompanyLoanShare !== false, () => {
      testIsValidMonetaryValue(
        'housingCompanyLoanPayment',
        data.housingCompanyLoanPayment,
        formatMessage,
        MAX_REASONABLE_NUMBER,
        true,
        0,
      )
    })

    testIsValidMonetaryValue('otherExpenses', data.otherExpenses, formatMessage, MAX_REASONABLE_NUMBER, true, 0)
  })
}
