import { AuthenticationCancelled } from '@components/common/authenticate-step/types'
import { getCancelWithoutSavingModalEvent } from '@components/common/Modal/CancelWithoutSavingModal'
import { Modal } from '@components/common/Modal/Modal'
import { useApplicationWizard } from '@sec-loans-ui/hooks/useApplicationWizard'
import { onFormSubmitCheckDependantData } from '@sec-loans-ui/utils/dependantChanges'
import { AppId, assertValue, SecLoansAbortModalCase, SecLoansEndpoint, SecLoansFlow, SecLoansSource } from '@shared'
import {
  AuthenticateStep,
  callEndpoint,
  clearLocalStorage,
  clearSessionStorage,
  getLanguage,
  getSourceChannel,
  GlobalServicesContext,
  OpenModalEvent,
  setAuthorizationHeaderBearerToken,
  TransitionStep,
  unsetAuthorizationHeaderBearerToken,
  updatePageFocus,
  useLocalizedTitle,
} from '@ui-common'
import { BackActionTopBar, MediumText, ModalVisualMode } from '@ui-components'
import { useSelector } from '@xstate/react'
import axios from 'axios'
import { lazy, Suspense, useCallback, useContext, useEffect, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import * as R from 'remeda'
import { AnyActor } from 'xstate'

import { SecLoansAnalyticsService, SecLoansCustomEventCategory } from '../services/analyticsService'
import { useAnalyticsService } from '../state/analyticsContext'
import { ApplicationWizardContext } from '../state/applicationWizardContext'
import { getSecLoansLocalizedEnums } from '../utils/getSecLoansLocalizedEnums'
import { getAuthenticationMode, getExitUrl, getSecLoansSourceChannel } from '../utils/helpers'
const ApplicantInfoWizard = lazy(() => import('./steps/applicant-info-phase/ApplicantInfoWizard'))
const CancelApplicationStep = lazy(() => import('./steps/cancel-application-step/CancelApplicationStep'))
const CatalogStep = lazy(() => import('./steps/catalog-step/CatalogStep'))
const GetAllApplicationsStep = lazy(() => import('./steps/get-all-applications-step/GetAllApplicationsStep'))
const GetApplicationStep = lazy(() => import('./steps/get-application-step/GetApplicationStep'))
const GetBasicInfoStep = lazy(() => import('./steps/get-basic-info-step/GetBasicInfoStep'))
const LoanInfoWizard = lazy(() => import('./steps/loan-info-phase/LoanInfoWizard'))
const TargetInfoWizard = lazy(() => import('./steps/loan-target-phase/TargetInfoWizard'))
const ReturnApplicationStep = lazy(() => import('./steps/return-to-primary-applicant-step/ReturnApplicationStep'))
const PollStep = lazy(() => import('./steps/status-poll-step/PollStep'))
const StatusStep = lazy(() => import('./steps/status-step/StatusStep'))
const TheEndStep = lazy(() => import('./steps/the-end-step/TheEndStep'))

import { EntranceStep } from './steps/entrance-step/EntranceStep'
import { InitializeStep } from './steps/initialize-step/InitializeStep'
import { ProgressFormSendType, ProgressFormType, WizardData } from './types'
import {
  PHASE_APPLICANT_INFO,
  PHASE_LOAN_INFO,
  PHASE_TARGET_INFO,
  STEP_ABORTED,
  STEP_AUTHENTICATE,
  STEP_CANCEL_APPLICATION,
  STEP_CATALOG,
  STEP_ENTRANCE,
  STEP_GET_ALL_APPLICATIONS,
  STEP_GET_APPLICATION,
  STEP_GET_BASIC_INFO,
  STEP_INITIALIZE,
  STEP_POLL,
  STEP_RETURN_APPLICATION,
  STEP_STATUS,
  STEP_SUCCEEDED,
  STEP_THE_END,
} from './wizardMachine'

const onExitDefault = (exitUrl: string) => {
  clearSessionStorage()
  clearLocalStorage()
  window.location.replace(exitUrl)
}

export interface WizardProps {
  onExit?: (url: string) => void
}

export const getSecLoansOpenAbortModalEvent = ({
  onAbort,
  intl,
  analyticsService,
  analyticsCategory,
  abortCase = SecLoansAbortModalCase.LeaveAndContinueLater,
}: {
  onAbort: () => void
  intl: IntlShape
  analyticsService: SecLoansAnalyticsService
  analyticsCategory: SecLoansCustomEventCategory
  abortCase?: SecLoansAbortModalCase
}): OpenModalEvent => {
  const { abortModalHeaderTexts, abortModalDescriptionTexts } = getSecLoansLocalizedEnums(intl.formatMessage)
  return {
    type: 'OPEN',
    data: {
      header: abortModalHeaderTexts[abortCase],
      children: (
        <MediumText key={`sec-loans-abort-${abortCase}prompt-message`} $color="primary" tagName="div">
          {abortModalDescriptionTexts[abortCase]}
        </MediumText>
      ),
      contentAriaLabel: `sec-loans-abort-${abortCase}-prompt-header`,
      primaryButtonOptions: {
        label: intl.formatMessage({ id: 'common-cancel' }),
        onClick: () => {
          analyticsService.sendCustomEvent(analyticsCategory, 'CancelButton')
        },
      },
      secondaryButtonOptions: {
        label: intl.formatMessage({ id: 'common-abort-prompt-exit' }),
        onClick: onAbort,
      },
      mode: ModalVisualMode.Help,
    },
  }
}

export const Wizard = ({ onExit = onExitDefault }: WizardProps): JSX.Element => {
  const { service } = useContext(ApplicationWizardContext)
  const state = useSelector(service, (s) => s)
  const { analyticsService } = useAnalyticsService()

  if (state.context.authToken) {
    setAuthorizationHeaderBearerToken(axios, state.context.authToken)
  } else {
    unsetAuthorizationHeaderBearerToken(axios)
  }
  const intl = useIntl()

  const globalServices = useContext(GlobalServicesContext)
  const { modalService } = useContext(GlobalServicesContext)
  const modalSend = modalService.send

  const language = getLanguage()
  useLocalizedTitle(language, AppId.SecLoans)

  const [isFormDirty, setIsFormDirty] = useState(false)

  const onAbort = () => {
    service.send({ type: 'EXIT_WIZARD' })
  }

  const applicationWizard = useApplicationWizard()
  const { wizardData, applicationMetadata } = applicationWizard

  const onFormStepSubmit: ProgressFormType = (changedData, stepSend, asyncValidateSubmit) => {
    const onSubmit = (type: ProgressFormSendType, data: WizardData) =>
      stepSend({
        type,
        data,
      })

    if (asyncValidateSubmit) {
      stepSend({ data: changedData, type: 'VALIDATE' })
      void asyncValidateSubmit((result) => {
        if (result === 'valid') {
          onSubmit('SUBMIT', changedData)
        } else if (result === 'invalid') {
          stepSend({ data: changedData, type: 'VALIDATION_FAILED' })
        } else {
          stepSend({ data: changedData, type: 'VALIDATION_SERVER_ERROR' })
        }
      })
    } else if (isEditing) {
      onFormSubmitCheckDependantData({
        wizardData,
        changedData,
        applicationMetadata: assertValue(applicationMetadata, 'applicationMetadata'),
        submit: (data) => onSubmit('SUBMIT', data),
        modalSend,
        children: (
          <MediumText $color="primary" tagName="div">
            {intl.formatMessage({ id: 'sec-loans-dependant-changes-modal-message' })}
          </MediumText>
        ),
        intl,
      })
    } else {
      onSubmit('SUBMIT', changedData)
    }
  }

  useEffect(() => {
    const timeoutIds = updatePageFocus()
    return () => {
      timeoutIds.forEach((t) => clearTimeout(t))
    }
  }, [state.value])

  useEffect(() => {
    void (async () => {
      if (state.matches(STEP_ABORTED)) {
        const exitUrls = assertValue(state.context.exitUrls, 'state.context.exitUrls')
        const url = await getExitUrl(exitUrls.failureUrl[language], exitUrls.cbsTokenParameter)
        onExit(url)
      }
      if (state.matches(STEP_SUCCEEDED)) {
        const exitUrls = assertValue(state.context.exitUrls, 'state.context.exitUrls')
        const url = await getExitUrl(exitUrls.successUrl[language], exitUrls.cbsTokenParameter)
        onExit(url)
      }
    })()
  }, [language, onExit, state])

  const showAbortPromptModal = () => {
    analyticsService.sendModalViewEvent('AbortModal')
    const openEvent: OpenModalEvent = getSecLoansOpenAbortModalEvent({
      onAbort: () => {
        analyticsService.sendCustomEvent('AbortModal', 'ExitButton')
        onAbort()
      },
      analyticsService,
      analyticsCategory: 'AbortModal',
      intl,
    })
    modalSend(openEvent)
  }

  const showPollAbortPromptModal = () => {
    analyticsService.sendModalViewEvent('PollAbortModal')
    const openEvent: OpenModalEvent = getSecLoansOpenAbortModalEvent({
      onAbort: () => {
        analyticsService.sendCustomEvent('PollAbortModal', 'ExitButton')
        onAbort()
      },
      analyticsService,
      analyticsCategory: 'PollAbortModal',
      intl,
      abortCase: SecLoansAbortModalCase.TiredOfWaiting,
    })
    modalSend(openEvent)
  }
  const showDiscardChangesModal = () => {
    analyticsService.sendModalViewEvent('DiscardChangesModal')
    const continueAction = intl.formatMessage({ id: 'common-continue-anyway' })

    modalSend(
      getCancelWithoutSavingModalEvent({
        onConfirm: () => {
          analyticsService.sendCustomEvent('DiscardChangesModal', 'ConfirmButton')
          onBackToApplication()
        },
        onCancel: () => {
          analyticsService.sendCustomEvent('DiscardChangesModal', 'CancelButton')
        },
        intl,
        header: intl.formatMessage({ id: 'sec-loans-back-to-application-unsaved-header' }),
        text: intl.formatMessage(
          { id: 'sec-loans-back-to-application-unsaved-warning' },
          { continue_action: continueAction },
        ),
        primaryActionText: continueAction,
      }),
    )
  }

  const onBackToApplication = () => {
    service.send({ type: 'BACK_TO_STATUS' })
  }

  const onWizardSectionFinished = () => service.send({ type: 'SECTION_DONE' })

  const sectionWizardProps = {
    showAbortPromptModal,
    onAbort,
    onFinished: onWizardSectionFinished,
    onFormStepSubmit,
  }

  const onFormIsDirtyChangeCallback = useCallback((isDirty: boolean) => {
    setIsFormDirty(isDirty)
  }, [])

  const isInPhase =
    state.matches(PHASE_TARGET_INFO) || state.matches(PHASE_APPLICANT_INFO) || state.matches(PHASE_LOAN_INFO)

  let component: JSX.Element | undefined

  const isEditing = !!state.context.stepToEdit
  switch (true) {
    case state.matches(STEP_INITIALIZE):
      component = <InitializeStep service={state.children['initializeStepMachine'] as AnyActor} />
      break
    case state.matches(STEP_ENTRANCE):
      component = (
        <EntranceStep
          service={state.children['entranceStepMachine'] as AnyActor}
          showAbortPromptModal={showAbortPromptModal}
          showProcessingTimeNotification={state.context.showProcessingTimeNotification}
        />
      )
      break
    case state.matches(STEP_AUTHENTICATE):
      component = (
        <AuthenticateStep
          verifyAuthCodeCallback={(authCode) => {
            const sourceChannel = getSourceChannel<SecLoansSource>()
            return callEndpoint(SecLoansEndpoint.ENDPOINT_VERIFY_AUTH_CODE, {
              requestPayload: {
                authCode,
                uiLanguage: language,
                sourceChannel,
                flow: SecLoansFlow.Default,
              },
            })
          }}
          authMode={getAuthenticationMode(getSecLoansSourceChannel())}
          onExit={onAbort}
          onAuthenticated={(token) => service.send({ type: 'AUTHENTICATION_DONE', data: { authToken: token } })}
          onAuthenticationCancelled={() => service.send({ type: AuthenticationCancelled })}
        />
      )
      break
    case state.matches(STEP_GET_BASIC_INFO):
      component = <GetBasicInfoStep parentService={service} onAbort={showAbortPromptModal} />
      break
    case state.matches(STEP_GET_ALL_APPLICATIONS):
      component = <GetAllApplicationsStep parentService={service} onAbort={showAbortPromptModal} />
      break
    case state.matches(STEP_CATALOG):
      component = <CatalogStep parentService={service} showAbortPromptModal={showAbortPromptModal} />
      break
    case state.matches(STEP_GET_APPLICATION):
      component = (
        <GetApplicationStep
          parentService={service}
          applicationId={state.context.catalogStepData?.applicationId}
          onAbort={showAbortPromptModal}
        />
      )
      break
    case state.matches(STEP_STATUS):
      component = (
        <StatusStep
          parentService={service}
          showAbortPromptModal={showAbortPromptModal}
          user={assertValue(state.context.user, 'state.context.user')}
        />
      )
      break
    case state.matches(PHASE_TARGET_INFO):
      component = <TargetInfoWizard {...sectionWizardProps} onFormIsDirtyChange={onFormIsDirtyChangeCallback} />
      break
    case state.matches(PHASE_APPLICANT_INFO):
      component = <ApplicantInfoWizard {...sectionWizardProps} onFormIsDirtyChange={onFormIsDirtyChangeCallback} />
      break
    case state.matches(PHASE_LOAN_INFO):
      component = <LoanInfoWizard {...sectionWizardProps} onFormIsDirtyChange={onFormIsDirtyChangeCallback} />
      break
    case state.matches(STEP_POLL):
      component = <PollStep showAbortPromptModal={showPollAbortPromptModal} />
      break
    case state.matches(STEP_CANCEL_APPLICATION):
      component = (
        <CancelApplicationStep
          parentService={service}
          applicationId={assertValue(
            state.context.catalogStepData?.applicationId,
            'state.context.catalogStepData.applicationId',
          )}
          onAbort={showAbortPromptModal}
        />
      )
      break
    case state.matches(STEP_RETURN_APPLICATION):
      component = (
        <ReturnApplicationStep
          parentService={service}
          applicationId={assertValue(
            state.context.catalogStepData?.applicationId,
            'state.context.catalogStepData.applicationId',
          )}
          onAbort={showAbortPromptModal}
        />
      )
      break
    case state.matches(STEP_THE_END):
      component = <TheEndStep parentService={service} />
      break
    default:
      component = undefined
  }

  return (
    <>
      {isInPhase && !isEditing && (
        <BackActionTopBar
          backLabel={intl.formatMessage({ id: 'sec-loans-back-to-application-summary' })}
          onBackClick={() => {
            if (isFormDirty) {
              showDiscardChangesModal()
            } else {
              onBackToApplication()
            }
          }}
        />
      )}
      <Suspense
        fallback={<TransitionStep isLoading={true} isErrored={false} onRetry={R.doNothing} error={undefined} />}
      >
        {component}
      </Suspense>
      <Modal service={globalServices.modalService} />
    </>
  )
}
