import {
  OptionalAndNullable,
  OtherOccupation,
  SecLoanOccupation,
  SecLoanPreviousEmploymentTypeOptions,
  SecLoanProbationOptions,
  SecLoansEmploymentType,
  SecLoansValidationConstants,
  SecLoanWorkingHoursOptions,
  SecLoanWorkingPositionOptions,
} from '@shared'
import {
  isDateLessThanXMonthsAgo,
  MINIMUM_REASONABLE_YEAR,
  validateFutureMonthAndYear,
  validatePastMonthAndYear,
  validatePastStartAndEndDate,
  validateShortTextInput,
} from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, group, omitWhen, only, Suite, test } from 'vest'

import { EntrepreneurInfoFormData } from '../entrepreneur-info-step/EntrepreneurInfoStep'
import { EmploymentInfoFormData, EmploymentInfoFormData as FormData } from './EmploymentInfoStep'
import { isPreviousEmploymentRequired } from './employmentInfoStepUtils'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  employmentType: SecLoansEmploymentType | undefined,
  occupation: SecLoanOccupation | undefined,
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> =>
  create((data, changedField) => {
    only(changedField)

    omitWhen(occupation !== OtherOccupation.OnFamilyLeave, () => {
      validateFutureMonthAndYear({
        fieldName: 'returnToWorkDate',
        formatMessage,
        dateToValidate: data.returnToWorkDate,
        isRequired: true,
      })
    })

    group('employer', () => {
      validateShortTextInput(
        'employer',
        formatMessage,
        data.employer,
        SecLoansValidationConstants.SHORT_TEXT_INPUT_MAX_LENGTH,
        true,
      )
    })

    test('workingHours', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.workingHours).inside(Object.values(SecLoanWorkingHoursOptions))
    })

    test('workingPosition', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.workingPosition).inside(Object.values(SecLoanWorkingPositionOptions))
    })

    group('jobTitle', () => {
      validateShortTextInput(
        'jobTitle',
        formatMessage,
        data.jobTitle,
        SecLoansValidationConstants.SHORT_TEXT_INPUT_MAX_LENGTH,
        true,
      )
    })

    group('employmentStartDate', () => {
      validatePastMonthAndYear({
        fieldName: 'employmentStartDate',
        formatMessage,
        dateToValidate: data.employmentStartDate,
        isRequired: true,
        minimumYear: MINIMUM_REASONABLE_YEAR,
      })
    })

    omitWhen(employmentType !== SecLoansEmploymentType.TimeLimited, () => {
      validateFutureMonthAndYear({
        fieldName: 'employmentEndDate',
        formatMessage,
        dateToValidate: data.employmentEndDate,
        isRequired: true,
      })
    })

    omitWhen(!data.employmentStartDate || !isDateLessThanXMonthsAgo(data.employmentStartDate, 6), () => {
      test('probation', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.probation).inside(Object.values(SecLoanProbationOptions))
      })
    })

    previousEmploymentValidator(formatMessage, data, data.employmentStartDate)
  })

export const previousEmploymentValidator = (
  formatMessage: IntlShape['formatMessage'],
  data: OptionalAndNullable<EmploymentInfoFormData | EntrepreneurInfoFormData>,
  currentEmploymentStartMonthAndYear: string | undefined | null,
): void => {
  omitWhen(!isPreviousEmploymentRequired(currentEmploymentStartMonthAndYear), () => {
    test('previousEmploymentType', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.previousEmploymentType).inside(Object.values(SecLoanPreviousEmploymentTypeOptions))
    })

    omitWhen(data.previousEmploymentType === SecLoanPreviousEmploymentTypeOptions.NoPreviousEmployment, () => {
      omitWhen(data.previousEmploymentType === SecLoanPreviousEmploymentTypeOptions.EntrepreneurOrFreelancer, () => {
        validateShortTextInput(
          'previousEmployerName',
          formatMessage,
          data.previousEmployerName,
          SecLoansValidationConstants.SHORT_TEXT_INPUT_MAX_LENGTH,
          true,
        )
      })

      omitWhen(data.previousEmploymentType !== SecLoanPreviousEmploymentTypeOptions.EntrepreneurOrFreelancer, () => {
        validateShortTextInput(
          'previousCompanyName',
          formatMessage,
          data.previousCompanyName,
          SecLoansValidationConstants.SHORT_TEXT_INPUT_MAX_LENGTH,
          true,
        )
      })

      validateShortTextInput(
        'previousJobTitle',
        formatMessage,
        data.previousJobTitle,
        SecLoansValidationConstants.SHORT_TEXT_INPUT_MAX_LENGTH,
        true,
      )

      validatePastStartAndEndDate({
        startDateFieldName: 'previousEmploymentStartDate',
        endDateFieldName: 'previousEmploymentEndDate',
        formatMessage,
        startDateToValidate: data.previousEmploymentStartDate,
        endDateToValidate: data.previousEmploymentEndDate,
        minimumYear: MINIMUM_REASONABLE_YEAR,
        isRequired: true,
      })
    })
  })
}
