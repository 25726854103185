import { lazy, Suspense } from 'react'
import { useFormContext } from 'react-hook-form'

export const FormDevTool = () => {
  const LazyDevTool = import.meta.env.DEV
    ? lazy(async () => ({
        default: (await import('@hookform/devtools')).DevTool,
      }))
    : () => null

  const methods = useFormContext()
  return (
    <Suspense>
      <LazyDevTool control={methods.control} />
    </Suspense>
  )
}
