import { MAX_REASONABLE_NUMBER, OptionalAndNullable } from '@shared'
import { testIsValidMonetaryValue, YesNo } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { RenovationsInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    test('hasUpcomingRenovations', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasUpcomingRenovations).inside(Object.values(YesNo))
    })

    test('hasOtherPurchases', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasUpcomingRenovations).inside(Object.values(YesNo))
    })

    omitWhen(data.hasUpcomingRenovations !== YesNo.Yes, () => {
      testIsValidMonetaryValue(
        'renovationsAmount',
        data.renovationsAmount,
        formatMessage,
        MAX_REASONABLE_NUMBER,
        true,
        0,
      )
    })

    omitWhen(data.hasOtherPurchases !== YesNo.Yes, () => {
      testIsValidMonetaryValue(
        'otherPurchasesAmount',
        data.otherPurchasesAmount,
        formatMessage,
        MAX_REASONABLE_NUMBER,
        true,
        0,
      )
    })
  })
}
