import { getSecLoansLocalizedEnums } from '@sec-loans-ui/utils/getSecLoansLocalizedEnums'
import {
  BackgroundInfoBase,
  BackgroundInfoCommon,
  EmploymentInfoBase,
  MIN_EMPLOYMENT_DURATION_YEARS,
  OptionalAndNullable,
  PreviousEmploymentFields,
  SecLoanPreviousEmploymentTypeOptions,
} from '@shared'
import {
  convertUndefinedToNull,
  getDateDifferenceInFullYears,
  getFirstOfCurrentMonth,
  resolveMapValue,
  textInputToDate,
} from '@ui-common'
import { SummaryData } from '@ui-components'
import { IntlShape } from 'react-intl'

import { getCompletenessForTwoApplicants, isEmployee } from '../../../../utils/helpers'
import { ApplicantStepDataValidator, EmploymentInfoStepBase, EmploymentInfoStepData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { EmploymentInfoFormData } from './EmploymentInfoStep'
import { createFormValidator } from './formValidator'

export const convertToFormValues = (
  employmentInfo: EmploymentInfoStepBase | undefined,
): OptionalAndNullable<EmploymentInfoFormData> => {
  return convertUndefinedToNull({
    employer: employmentInfo?.employer,
    workingHours: employmentInfo?.workingHours,
    workingPosition: employmentInfo?.workingPosition,
    jobTitle: employmentInfo?.jobTitle,
    employmentStartDate: employmentInfo?.employmentStartDate,
    employmentEndDate: employmentInfo?.employmentEndDate,
    probation: employmentInfo?.probation,
    previousEmploymentType: employmentInfo?.previousEmploymentType,
    previousEmployerName: employmentInfo?.previousEmployment?.previousEmployerName,
    previousCompanyName: employmentInfo?.previousEmployment?.previousCompanyName,
    previousJobTitle: employmentInfo?.previousEmployment?.previousJobTitle,
    previousEmploymentStartDate: employmentInfo?.previousEmployment?.previousEmploymentStartDate,
    previousEmploymentEndDate: employmentInfo?.previousEmployment?.previousEmploymentEndDate,
    returnToWorkDate: employmentInfo?.returnToWorkDate,
  })
}

export const getAndValidateEmploymentInfoTypeData: ApplicantStepDataValidator<EmploymentInfoStepData> = ({
  applicantInfo,
}) => {
  const backgroundInfo = applicantInfo?.backgroundInfoData
  const employmentInfo = applicantInfo?.employmentInfoData
  const hasCoApplicant = !!applicantInfo?.numberOfApplicantsInfoData?.hasCoApplicant

  const isFirstApplicantEmployee = isEmployee(backgroundInfo, false)
  const isSecondApplicantEmployee = hasCoApplicant ? isEmployee(backgroundInfo, true) : false

  if (!isFirstApplicantEmployee && !isSecondApplicantEmployee) {
    return null
  }

  const isFirstApplicantValid = validateApplicant(
    isFirstApplicantEmployee,
    employmentInfo?.firstApplicant,
    backgroundInfo,
  )
  const isSecondApplicantValid = validateApplicant(
    isSecondApplicantEmployee,
    employmentInfo?.secondApplicant,
    backgroundInfo?.secondApplicant,
  )

  if (!isFirstApplicantValid && !isSecondApplicantValid) {
    return undefined
  }

  return {
    data: {
      firstApplicant: isFirstApplicantEmployee && isFirstApplicantValid ? employmentInfo?.firstApplicant : undefined,
      secondApplicant:
        isSecondApplicantEmployee && isSecondApplicantValid ? employmentInfo?.secondApplicant : undefined,
    },
    isComplete: getCompletenessForTwoApplicants({ hasCoApplicant, isFirstApplicantValid, isSecondApplicantValid }),
  }
}

const validateApplicant = (
  isApplicantEmployee: boolean,
  employmentInfo: EmploymentInfoBase | undefined,
  backgroundInfo: BackgroundInfoBase | BackgroundInfoCommon | undefined,
) => {
  if (!isApplicantEmployee) {
    return true
  }
  return isFormDataValid(convertToFormValues(employmentInfo), (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, backgroundInfo?.employmentType, backgroundInfo?.occupation),
  )
}

export enum PreviousEmploymentKind {
  Employee = 'employee',
  Entrepreneur = 'entrepreneur',
  NotAvailable = 'notAvailable',
}

export const getPreviousEmploymentKind = (
  previousEmploymentType: SecLoanPreviousEmploymentTypeOptions | undefined,
): PreviousEmploymentKind => {
  if (!previousEmploymentType) {
    return PreviousEmploymentKind.NotAvailable
  }

  const previousEmploymentTypes: Record<SecLoanPreviousEmploymentTypeOptions, PreviousEmploymentKind> = {
    [SecLoanPreviousEmploymentTypeOptions.FixedTerm]: PreviousEmploymentKind.Employee,
    [SecLoanPreviousEmploymentTypeOptions.Permanent]: PreviousEmploymentKind.Employee,
    [SecLoanPreviousEmploymentTypeOptions.Temporary]: PreviousEmploymentKind.Employee,
    [SecLoanPreviousEmploymentTypeOptions.EntrepreneurOrFreelancer]: PreviousEmploymentKind.Entrepreneur,
    [SecLoanPreviousEmploymentTypeOptions.NoPreviousEmployment]: PreviousEmploymentKind.NotAvailable,
  }

  return previousEmploymentTypes[previousEmploymentType]
}

export const isPreviousEmploymentRequired = (
  currentEmploymentStartMonthAndYear: string | undefined | null,
): boolean => {
  if (!currentEmploymentStartMonthAndYear) {
    return false
  }
  const startDate = textInputToDate(currentEmploymentStartMonthAndYear)
  const currentMonth = getFirstOfCurrentMonth()
  return getDateDifferenceInFullYears(startDate, currentMonth) < MIN_EMPLOYMENT_DURATION_YEARS
}

export const getPreviousEmploymentSummaryData = (
  previousEmploymentFields: PreviousEmploymentFields | undefined,
  previousEmploymentType: SecLoanPreviousEmploymentTypeOptions | undefined,
  applicantNumberSuffix: string,
  formatMessage: IntlShape['formatMessage'],
): SummaryData[] => {
  if (!previousEmploymentFields) {
    return []
  }
  const { employmentInfoPreviousEmploymentTypeLabelText } = getSecLoansLocalizedEnums(formatMessage)
  const previousEmploymentKind = getPreviousEmploymentKind(previousEmploymentType)
  const summaryData: SummaryData[] = [
    {
      sectionHeading: formatMessage({ id: 'employment-info-previous-employment' }),
      heading: formatMessage({ id: 'employment-info-employment-type-label' }),
      value: resolveMapValue(employmentInfoPreviousEmploymentTypeLabelText, previousEmploymentType),
      childData: [
        {
          heading: `${formatMessage({ id: 'employment-info-employer-label' })}${applicantNumberSuffix}`,
          value: previousEmploymentFields.previousEmployerName,
          condition: previousEmploymentKind === PreviousEmploymentKind.Employee,
        },
        {
          heading: formatMessage({ id: 'employment-info-company-label' }),
          value: previousEmploymentFields.previousCompanyName,
          condition: previousEmploymentKind === PreviousEmploymentKind.Entrepreneur,
        },
        {
          heading: formatMessage({ id: 'employment-info-profession-label' }),
          value: previousEmploymentFields.previousJobTitle,
          condition: previousEmploymentKind !== PreviousEmploymentKind.NotAvailable,
        },
        {
          heading: formatMessage({ id: 'employment-info-employment-start-label' }),
          value: previousEmploymentFields.previousEmploymentStartDate,
          condition: previousEmploymentKind !== PreviousEmploymentKind.NotAvailable,
        },
        {
          heading: formatMessage({ id: 'employment-info-employment-end-label' }),
          value: previousEmploymentFields.previousEmploymentEndDate,
          condition: previousEmploymentKind !== PreviousEmploymentKind.NotAvailable,
        },
      ],
    },
  ]
  return summaryData
}
