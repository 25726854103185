import { OptionalAndNullable, SecLoansValidationConstants } from '@shared'
import { validateShortTextInput, validateTextArea } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, omitWhen, only, Suite } from 'vest'

import { LoanAdditionalInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    const isAdditionalInfoRequired = false

    validateTextArea(
      formatMessage,
      'additionalInfo',
      data.additionalInfo,
      SecLoansValidationConstants.LOAN_INFO_ADDITIONAL_INFO_MAX_LENGTH,
      isAdditionalInfoRequired,
    )

    omitWhen(!data.campaignCode, () => {
      validateShortTextInput(
        'campaignCode',
        formatMessage,
        data.campaignCode,
        SecLoansValidationConstants.CAMPAIGN_CODE_MAX_LENGTH,
        false,
      )
    })
  })
}
