import { assertValue, OptionalAndNullable, PurchaseLoanInfoData } from '@shared'
import { convertUndefinedToNull, numberToString, toNumber } from '@ui-common'
import { IntlShape } from 'react-intl'

import { PurchaseLoanInfoStepData, StepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { PurchaseLoanInfoFormData } from './types'

export const convertToFormValues = (
  purchaseLoanInfo: PurchaseLoanInfoStepData | undefined,
): OptionalAndNullable<PurchaseLoanInfoFormData> => {
  return convertUndefinedToNull({
    loanAmount: numberToString(purchaseLoanInfo?.loanAmount),
    loanMonthlyPayment: numberToString(purchaseLoanInfo?.loanMonthlyPayment),
    loanPurpose: purchaseLoanInfo?.loanPurpose,
    loanIssuer: purchaseLoanInfo?.loanIssuer,
    otherPurpose: purchaseLoanInfo?.otherPurpose,
  })
}

export const convertToStepData = (formData: PurchaseLoanInfoFormData): PurchaseLoanInfoStepData => {
  return {
    loanAmount: assertValue(toNumber(formData?.loanAmount), 'formData.loanAmount'),
    loanMonthlyPayment: toNumber(formData?.loanMonthlyPayment),
    loanPurpose: formData?.loanPurpose,
    loanIssuer: formData.loanIssuer || undefined,
    otherPurpose: formData?.otherPurpose,
  }
}

export const getAndValidatePurchaseLoanInfoData: StepDataValidator<PurchaseLoanInfoData | undefined> = (
  validationData,
  applicationMetadata,
) => {
  const metadata = assertValue(applicationMetadata, 'applicationMetadata')
  const createValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, metadata.applicationPurpose)

  return isFormDataValid(convertToFormValues(validationData.targetInfo?.purchaseLoanInfo), createValidator)
    ? validationData.targetInfo?.purchaseLoanInfo
    : undefined
}
