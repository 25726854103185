import { trackPageView } from '@snowplow/browser-tracker'
import { TechnicalErrorStep } from '@ui-components/layout/TechnicalErrorStep'
import { Component, ReactNode } from 'react'
import { injectIntl, IntlShape } from 'react-intl'

import { sendErrorEventToAnalytics } from './analytics'

interface Props {
  children?: ReactNode
  intl: IntlShape
  onExitClick: () => void
  onError?: () => void
}

interface State {
  hasError: boolean
}

class StepErrorBoundaryComponent extends Component<Props, State> {
  state: State = {
    hasError: false,
  }

  static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    console.error('Uncaught error:', error, errorInfo)

    trackPageView({ title: 'StepErrorBoundary' })
    sendErrorEventToAnalytics(`Step error: ${error.message}`, error)

    if (this.props.onError) {
      this.props.onError()
    }
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <TechnicalErrorStep
          heading={this.props.intl.formatMessage({ id: 'common-unexpected-technical-error' })}
          content={this.props.intl.formatMessage({ id: 'common-error-temporary-text' })}
          buttonLabel={this.props.intl.formatMessage({ id: 'common-exit' })}
          onButtonClick={() => {
            if (sessionStorage) {
              sessionStorage.clear()
            }
            this.props.onExitClick()
          }}
        />
      )
    }
    return this.props.children
  }
}

export const StepErrorBoundary = injectIntl(StepErrorBoundaryComponent)
