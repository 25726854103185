import {
  assertValue,
  Bank,
  MAX_REASONABLE_NUMBER,
  OptionalAndNullable,
  SecLoansHousingLoanTargetType,
  SecLoansValidationConstants,
} from '@shared'
import {
  formatNumber,
  testIsValidMonetaryValue,
  testIsValidPercentageValue,
  validatePastMonthAndYear,
  YesNo,
} from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { LoanTransferTargetInfoFormData as FormData } from './types'

export const createFormValidator = ({
  formatMessage,
  isFirstTimeHomeBuyerQuestionRequired,
}: {
  formatMessage: IntlShape['formatMessage']
  isFirstTimeHomeBuyerQuestionRequired: boolean
}): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    // ENUMS
    test('loanTargetType', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.loanTargetType).inside(Object.values(SecLoansHousingLoanTargetType))
    })

    omitWhen(!isFirstTimeHomeBuyerQuestionRequired, () => {
      test('isLoanForFirstTimePurchase', formatMessage({ id: 'validate-required-field-missing' }), () => {
        enforce(data.isLoanForFirstTimePurchase).inside(Object.values(YesNo))
      })

      omitWhen(data.isLoanForFirstTimePurchase !== YesNo.Yes, () => {
        test('isAspLoan', formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(data.isAspLoan).inside(Object.values(YesNo))
        })
      })
    })

    test('loanIssuer', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.loanIssuer).inside(Object.values(Bank))
    })
    // END ENUMS

    // MONETARY
    testIsValidMonetaryValue('loanAmount', data.loanAmount, formatMessage)
    testIsValidMonetaryValue('resaleEstimate', data.resaleEstimate, formatMessage)
    testIsValidMonetaryValue('purchasePrice', data.purchasePrice, formatMessage)
    testIsValidMonetaryValue('loanMonthlyPayment', data.loanMonthlyPayment, formatMessage, MAX_REASONABLE_NUMBER)

    test(
      'loanMonthlyPayment',
      formatMessage(
        { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
        {
          firstValue: formatMessage({
            id: 'sec-loans-loan-transfer-target-loan-montly-installment-form-label',
          }),
          secondValue: formatMessage({ id: 'sec-loans-loan-transfer-target-loan-amount-form-label' }),
        },
      ),
      () => {
        const loanMonthlyPayment = assertValue(data.loanMonthlyPayment, 'data.loanMonthlyPayment')
        const loanAmount = assertValue(data.loanAmount, 'data.loanAmount')
        enforce(formatNumber(loanMonthlyPayment) <= formatNumber(loanAmount)).isTruthy()
      },
    )
    // END MONETARY

    // PERCENTAGE
    testIsValidPercentageValue('loanMarginPercentage', data.loanMarginPercentage, formatMessage, false)
    // END PERCENTAGE

    // TEXT MONTH YEAR
    validatePastMonthAndYear({
      fieldName: 'moveInMonth',
      formatMessage,
      dateToValidate: data.moveInMonth,
      minimumYear: SecLoansValidationConstants.MINIMUM_MOVE_IN_YEAR_IN_PAST,
      isRequired: true,
    })
    // END TEXT MONTH YEAR
  })
}
