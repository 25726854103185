import { AppName, assertValue, SecLoansExitUrlResponse } from '@shared'
import { ENDPOINT_SEC_LOANS_GET_EXIT_URLS } from '@shared/sec-loans/endpoints'
import { callEndpoint, debugLog, redirectToMaintenancePageIfNeeded, resolveAndStoreLanguage } from '@ui-common'
import { assign, fromPromise, sendParent, setup } from 'xstate'

import {
  getFallbackExitUrls,
  getSecLoansFlow,
  getSecLoansSourceChannel,
  resolveAndStoreFlow,
  resolveAndStoreSourceChannel,
} from '../../../utils/helpers'

export const GET_AND_STORE_INPUT_PARAMS = 'getAndStoreInputParams'
export const ENSURE_BFF_AVAILABILITY = 'ensureBffAvailability'
export const GET_EXIT_URLS = 'getExitUrls'
export const ERROR = 'error'
export const ABORT_PROMPT = 'abortPrompt'

export type InitializeStepContext = {
  exitUrls?: SecLoansExitUrlResponse
}

export type ExitEvent = { type: 'EXIT' }
export type RetryEvent = { type: 'RETRY' }

export type InitializeStepEvent = RetryEvent | ExitEvent

export const initializeStepMachine = setup({
  types: {
    context: {} as InitializeStepContext,
    events: {} as InitializeStepEvent,
  },
  actors: {
    getAndStoreInputParams: fromPromise(async () => {
      debugLog('Service: getAndStoreInputParams')
      resolveAndStoreSourceChannel()
      resolveAndStoreFlow()
      resolveAndStoreLanguage()
    }),
    ensureBffAvailability: fromPromise(async () => {
      debugLog('Service: ensureBffAvailability')
      await redirectToMaintenancePageIfNeeded(import.meta.env.VITE_ENV_ID, AppName.SecLoans)
    }),
    getExitUrls: fromPromise(() => {
      debugLog('Service: getExitUrls')
      return callEndpoint(ENDPOINT_SEC_LOANS_GET_EXIT_URLS, {
        queryParams: {
          sourceChannel: getSecLoansSourceChannel(true),
          flow: getSecLoansFlow(true),
        },
      })
    }),
  },
  actions: {
    exit: sendParent(({ context }) => {
      return {
        type: 'SAVE_EXIT_URLS_AND_EXIT_WIZARD',
        data: { exitUrls: assertValue(context.exitUrls, 'context.exitUrls') },
      }
    }),
    storeExitUrls: assign((_, data: SecLoansExitUrlResponse) => {
      debugLog('Action: Store exit URLs')
      return { exitUrls: data }
    }),
    proceedToNextStep: sendParent(({ context }) => {
      return {
        type: 'NEXT_STEP',
        data: { exitUrls: assertValue(context.exitUrls, 'context.exitUrls') },
      }
    }),
    storeFallbackExitUrls: assign(() => {
      debugLog('Action: Store fallback exit URLs')
      return { exitUrls: getFallbackExitUrls() }
    }),
  },
}).createMachine({
  id: 'initializeStepMachine',
  initial: GET_AND_STORE_INPUT_PARAMS,
  states: {
    [GET_AND_STORE_INPUT_PARAMS]: {
      invoke: {
        src: 'getAndStoreInputParams',
        onDone: [
          {
            target: ENSURE_BFF_AVAILABILITY,
          },
        ],
      },
    },
    [ENSURE_BFF_AVAILABILITY]: {
      invoke: {
        src: 'ensureBffAvailability',
        onDone: [
          {
            target: GET_EXIT_URLS,
          },
        ],
      },
    },
    [GET_EXIT_URLS]: {
      invoke: {
        src: 'getExitUrls',
        onDone: {
          actions: [{ type: 'storeExitUrls', params: ({ event }) => event.output }, { type: 'proceedToNextStep' }],
        },
        onError: {
          actions: [{ type: 'storeFallbackExitUrls' }],
          target: ERROR,
        },
      },
    },
    [ERROR]: {
      on: {
        EXIT: { actions: { type: 'exit' } },
        RETRY: GET_EXIT_URLS,
      },
    },
  },
})
