import { getCommonLocalizedEnums } from '@ui-common/utils'
import { CopyButton } from '@ui-components/actions/special/CopyButton'
import { Notification } from '@ui-components/containers/Notification'
import { Divider } from '@ui-components/decorative/Divider'
import { MediumText } from '@ui-components/decorative/Typography'
import { getShortRequestId } from 'packages/ui-components/lib/utilities/helpers'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { copyToClipboard } from '../../utils/helpers'

export interface RequestIdSectionProps {
  requestId: string
}

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RequestIdSection = ({ requestId }: RequestIdSectionProps) => {
  const [isCopied, setIsCopied] = useState(false)

  const intl = useIntl()

  const { isCopiedText } = getCommonLocalizedEnums(intl.formatMessage)

  const requestIdElementId = 'requestId'

  const handleCopy = async () => {
    setIsCopied(true)
    await copyToClipboard(requestIdElementId)
  }

  const header = (
    <>
      <FormattedMessage id="common-error-request-id-text" />
      <BottomContainer>
        <MediumText id={requestIdElementId}>{getShortRequestId(requestId)}</MediumText>
        <CopyButton onClick={() => handleCopy()} label={isCopiedText.get(isCopied)} />
      </BottomContainer>
    </>
  )

  return (
    <>
      <Divider spacing="medium" />
      <div>
        <Notification variant="plain" iconType="info" header={header} />
      </div>
    </>
  )
}
