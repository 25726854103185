import { getAndValidateLoanCostEstimateTypeData } from '@sec-loans-ui/wizard/steps/loan-target-phase/loan-cost-estimate-info-step/loanCostEstimateInfoStepUtils'
import {
  AddressInfoData,
  assertValue,
  HousingLoanInfo,
  LivingExpensesInfoData,
  LoanCostEstimateInfoData,
  LocationInfoData,
  PriceInfoData,
  PurchaseLoanInfoData,
  RealEstateLivingExpensesInfoData,
  RenovationsInfoData,
  ResidenceInfoData,
  SecLoanApplicationType,
  SecLoanPurposeType,
  SecLoansApplicationMetadata,
  TargetAdditionalInfoData,
  TargetTypeInfoData,
} from '@shared'

import { PhaseDataHandler, TargetInfoWizardData, TargetInfoWizardDataType, WizardData } from '../../types'
import { getAndValidateAdditionalInfoTypeData } from './additional-info-step/additionalInfoStepUtils'
import { getAndValidateAddressInfoTypeData } from './address-info-step/addressInfoStepUtils'
import { getAndValidateConstructionLoanTargetTypeData } from './construction-loan-target-info-step/constructionLoanTargetInfoStepUtils'
import { getAndValidateLoanTransferData } from './housing-loan-transfer-target-info-step/housingLoanTransferTargetInfoStepUtils'
import { getAndValidateLivingExpensesInfoTypeData } from './living-expenses-info-step/livingExpensesInfoStepUtils'
import { getAndValidateLocationTypeData } from './location-info-step/locationInfoStepUtils'
import { getAndValidatePriceTypeData } from './price-info-step/priceInfoStepUtils'
import { getAndValidatePurchaseLoanInfoData } from './purchase-loan-info-step/purchaseLoanInfoStepUtils'
import { getAndValidateRealEstateLivingExpensesTypeData } from './real-estate-living-expenses-info-step/realEstateLivingExpensesInfoStepUtils'
import { getAndValidateRenovationsTypeData } from './renovations-info-step/renovationsInfoStepUtils'
import { getAndValidateResidenceInfoTypeData } from './residence-info-step/residenceInfoStepUtils'
import { getAndValidateTargetTypeData } from './target-type-info-step/targetTypeInfoStepUtils'

type TargetInfoApplicationPhaseData =
  | TargetTypeInfoData
  | HousingLoanInfo
  | PriceInfoData
  | LocationInfoData
  | AddressInfoData
  | ResidenceInfoData
  | RealEstateLivingExpensesInfoData
  | LivingExpensesInfoData
  | TargetAdditionalInfoData
  | PurchaseLoanInfoData
  | RenovationsInfoData
  | LoanCostEstimateInfoData

export const getTargetInfoPhaseData = (
  applicationData: WizardData,
  metadata: SecLoansApplicationMetadata,
): TargetInfoWizardDataType | undefined => {
  if (!applicationData.targetInfo) {
    return
  }

  const loanTargetValidator: PhaseDataHandler<TargetInfoApplicationPhaseData | undefined> =
    metadata.applicationPurpose === SecLoanPurposeType.LoanTransferToSbank
      ? {
          location: {
            targetInfo: {
              housingLoanInfo: { transferredLoanTargetData: null, targetTypeInfo: { targetType: null } },
            },
          },
          validate: getAndValidateLoanTransferData,
        }
      : {
          location: { targetInfo: { housingLoanInfo: { targetTypeInfo: { targetType: null } } } },
          validate: getAndValidateTargetTypeData,
        }

  const priceValidator = {
    location: { targetInfo: { priceInfo: null } },
    validate: getAndValidatePriceTypeData,
  }

  const loanCostEstimateValidator = {
    location: { targetInfo: { loanCostEstimateInfo: null } },
    validate: getAndValidateLoanCostEstimateTypeData,
  }

  const constructionLoanTargetInfoValidator = {
    location: { targetInfo: { constructionLoanTargetInfo: null } },
    validate: getAndValidateConstructionLoanTargetTypeData,
  }

  const renovationInfoValidator = {
    location: { targetInfo: { renovationsInfoData: null } },
    validate: getAndValidateRenovationsTypeData,
  }

  let targetInfoDataHandlers: PhaseDataHandler<TargetInfoApplicationPhaseData | undefined | null>[] = [
    ...([SecLoanPurposeType.Construction, SecLoanPurposeType.Renovation].includes(metadata.applicationPurpose)
      ? []
      : [loanTargetValidator]),
    ...(metadata.applicationPurpose === SecLoanPurposeType.Renovation ? [] : [renovationInfoValidator]),
    ...(metadata.applicationPurpose === SecLoanPurposeType.Buy ? [priceValidator] : []),
    ...(metadata.applicationPurpose === SecLoanPurposeType.Construction
      ? [loanCostEstimateValidator, constructionLoanTargetInfoValidator]
      : []),
    ...(metadata.applicationPurpose === SecLoanPurposeType.Renovation ? [loanCostEstimateValidator] : []),
    {
      location: { targetInfo: { locationInfoData: null } },
      validate: getAndValidateLocationTypeData,
    },
    {
      location: { targetInfo: { livingExpensesInfoData: null } },
      validate: getAndValidateLivingExpensesInfoTypeData,
    },
    {
      location: { targetInfo: { addressInfoData: null } },
      validate: getAndValidateAddressInfoTypeData,
    },
    {
      location: { targetInfo: { residenceInfoData: null } },
      validate: getAndValidateResidenceInfoTypeData,
    },
    {
      location: { targetInfo: { realEstateLivingExpensesInfoData: null } },
      validate: getAndValidateRealEstateLivingExpensesTypeData,
    },
    {
      location: { targetInfo: { additionalInfoData: null } },
      validate: getAndValidateAdditionalInfoTypeData,
    },
  ]

  if (metadata.applicationType === SecLoanApplicationType.PurchaseLoan) {
    targetInfoDataHandlers = [
      {
        location: { targetInfo: { purchaseLoanInfo: null } },
        validate: getAndValidatePurchaseLoanInfoData,
      },
    ]
  }

  const initialTargetInfo: TargetInfoWizardDataType = { targetInfo: { isComplete: true } }
  return targetInfoDataHandlers.reduce((validatedData, currentStep) => {
    const targetInfo = assertValue(currentStep.location.targetInfo, 'currentStep.location.targetInfo')
    const location = Object.keys(targetInfo)[0] as keyof TargetInfoWizardData
    const dataCandidate = currentStep.validate(
      {
        targetInfo: { ...validatedData.targetInfo, [location]: applicationData.targetInfo?.[location] },
      },
      metadata,
    )
    const isComplete = validatedData.targetInfo?.isComplete && dataCandidate !== undefined
    return {
      targetInfo: {
        ...validatedData.targetInfo,
        [location]: dataCandidate ?? undefined,
        isComplete,
      },
    }
  }, initialTargetInfo)
}
