import { VisuallyHidden } from '@s-group/design-system-components/VisuallyHidden/VisuallyHidden'
import { LoadingSpinner } from '@ui-components/decorative/LoadingSpinner'
import { MediumText, SmallHeading } from '@ui-components/decorative/Typography'
import { SPACE_SMALL } from '@ui-components/global/spacing'
import { PAGE_LOADER_CLASS } from '@ui-components/utilities/helpers'
import { AriaAttributes } from 'react'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

export const loadingContainerTestId = 'loading-container'
export const loadingAriaLiveContainerTestId = 'aria-live-loading-container'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:focus-visible {
    outline: none;
  }

  > * {
    margin: auto;
    margin-bottom: 0;
  }
`

const SpinnerWrapper = styled.div`
  padding-bottom: ${SPACE_SMALL};
  line-height: 0;
`

export interface TransitionLoadingIndicatorProps {
  heading?: string
  text?: string
  'aria-live'?: AriaAttributes['aria-live']
}

export const TransitionLoadingIndicator = ({
  heading,
  text,
  ...props
}: TransitionLoadingIndicatorProps): JSX.Element => {
  const intl = useIntl()

  const loadingTexts = (
    <>
      <SmallHeading tagName="h1">{heading || intl.formatMessage({ id: 'common-wait' })}</SmallHeading>
      <MediumText $color="primary">{text || intl.formatMessage({ id: 'common-wait-description' })}</MediumText>
    </>
  )

  return (
    <>
      {/* This element is automatically focused with updatePageFocus helper function */}
      <Container data-testid={loadingContainerTestId} className={PAGE_LOADER_CLASS} tabIndex={-1}>
        <SpinnerWrapper>
          <LoadingSpinner aria-hidden={true} alt={intl.formatMessage({ id: 'common-wait' })} />
        </SpinnerWrapper>
        {loadingTexts}
      </Container>

      {/* Have separate hidden section for SR announcements (for text changes) because above section is focused automatically */}
      {props['aria-live'] && (
        <VisuallyHidden>
          <div aria-live={props['aria-live']} aria-atomic="true" data-testid={loadingAriaLiveContainerTestId}>
            {loadingTexts}
          </div>
        </VisuallyHidden>
      )}
    </>
  )
}
