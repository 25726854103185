import { OptionalAndNullable, SecLoansValidationConstants } from '@shared'
import { validateTextArea } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, omitWhen, only, Suite } from 'vest'

import { WizardData } from '../../../types'
import { TargetAdditionalInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  applicationData: WizardData,
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    const hasRenovations =
      !!applicationData.targetInfo?.renovationsInfoData?.renovationsAmount ||
      !!applicationData.targetInfo?.renovationsInfoData?.otherPurchasesAmount

    const isAdditionalInfoRequired = false

    validateTextArea(
      formatMessage,
      'additionalInfo',
      data.additionalInfo,
      SecLoansValidationConstants.ADDITIONAL_INFO_MAX_LENGTH,
      isAdditionalInfoRequired,
    )

    omitWhen(!hasRenovations, () => {
      validateTextArea(
        formatMessage,
        'additionalRenovationsInfo',
        data.additionalRenovationsInfo,
        SecLoansValidationConstants.RENOVATION_ADDITIONAL_INFO_MAX_LENGTH,
        true,
      )
    })
  })
}
