import {
  assertValue,
  CollateralInfoData,
  InsuranceInfoData,
  LoanAdditionalInfoData,
  LoanBackgroundInfoData,
  MonthlySavingsInfoData,
  PaymentPlanInfoData,
  ReferenceRateInfoData,
  SecLoansApplicationMetadata,
} from '@shared'

import { PhaseDataHandler, WizardData } from '../../types'
import { getAndValidateAdditionalInfoTypeData } from './additional-info-step/additionalInfoStepUtils'
import { getAndValidateCollateralInfoTypeData } from './collateral-info-step/collateralInfoStepUtils'
import { getAndValidateInsuranceInfoTypeData } from './insurance-info-step/insuranceInfoStepUtils'
import { getAndValidateLoanBackgroundInfoTypeData } from './loan-background-info-step/loanBackgroundInfoStepUtils'
import { getAndValidateMonthlySavingsInfoTypeData } from './monthly-savings-info-step/monthlySavingsInfoStepUtils'
import { getAndValidatePaymentPlanInfoTypeData } from './payment-plan-info-step/paymentPlanInfoStepUtils'
import { getAndValidateReferenceRateInfoTypeData } from './reference-rate-info-step/referenceRateInfoStepUtils'
import { LoanInfoWizardData, LoanInfoWizardDataType } from './types'

type LoanInfoApplicationPhaseData =
  | LoanBackgroundInfoData
  | PaymentPlanInfoData
  | ReferenceRateInfoData
  | InsuranceInfoData
  | CollateralInfoData
  | MonthlySavingsInfoData
  | LoanAdditionalInfoData

export const getLoanInfoPhaseData = (
  applicationData: WizardData,
  applicationMetadata: SecLoansApplicationMetadata,
): LoanInfoWizardDataType | undefined => {
  if (!applicationData.loanInfo) {
    return
  }

  const loanInfoDataHandlers: PhaseDataHandler<LoanInfoApplicationPhaseData | undefined | null>[] = [
    {
      location: { loanInfo: { loanBackgroundInfoData: null } },
      validate: getAndValidateLoanBackgroundInfoTypeData,
    },
    {
      location: { loanInfo: { paymentPlanInfoData: null } },
      validate: getAndValidatePaymentPlanInfoTypeData,
    },
    {
      location: { loanInfo: { referenceRateInfoData: null } },
      validate: getAndValidateReferenceRateInfoTypeData,
    },
    {
      location: { loanInfo: { insuranceInfoData: null } },
      validate: getAndValidateInsuranceInfoTypeData,
    },
    {
      location: { loanInfo: { collateralInfoData: null } },
      validate: getAndValidateCollateralInfoTypeData,
    },
    {
      location: { loanInfo: { monthlySavingsInfoData: null } },
      validate: getAndValidateMonthlySavingsInfoTypeData,
    },
    {
      location: { loanInfo: { loanAdditionalInfoData: null } },
      validate: getAndValidateAdditionalInfoTypeData,
    },
  ]
  const initialLoanInfo: LoanInfoWizardDataType = { loanInfo: { isComplete: true } }
  return loanInfoDataHandlers.reduce((validatedData, currentStep) => {
    const loanInfo = assertValue(currentStep.location.loanInfo, 'currentStep.location.loanInfo')
    const location = Object.keys(loanInfo)[0] as keyof LoanInfoWizardData
    const dataCandidate = currentStep.validate(
      {
        targetInfo: applicationData.targetInfo,
        applicantInfo: applicationData.applicantInfo,
        loanInfo: { ...validatedData.loanInfo, [location]: applicationData.loanInfo?.[location] },
      },
      applicationMetadata,
    )
    const isComplete = validatedData.loanInfo?.isComplete && dataCandidate !== undefined

    return {
      loanInfo: {
        ...validatedData.loanInfo,
        [location]: dataCandidate ?? undefined,
        isComplete,
      },
    }
  }, initialLoanInfo)
}
