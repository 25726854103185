import { RemoveItemButton } from '@ui-components/actions/special/RemoveItemButton'
import { getActionTargetAriaLabel } from '@ui-components/utilities/textHelpers'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

interface RemoveButtonProps extends Omit<React.ComponentProps<typeof RemoveItemButton>, 'label' | 'aria-label'> {
  targetLabel: string
}

const NoWrapRemoveItemButton = styled(RemoveItemButton)`
  white-space: nowrap;
`

export const RemoveButton = ({ targetLabel, type, ...rest }: RemoveButtonProps) => {
  const intl = useIntl()
  const label = intl.formatMessage({ id: 'common-remove' })

  return (
    <NoWrapRemoveItemButton
      {...rest}
      type={type ?? 'button'}
      label={label}
      aria-label={getActionTargetAriaLabel(label, targetLabel)}
    />
  )
}
