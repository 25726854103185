import { usePersistingActor } from '@ui-common/hooks'
import { useEffectOnce } from '@ui-common/hooks/useEffectOnce'
import { isAuthCallback } from '@ui-common/utils/authentication'
import { useSelector } from '@xstate/react'
import { useMemo, useState } from 'react'

import {
  AUTH_CANCELLED,
  AUTH_ERROR,
  authenticateStepMachine,
  GET_AUTH_CODE,
  GET_AUTH_URL,
  PARSE_AUTH_CODE,
  VERIFY_AUTH_CODE,
} from './authenticateStepMachine'
import { AuthenticateInput, AuthenticationState } from './types'

export const useAuthenticateState = (input: AuthenticateInput): AuthenticationState => {
  useEffectOnce(() => {
    if (isAuthCallback()) {
      actor.send({ type: 'PARSE_AUTH_CODE' })
    }
  })

  const [authenticateMachine] = useState(() => authenticateStepMachine(input))

  const actor = usePersistingActor(authenticateMachine, (state) => state?.value === GET_AUTH_CODE)

  const state = useSelector(actor, (s) => s)

  const loadingStates = [GET_AUTH_URL, GET_AUTH_CODE, PARSE_AUTH_CODE, VERIFY_AUTH_CODE]
  const isLoading = loadingStates.includes(state.value)
  const isCancelled = state.matches(AUTH_CANCELLED)
  const hasError = state.matches(AUTH_ERROR)
  const error = state.context.error

  return useMemo(() => {
    return {
      isLoading,
      isCancelled,
      hasError,
      abort: () => actor.send({ type: 'ABORT' }),
      retry: () => actor.send({ type: 'AUTHENTICATE' }),
      error,
    }
  }, [isLoading, isCancelled, hasError, actor, error])
}
