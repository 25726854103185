import { Language } from '@shared'
import { useContext } from 'react'

import { StorageKey } from '../types'
import { LanguageCtx, setLocalStorageItem } from '../utils'

type ReturnObj = {
  changeLanguage: () => void
  currentLang: Language
}

export const useLanguage = (): ReturnObj => {
  const { currentLang, setCurrentLang } = useContext(LanguageCtx)
  return {
    currentLang,
    changeLanguage: () => {
      const newLang = currentLang === Language.Fi ? Language.Sv : Language.Fi
      setCurrentLang(newLang)
      setLocalStorageItem(StorageKey.Language, newLang)
    },
  }
}
