import { FormGroupOption } from '@ui-components/global/ComponentProps'
import { ChangeEvent, forwardRef } from 'react'
import { useIntl } from 'react-intl'

import { SnowPlowData } from '../analytics'
import { CsatFormData, CsatFormHandle, CsatQuestionnaire } from './CsatQuestionnaire'

interface CsatExitReasonProps {
  error?: string
  onContinue?: () => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  options: FormGroupOption[]
}

export const CsatExitReasonQuestionnaire = forwardRef<CsatFormHandle, CsatExitReasonProps>((props, ref) => {
  const { onContinue, error, onChange, options } = props
  const intl = useIntl()

  const getAnalyticsData = (data: CsatFormData): SnowPlowData | undefined => {
    if (data.exitReason) {
      return { exitReason: data.exitReason, csatFeedback: data.feedback ?? '' }
    }
    return undefined
  }

  return (
    <CsatQuestionnaire
      getAnalyticsData={getAnalyticsData}
      onContinue={onContinue}
      options={options}
      formId={'exitReason'}
      formLabel={intl.formatMessage({ id: 'common-csat-exit-reason-form-label' })}
      feedbackLabel={intl.formatMessage({ id: 'common-csat-exit-reason-feedback-label' })}
      ref={ref}
      error={error}
      onChange={onChange}
    />
  )
})

CsatExitReasonQuestionnaire.displayName = 'CsatExitReasonQuestionnaire'
