import {
  assertValue,
  MAX_REASONABLE_NUMBER,
  OptionalAndNullable,
  SecLoansApplicants,
  SecLoansInvestmentType,
  SecLoansPropertyType,
} from '@shared'
import { formatNumber, testIsValidMonetaryValue, YesNo } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { WealthInfoStepFormData as FormData } from './WealthInfoStep'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  hasCoApplicant: boolean,
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> =>
  create((data, changedField) => {
    only(changedField)

    test('hasSavings', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasSavings).inside(Object.values(YesNo))
    })

    test('hasProperties', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasProperties).inside(Object.values(YesNo))
    })

    test('hasInvestments', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.hasInvestments).inside(Object.values(YesNo))
    })

    omitWhen(data.hasSavings !== YesNo.Yes, () => {
      testIsValidMonetaryValue('savingsAmount', data.savingsAmount, formatMessage)

      omitWhen(!hasCoApplicant, () => {
        test('ownership', formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(data.ownership).inside(Object.values(SecLoansApplicants))
        })
      })
    })

    omitWhen(data.hasProperties !== YesNo.Yes, () => {
      Object.keys(data.properties ?? []).forEach((_key, index) => {
        const properties = data.properties?.[index]
        test(`properties.${index}.propertyType`, formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(properties?.propertyType).inside(Object.values(SecLoansPropertyType))
        })

        omitWhen(properties?.propertyType !== SecLoansPropertyType.HousingStock, () => {
          test(`properties.${index}.hasCompanyDebt`, formatMessage({ id: 'validate-required-field-missing' }), () => {
            enforce(properties?.hasCompanyDebt).inside(Object.values(YesNo))
          })
        })

        omitWhen(properties?.hasCompanyDebt !== YesNo.Yes, () => {
          testIsValidMonetaryValue(
            `properties.${index}.companyDebtAmount`,
            properties?.companyDebtAmount,
            formatMessage,
          )

          testIsValidMonetaryValue(
            `properties.${index}.monthlyPaymentToCompanyDebt`,
            properties?.monthlyPaymentToCompanyDebt,
            formatMessage,
            MAX_REASONABLE_NUMBER,
            true,
            0,
          )

          omitWhen(!properties?.monthlyPaymentToCompanyDebt || !properties.companyDebtAmount, () => {
            test(
              `properties.${index}.monthlyPaymentToCompanyDebt`,
              formatMessage(
                { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
                {
                  firstValue: formatMessage({
                    id: 'sec-loans-target-housing-company-loan-payment-label',
                  }),
                  secondValue: formatMessage({ id: 'sec-loans-wealth-info-property-company-debt-amount-label' }),
                },
              ),
              () => {
                const monthlyPaymentToCompanyDebt = assertValue(
                  properties?.monthlyPaymentToCompanyDebt,
                  'properties.monthlyPaymentToCompanyDebt',
                )
                const companyDebtAmount = assertValue(properties?.companyDebtAmount, 'properties.companyDebtAmount')
                enforce(formatNumber(monthlyPaymentToCompanyDebt) <= formatNumber(companyDebtAmount)).isTruthy()
              },
            )
          })
        })

        testIsValidMonetaryValue(`properties.${index}.value`, properties?.value, formatMessage)

        omitWhen(!hasCoApplicant, () => {
          test(`properties.${index}.ownership`, formatMessage({ id: 'validate-required-field-missing' }), () => {
            enforce(properties?.ownership).inside(Object.values(SecLoansApplicants))
          })
        })
      })
    })
    omitWhen(data.hasInvestments !== YesNo.Yes, () => {
      data.investments?.forEach((investment, index) => {
        test(`investments.${index}.investmentType`, formatMessage({ id: 'validate-required-field-missing' }), () => {
          enforce(investment?.investmentType).inside(Object.values(SecLoansInvestmentType))
        })

        testIsValidMonetaryValue(`investments.${index}.value`, investment?.value, formatMessage)

        omitWhen(!hasCoApplicant, () => {
          test(`investments.${index}.ownership`, formatMessage({ id: 'validate-required-field-missing' }), () => {
            enforce(investment?.ownership).inside(Object.values(SecLoansApplicants))
          })
        })
      })
    })
  })
