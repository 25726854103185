import { AnalyticsService } from '@components/common/analytics'
import { useEffect, useRef } from 'react'

export const useSendPageViewEvent = <TPageEvent, TCustomEventName>(
  analyticsService: AnalyticsService<TPageEvent, TCustomEventName>,
  name: TPageEvent,
): void => {
  const lastSentPage = useRef<TPageEvent | null>(null)

  useEffect(() => {
    // Avoid sending same page view multiple times in a row (React StrictMode)
    if (name !== lastSentPage.current) {
      analyticsService.sendPageViewEvent(name)
      lastSentPage.current = name
    }
  }, [analyticsService, name])
}
