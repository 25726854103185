import { AppId, Language } from '@shared'
import { CommonBankApp } from '@ui-common'
import { MessageFormatElement } from 'react-intl'

import messagesFi from './l10n/fi.json'
import messagesSv from './l10n/sv.json'
import { SecLoansAnalyticsContextProvider } from './state/analyticsContext'
import { ApplicationWizardProvider, AuthenticatedState } from './state/applicationWizardContext'
import { Wizard } from './wizard/Wizard'

const messages: Record<Language, Record<string, string> | Record<string, MessageFormatElement[]>> = {
  [Language.Fi]: messagesFi,
  [Language.Sv]: messagesSv,
}

interface AppProps {
  authenticatedState?: AuthenticatedState
}

const App = ({ authenticatedState }: AppProps): JSX.Element => {
  return (
    <CommonBankApp appId={AppId.SecLoans} translations={messages}>
      <SecLoansAnalyticsContextProvider>
        <ApplicationWizardProvider authenticatedState={authenticatedState}>
          <Wizard />
        </ApplicationWizardProvider>
      </SecLoansAnalyticsContextProvider>
    </CommonBankApp>
  )
}

export default App
