export const STEP_SUCCEEDED = 'SucceededStep'
export const STEP_ABORTED = 'AbortedStep'

export const STEP_LOAN_BACKGROUND_INFO = 'StepLoanBackgroundInfo'
export const STEP_PAYMENT_PLAN_INFO = 'StepPaymentPlanInfo'
export const STEP_ECONOMIC_ASSESSMENT_INFO = 'StepEconomicAssessmentInfo'
export const STEP_REFERENCE_RATE_INFO = 'StepReferenceRateInfo'
export const STEP_INSURANCE_INFO = 'StepInsuranceInfo'
export const STEP_COLLATERAL_INFO = 'StepCollateralInfo'
export const STEP_MONTHLY_SAVINGS_INFO = 'StepMonthlySavingsInfo'
export const STEP_LOAN_ADDITIONAL_INFO = 'StepLoanAdditionalInfo'

export const LoanInfoStep = [
  STEP_LOAN_BACKGROUND_INFO,
  STEP_PAYMENT_PLAN_INFO,
  STEP_ECONOMIC_ASSESSMENT_INFO,
  STEP_REFERENCE_RATE_INFO,
  STEP_INSURANCE_INFO,
  STEP_COLLATERAL_INFO,
  STEP_MONTHLY_SAVINGS_INFO,
  STEP_LOAN_ADDITIONAL_INFO,
] as const
export type LoanInfoStep = (typeof LoanInfoStep)[number]

export type LoanInfoPage = LoanInfoStep | typeof STEP_SUCCEEDED | typeof STEP_ABORTED

export const STEP_TARGET_TYPE_INFO = 'StepTargetTypeInfo'
export const STEP_LOAN_COST_ESTIMATE_INFO = 'StepLoanCostEstimateInfo'
export const STEP_CONSTRUCTION_LOAN_TARGET_INFO_STEP = 'StepConstructionLoanTargetInfo'
export const STEP_LOAN_TARGET_TYPE_INFO = 'StepLoanTargetTypeInfo'
export const STEP_PRICE_INFO = 'StepPriceInfo'
export const STEP_LOCATION_INFO = 'StepLocationInfo'
export const STEP_ADDRESS_INFO = 'StepAddressInfo'
export const STEP_RESIDENCE_INFO = 'StepResidenceInfo'
export const STEP_LIVING_EXPENSES_INFO = 'StepLivingExpensesInfo'
export const STEP_TARGET_ADDITIONAL_INFO = 'StepTargetAdditionalInfo'
export const STEP_PURCHASE_LOAN_INFO = 'StepPurchaseLoanInfo'
export const STEP_RENOVATIONS_INFO = 'StepRenovationsInfo'
export const STEP_REAL_ESTATE_LIVING_EXPENSES = 'RealEstateLivingExpensesInfo'

export const TargetInfoStep = [
  STEP_LOAN_TARGET_TYPE_INFO,
  STEP_LOAN_COST_ESTIMATE_INFO,
  STEP_CONSTRUCTION_LOAN_TARGET_INFO_STEP,
  STEP_TARGET_TYPE_INFO,
  STEP_PRICE_INFO,
  STEP_LOCATION_INFO,
  STEP_ADDRESS_INFO,
  STEP_RESIDENCE_INFO,
  STEP_REAL_ESTATE_LIVING_EXPENSES,
  STEP_LIVING_EXPENSES_INFO,
  STEP_TARGET_ADDITIONAL_INFO,
  STEP_PURCHASE_LOAN_INFO,
  STEP_RENOVATIONS_INFO,
] as const
export type TargetInfoStep = (typeof TargetInfoStep)[number]

export type TargetInfoPage = TargetInfoStep | typeof STEP_SUCCEEDED | typeof STEP_ABORTED

export const STEP_NUMBER_OF_APPLICANTS = 'StepNumberOfApplicants'
export const STEP_BACKGROUND_INFO = 'StepBackgroundInfo'
export const STEP_EMPLOYMENT_INFO = 'StepEmploymentInfo'
export const STEP_ENTREPRENEUR_INFO = 'StepEntrepreneurInfo'
export const STEP_INCOME_INFO = 'StepIncomeInfo'
export const STEP_EXPENSES_INFO = 'StepExpensesInfo'
export const STEP_SECOND_APPLICANT_BACKGROUND_INFO = 'StepSecondApplicantBackgroundInfo'
export const STEP_SECOND_APPLICANT_EMPLOYMENT_INFO = 'StepSecondApplicantEmploymentInfo'
export const STEP_SECOND_APPLICANT_ENTREPRENEUR_INFO = 'StepSecondApplicantEntrepreneurInfo'
export const STEP_SECOND_APPLICANT_INCOME_INFO = 'StepSecondApplicantIncomeInfo'
export const STEP_SECOND_APPLICANT_EXPENSES_INFO = 'StepSecondApplicantExpensesInfo'
export const STEP_HOUSING_INFO = 'StepHousingInfo'
export const STEP_CURRENT_RESIDENCE_INFO = 'StepCurrentResidenceInfo'
export const STEP_WEALTH_INFO = 'StepWealthInfo'
export const STEP_CREDIT_INFO = 'StepCreditInfo'
export const STEP_LOANS_AND_CREDITS_INFO = 'StepLoansAndCreditsInfo'
export const STEP_GUARANTEES_INFO = 'StepGuaranteesInfo'
export const STEP_APPLICANT_ADDITIONAL_INFO = 'StepApplicantAdditionalInfo'
export const STEP_INSTALLMENT_INFO = 'StepInstallmentInfo'

export const secondApplicantInfoStep = [
  STEP_SECOND_APPLICANT_BACKGROUND_INFO,
  STEP_SECOND_APPLICANT_EMPLOYMENT_INFO,
  STEP_SECOND_APPLICANT_ENTREPRENEUR_INFO,
  STEP_SECOND_APPLICANT_INCOME_INFO,
  STEP_SECOND_APPLICANT_EXPENSES_INFO,
] as const
export const ApplicantInfoStep = [
  STEP_NUMBER_OF_APPLICANTS,
  STEP_BACKGROUND_INFO,
  STEP_EMPLOYMENT_INFO,
  STEP_ENTREPRENEUR_INFO,
  STEP_INCOME_INFO,
  STEP_EXPENSES_INFO,
  ...secondApplicantInfoStep,
  STEP_HOUSING_INFO,
  STEP_CURRENT_RESIDENCE_INFO,
  STEP_WEALTH_INFO,
  STEP_CREDIT_INFO,
  STEP_LOANS_AND_CREDITS_INFO,
  STEP_GUARANTEES_INFO,
  STEP_APPLICANT_ADDITIONAL_INFO,
  STEP_INSTALLMENT_INFO,
] as const

export type ApplicantInfoStep = (typeof ApplicantInfoStep)[number]

export type ApplicantInfoPage = ApplicantInfoStep | typeof STEP_SUCCEEDED | typeof STEP_ABORTED

export enum RootStepType {
  Root = 'root',
  Entrance = 'entrance',
  Catalog = 'catalog',
  Status = 'status',
  TheEnd = 'the-end',
}

export const PhaseStep = [...TargetInfoStep, ...ApplicantInfoStep, ...LoanInfoStep] as const
export const PhaseStepSet = new Set(PhaseStep)

if (PhaseStepSet.size !== PhaseStep.length) {
  throw new Error(`Each wizard phase step name must be unique`)
}

export type PhaseStepType = (typeof PhaseStep)[number]
export type StepType = RootStepType | PhaseStepType
