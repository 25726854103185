import municipalities from './municipalities.json'

export enum CommonMunicipality {
  Espoo = '049',
  Helsinki = '091',
  Oulu = '564',
  Tampere = '837',
  Turku = '853',
  Vantaa = '092',
  Other = 'Other',
}

interface Municipality {
  id: string
  fi: string
  sv: string
  en: string
}

export const getMunicipalities = (): Municipality[] => municipalities

export const getOtherMunicipalities = (): Municipality[] => municipalities.filter((m) => !isCommonMunicipality(m.id))

export const getMunicipality = (id: string | undefined): Municipality | undefined =>
  getMunicipalities().find((m) => m.id === id)

export const isCommonMunicipality = (municipality: string): boolean => {
  return Object.values(CommonMunicipality).includes(municipality as CommonMunicipality)
}
