import {
  assertValue,
  calculateTotalRealEstateLivingExpenses,
  LoanCostEstimateInfoData,
  RealEstateLivingExpensesInfoData,
  ResidenceInfoData,
  SecLoanPurposeType,
  SecLoansApplicationMetadata,
  SecLoansConstructionLotOwnership,
  SecLoansHousingLoanTargetType,
  SecLoansLotOwnership,
  SecLoansLotType,
} from '@shared'
import { convertUndefinedToNull, formatFinnishCurrencyValue, numberToString, toNumber } from '@ui-common'
import { COMMON_PLACEHOLDER } from '@ui-components'
import { IntlShape } from 'react-intl'

import { RealEstateLivingExpensesInfoStepData, StepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { RealEstateLivingExpensesInfoFormData } from './types'

export const convertToFormValues = (
  realEstateLivingExpensesData: RealEstateLivingExpensesInfoStepData | undefined,
): RealEstateLivingExpensesInfoFormData => {
  return convertUndefinedToNull({
    monthlyHeatingExpense: numberToString(realEstateLivingExpensesData?.monthlyHeatingExpense),
    otherMonthlyExpenses: numberToString(realEstateLivingExpensesData?.otherMonthlyExpenses),
    yearlyRealEstateTax: numberToString(realEstateLivingExpensesData?.yearlyRealEstateTax),
    yearlyLotRent: numberToString(realEstateLivingExpensesData?.yearlyLotRent),
  })
}

export const convertToSubmitData = ({
  monthlyHeatingExpense,
  otherMonthlyExpenses,
  yearlyRealEstateTax,
  yearlyLotRent,
}: RealEstateLivingExpensesInfoFormData): RealEstateLivingExpensesInfoStepData => {
  return {
    monthlyHeatingExpense: assertValue(toNumber(monthlyHeatingExpense), 'monthlyHeatingExpense'),
    otherMonthlyExpenses: assertValue(toNumber(otherMonthlyExpenses), 'otherMonthlyExpenses'),
    yearlyRealEstateTax: toNumber(yearlyRealEstateTax),
    yearlyLotRent: toNumber(yearlyLotRent),
  }
}

export const resolveTotalMonthlyExpenses = (
  monthlyHeatingExpense: number | undefined,
  otherMonthlyExpenses: number | undefined,
  yearlyExpense: number | undefined,
): string => {
  return monthlyHeatingExpense !== undefined && otherMonthlyExpenses !== undefined && yearlyExpense !== undefined
    ? formatFinnishCurrencyValue(
        calculateTotalRealEstateLivingExpenses(monthlyHeatingExpense, otherMonthlyExpenses, yearlyExpense),
      )
    : COMMON_PLACEHOLDER
}

export const resolveLotType = (
  applicationMetadata: SecLoansApplicationMetadata,
  residenceInfoData: ResidenceInfoData | undefined,
  loanCostEstimateInfo: LoanCostEstimateInfoData | undefined,
): SecLoansLotType | undefined => {
  const isConstructionPurpose = applicationMetadata.applicationPurpose === SecLoanPurposeType.Construction
  return isConstructionPurpose ? loanCostEstimateInfo?.plotOwnership : residenceInfoData?.lotType
}

export const isGoingToBeOwnLot = (lotType: SecLoansLotType | undefined): boolean => {
  return (
    !!lotType &&
    [
      SecLoansLotOwnership.Own,
      SecLoansConstructionLotOwnership.Own,
      SecLoansConstructionLotOwnership.BuyLater,
    ].includes(lotType)
  )
}

export const isGoingToBeRentalLot = (lotType: SecLoansLotType | undefined): boolean => {
  return lotType
    ? [
        SecLoansLotOwnership.PublicRental,
        SecLoansLotOwnership.PrivateRental,
        SecLoansConstructionLotOwnership.Rent,
      ].includes(lotType)
    : false
}

export const getAndValidateRealEstateLivingExpensesTypeData: StepDataValidator<
  RealEstateLivingExpensesInfoData | undefined | null
> = (validationData, applicationMetadata) => {
  const metadata = assertValue(applicationMetadata, 'applicationMetadata')
  const { realEstateLivingExpensesInfoData, residenceInfoData, loanCostEstimateInfo } = assertValue(
    validationData.targetInfo,
    'validationData.targetInfo',
  )

  const isTargetTypeRealEstate =
    validationData.targetInfo?.housingLoanInfo?.targetTypeInfo?.targetType === SecLoansHousingLoanTargetType.RealEstate
  const isConstructionPurpose = metadata?.applicationPurpose === SecLoanPurposeType.Construction

  if (!isConstructionPurpose && !isTargetTypeRealEstate) {
    return null
  }

  const lotType = resolveLotType(metadata, residenceInfoData, loanCostEstimateInfo)
  const createValidator = (formatMessage: IntlShape['formatMessage']) => createFormValidator(formatMessage, lotType)
  const isValidData = isFormDataValid(convertToFormValues(realEstateLivingExpensesInfoData), createValidator)
  return isValidData ? realEstateLivingExpensesInfoData : undefined
}

export const isRealEstateLivingExpensesInfoComplete = (
  lotType: SecLoansLotType | undefined,
  realEstateLivingExpensesInfo: RealEstateLivingExpensesInfoStepData | undefined,
): boolean => {
  const sharedFields =
    realEstateLivingExpensesInfo?.monthlyHeatingExpense !== undefined &&
    realEstateLivingExpensesInfo?.otherMonthlyExpenses !== undefined
  if (isGoingToBeOwnLot(lotType)) {
    return sharedFields && realEstateLivingExpensesInfo?.yearlyRealEstateTax !== undefined
  } else if (isGoingToBeRentalLot(lotType)) {
    return sharedFields && realEstateLivingExpensesInfo?.yearlyLotRent !== undefined
  }
  return sharedFields
}
