import { Bank } from '@shared'
import { FormRadioGroup } from '@ui-components/form/FormRadioGroup'
import { buildOptionList } from '@utils/form'
import { getCommonLocalizedEnums } from '@utils/getCommonLocalizedEnums'
import { PropsWithChildren } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface BankSelectorFieldProps<T extends object> extends PropsWithChildren {
  label: string
  fieldName: keyof T
  error: string | undefined
  excludeSbank: boolean
  isRequired: boolean
}

export const BankSelector = <T extends object>(props: BankSelectorFieldProps<T>) => {
  const { fieldName, excludeSbank, children, isRequired, label, ...rest } = props

  const intl = useIntl()
  const methods = useFormContext()

  const { register } = methods

  const { bankTexts } = getCommonLocalizedEnums(intl.formatMessage)

  return (
    <FormRadioGroup
      label={label}
      required={isRequired}
      options={buildOptionList(
        Object.values(Bank).filter((e) => !excludeSbank || e !== Bank.SPankki),
        bankTexts,
      )}
      {...rest}
      {...register(fieldName as string, { shouldUnregister: true })}
    >
      {children}
    </FormRadioGroup>
  )
}

BankSelector.displayName = 'BankSelector'
