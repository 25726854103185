import {
  assertValue,
  OptionalAndNullable,
  ResidenceInfoData,
  SecLoanApplicationType,
  SecLoanPurposeType,
  SecLoansApplicationMetadata,
  SecLoansHousingLoanTargetType,
  SecLoansResidenceType,
  SecLoansValidationConstants,
  typedBoolean,
} from '@shared'
import {
  convertUndefinedToNull,
  getYesNoDefault,
  numberToString,
  resolveBooleanInputToYesNo,
  resolveFormEmptyString,
  resolveYesNoToBooleanInput,
  toNumber,
} from '@ui-common'
import { IntlShape } from 'react-intl'

import { getOptionAndOtherFormValues, resolveValueFromFormFields } from '../../../../utils/helpers'
import { HousingLoanApplicationData, ResidenceInfoStepData, StepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { ResidenceDataTypes, ResidenceInfoFormData } from './types'

export enum ResidenceInput {
  ResidenceType = 'residence type',
  LotType = 'lot ownership',
  NumberOfRooms = 'number of rooms',
  RoomLayout = 'room layout',
  NumberOfFloors = 'number of floors',
  BuildYear = 'year built',
  PipeRenovationDone = 'pipe renovation done',
  RenovationHistory = 'renovation history',
  PlannedRenovations = 'planned renovations',
  NumberOfApartments = 'number of apartments',
  SalesSite = 'sale ad origin',
  ApartmentType = 'apartment type',
  NestedLotType = 'lot ownership (nested)',
}

export interface ResidenceInputRules {
  shouldAskLotType: boolean
  shouldAskNumberOfRooms: boolean
  shouldAskRoomLayout: boolean
  shouldAskNumberOfFloors: boolean
  shouldAskBuildYear: boolean
  shouldAskPipeRenovationDetails: boolean
  shouldAskRenovationHistory: boolean
  shouldAskPlannedRenovations: boolean
  shouldAskNumberOfApartments: boolean
  shouldAskSalesSite: boolean
  shouldAskResidenceType: boolean
  shouldAskLotTypeNestedWithResidenceType: boolean
  shouldAskLivingArea: boolean
  shouldAskTotalArea: boolean
  shouldAskHeatingType: boolean
  shouldAskLotArea: boolean
}

export const getShownInformationFromApplication = (
  data: OptionalAndNullable<ResidenceInfoFormData> | undefined,
  applicationData: HousingLoanApplicationData,
  applicationMetadata: SecLoansApplicationMetadata,
  ruleResolver: (config: ResidenceInfoConfig) => ResidenceDataTypes[],
): ResidenceDataTypes[] => {
  const { targetInfo } = applicationData
  const { housingLoanInfo, residenceInfoData } = targetInfo ?? {}
  const targetType = housingLoanInfo?.targetTypeInfo?.targetType

  const applicationType: SecLoanApplicationType = applicationMetadata.applicationType
  const loanTarget = targetType

  return ruleResolver({
    loanTarget,
    buildYear: data?.buildYear ?? residenceInfoData?.buildYear,
    applicationType,
    applicationPurpose: applicationMetadata.applicationPurpose,
  })
}

export interface ResidenceInfoConfig {
  loanTarget?: SecLoansHousingLoanTargetType
  buildYear?: number
  applicationType: SecLoanApplicationType
  applicationPurpose: SecLoanPurposeType
}

const isConsideredOldBuildYear = (buildYear: number | undefined) => {
  const currentYear = new Date().getFullYear()
  return (
    !!buildYear &&
    buildYear > SecLoansValidationConstants.MINIMUM_BUILD_YEAR &&
    buildYear < currentYear - SecLoansValidationConstants.YEARS_SINCE_BUILT_TO_ASK_ABOUT_PIPES
  )
}

export const getShownInformation = (config: ResidenceInfoConfig): ResidenceDataTypes[] => {
  // Avoid having any frontend specifics here, could be used later in backend
  const shouldAskLivingArea = true
  let shouldAskTotalArea = false
  let shouldAskSalesSite = false
  let shouldAskRoomLayout = false
  let shouldAskLotType = false
  let shouldAskPipeRenovationDetails = false
  let shouldAskNumberOfApartments = false
  let shouldAskBuildYear = false
  let shouldAskNumberOfRooms = false
  let shouldAskNumberOfFloors = false
  let shouldAskRenovationHistory = false
  let shouldAskPlannedRenovations = false
  let shouldAskResidenceType = false
  let shouldAskHeatingType = false
  let shouldAskLotArea = false
  let shouldAskRealEstateId = false
  let shouldAskIsCollateralForSBankLoan = false
  let shouldAskRenovationNeeds = false

  const { buildYear, applicationType, applicationPurpose } = config

  const isBuyPurpose = applicationPurpose === SecLoanPurposeType.Buy
  const isRenovationPurpose = applicationPurpose === SecLoanPurposeType.Renovation

  if (config?.loanTarget) {
    const { loanTarget } = config
    const isTargetRealEstate = loanTarget === SecLoansHousingLoanTargetType.RealEstate
    const isTargetApartment = loanTarget === SecLoansHousingLoanTargetType.HousingStock
    const isTargetRightOfResidence = loanTarget === SecLoansHousingLoanTargetType.RightOfResidence
    const isTargetPartOwnership = loanTarget === SecLoansHousingLoanTargetType.PartOwnership
    const isTargetKnown = loanTarget !== SecLoansHousingLoanTargetType.NotKnown

    const isApartmentOrRealEstate = isTargetApartment || isTargetRealEstate
    const isConsideredOldApartment = isTargetApartment && isConsideredOldBuildYear(buildYear)
    const isSecondaryResidenceLoan = applicationType === SecLoanApplicationType.CottageLoan

    if (isTargetKnown) {
      shouldAskSalesSite = isApartmentOrRealEstate && isBuyPurpose
      shouldAskRoomLayout = isApartmentOrRealEstate || isTargetRightOfResidence || isTargetPartOwnership
      shouldAskTotalArea = isTargetRealEstate

      shouldAskLotType = isTargetRealEstate
      shouldAskPipeRenovationDetails = isConsideredOldApartment
      shouldAskNumberOfApartments = isTargetApartment
      shouldAskBuildYear = true
      shouldAskNumberOfRooms = true
      shouldAskNumberOfFloors = isApartmentOrRealEstate
      shouldAskRenovationHistory = isTargetRealEstate
      shouldAskPlannedRenovations = isConsideredOldApartment && isTargetApartment
      shouldAskHeatingType = isTargetRealEstate
      shouldAskLotArea = isTargetRealEstate
      shouldAskRealEstateId = isTargetRealEstate
    } else if (isSecondaryResidenceLoan) {
      shouldAskNumberOfRooms = true
    }

    shouldAskResidenceType = !isTargetRealEstate
  }

  // TODO: DIG-3689 - need to thoroughly check the submitted data of form, this step during renovation seems to also have loanTarget data which it should not need nor use
  if (isRenovationPurpose) {
    shouldAskIsCollateralForSBankLoan = true
    shouldAskNumberOfRooms = true
    shouldAskRoomLayout = true
    shouldAskBuildYear = true
    shouldAskResidenceType = true
    shouldAskRenovationNeeds = true
  }

  return getDataTypes(
    shouldAskLotType,
    shouldAskNumberOfRooms,
    shouldAskRoomLayout,
    shouldAskNumberOfFloors,
    shouldAskBuildYear,
    shouldAskPipeRenovationDetails,
    shouldAskRenovationHistory,
    shouldAskPlannedRenovations,
    shouldAskNumberOfApartments,
    shouldAskSalesSite,
    shouldAskResidenceType,
    shouldAskTotalArea,
    shouldAskLivingArea,
    shouldAskHeatingType,
    shouldAskLotArea,

    shouldAskRealEstateId,
    shouldAskIsCollateralForSBankLoan,
    shouldAskRenovationNeeds,
  )
}

const getDataTypes = (
  shouldAskLotType: boolean,
  shouldAskNumberOfRooms: boolean,
  shouldAskRoomLayout: boolean,
  shouldAskNumberOfFloors: boolean,
  shouldAskBuildYear: boolean,
  shouldAskPipeRenovationDetails: boolean,
  shouldAskRenovationHistory: boolean,
  shouldAskPlannedRenovations: boolean,
  shouldAskNumberOfApartments: boolean,
  shouldAskSalesSite: boolean,
  shouldAskResidenceType: boolean,
  shouldAskTotalArea: boolean,
  shouldAskLivingArea: boolean,
  shouldAskHeatingType: boolean,
  shouldAskLotArea: boolean,

  shouldAskRealEstateId: boolean,
  shouldAskIsCollateralForSBankLoan: boolean,
  shouldAskRenovationNeeds: boolean,
): ResidenceDataTypes[] => {
  return [
    shouldAskLotType && ResidenceDataTypes.LotType,
    shouldAskNumberOfRooms && ResidenceDataTypes.NumberOfRooms,
    shouldAskRoomLayout && ResidenceDataTypes.RoomLayout,
    shouldAskNumberOfFloors && ResidenceDataTypes.NumberOfFloors,
    shouldAskBuildYear && ResidenceDataTypes.BuildYear,
    shouldAskPipeRenovationDetails && ResidenceDataTypes.PipeRenovationDone,
    shouldAskRenovationHistory && ResidenceDataTypes.RenovationHistory,
    shouldAskPlannedRenovations && ResidenceDataTypes.PlannedRenovations,
    shouldAskNumberOfApartments && ResidenceDataTypes.NumberOfApartments,
    shouldAskSalesSite && ResidenceDataTypes.SalesSite,
    shouldAskResidenceType && ResidenceDataTypes.ResidenceType,
    shouldAskTotalArea && ResidenceDataTypes.TotalArea,
    shouldAskLivingArea && ResidenceDataTypes.LivingArea,
    shouldAskHeatingType && ResidenceDataTypes.HeatingType,
    shouldAskLotArea && ResidenceDataTypes.LotArea,
    shouldAskRealEstateId && ResidenceDataTypes.RealEstateId,
    shouldAskIsCollateralForSBankLoan && ResidenceDataTypes.IsCollateralForSBankLoan,
    shouldAskRenovationNeeds && ResidenceDataTypes.RenovationNeeds,
  ].filter(typedBoolean)
}

export const getResidenceTypeOptions = (
  isTargetKnown: boolean,
  applicationType: SecLoanApplicationType,
  loanTarget: SecLoansHousingLoanTargetType | undefined,
): SecLoansResidenceType[] => {
  let typeOptions: SecLoansResidenceType[] = Object.values(SecLoansResidenceType)

  if (isTargetKnown || applicationType !== SecLoanApplicationType.CottageLoan) {
    typeOptions = typeOptions.filter((type) => type !== SecLoansResidenceType.RealEstate)
  }

  if (
    applicationType === SecLoanApplicationType.CottageLoan ||
    loanTarget === SecLoansHousingLoanTargetType.HousingStock
  ) {
    typeOptions = typeOptions.filter((type) => type !== SecLoansResidenceType.TownHouse)
  }

  return typeOptions
}

export const convertToFormValues = (
  residenceInfoStepData: ResidenceInfoStepData | undefined,
): OptionalAndNullable<ResidenceInfoFormData> => {
  const numberOfFloors = getOptionAndOtherFormValues(
    residenceInfoStepData?.numberOfFloors,
    SecLoansValidationConstants.MIN_NUMBER_OF_FLOORS,
    SecLoansValidationConstants.MAX_NUMBER_OF_FLOORS_OPTION,
  )
  const numberOfRooms = getOptionAndOtherFormValues(
    residenceInfoStepData?.numberOfRooms,
    1,
    SecLoansValidationConstants.MAX_NUMBER_OF_ROOMS_OPTION,
  )
  return convertUndefinedToNull({
    ...residenceInfoStepData,
    livingSquareArea: numberToString(residenceInfoStepData?.livingSquareArea),
    totalSquareArea: numberToString(residenceInfoStepData?.totalSquareArea),
    lotSquareArea: numberToString(residenceInfoStepData?.lotSquareArea),
    numberOfFloors: numberOfFloors.option,
    numberOfFloorsOther: numberOfFloors.other,
    numberOfRooms: numberOfRooms.option,
    numberOfRoomsOther: numberOfRooms.other,
    isPipeRenovationDone: resolveBooleanInputToYesNo(residenceInfoStepData?.isPipeRenovationDone),
    numberOfApartments: numberToString(residenceInfoStepData?.numberOfApartments),
    hasSalesSite: getYesNoDefault(!!residenceInfoStepData, residenceInfoStepData?.hasSalesSite),
    buildYear: residenceInfoStepData?.buildYear,
    plannedRenovations: residenceInfoStepData?.plannedRenovations,
    roomLayoutDescription: residenceInfoStepData?.roomLayoutDescription,
    heatingType: residenceInfoStepData?.heatingType,
    residenceType: residenceInfoStepData?.residenceType,
    salesSiteTargetId: residenceInfoStepData?.salesSiteTargetId,
    salesSiteOther: residenceInfoStepData?.salesSiteOther,
    salesSite: residenceInfoStepData?.salesSite,
    isCollateralForSBankLoan: resolveBooleanInputToYesNo(residenceInfoStepData?.isCollateralForSBankLoan),
    renovationNeeds: residenceInfoStepData?.renovationNeeds,
  })
}

export const convertToResidenceInfoSubmitData = (formData: ResidenceInfoFormData): ResidenceInfoStepData => {
  return {
    residenceType: formData.residenceType,
    livingSquareArea: toNumber(formData.livingSquareArea),
    totalSquareArea: toNumber(formData.totalSquareArea),
    lotSquareArea: toNumber(formData.lotSquareArea),
    numberOfFloors: formData.numberOfFloors
      ? resolveValueFromFormFields(formData.numberOfFloors, formData.numberOfFloorsOther)
      : undefined,
    numberOfRooms: formData.numberOfRooms
      ? resolveValueFromFormFields(formData.numberOfRooms, formData.numberOfRoomsOther)
      : undefined,
    lotType: formData?.lotType,
    heatingType: formData?.heatingType,
    realEstateId: formData?.realEstateId || null,
    salesSite: formData?.salesSite,
    salesSiteTargetId: formData?.salesSiteTargetId,
    salesSiteOther: formData?.salesSiteOther,
    roomLayoutDescription: formData?.roomLayoutDescription,
    buildYear: toNumber(formData?.buildYear),
    renovationHistory: formData?.renovationHistory || null,
    plannedRenovations: formData?.plannedRenovations,
    numberOfApartments: toNumber(formData?.numberOfApartments),
    isPipeRenovationDone: resolveYesNoToBooleanInput(formData.isPipeRenovationDone),
    hasSalesSite: resolveYesNoToBooleanInput(formData?.hasSalesSite),
    isCollateralForSBankLoan: resolveYesNoToBooleanInput(formData?.isCollateralForSBankLoan),
    renovationNeeds: resolveFormEmptyString(formData?.renovationNeeds),
  }
}

export const getAndValidateResidenceInfoTypeData: StepDataValidator<ResidenceInfoData | undefined | null> = (
  validationData,
  metadata,
) => {
  const isConstructionPurpose = metadata?.applicationPurpose === SecLoanPurposeType.Construction

  if (isConstructionPurpose) {
    return null
  }

  const createValidator = (formatMessage: IntlShape['formatMessage']) =>
    createFormValidator(formatMessage, validationData, assertValue(metadata, 'metadata'))

  return isFormDataValid(convertToFormValues(validationData.targetInfo?.residenceInfoData), createValidator)
    ? validationData.targetInfo?.residenceInfoData
    : undefined
}

export const getResidenceInfoLabels = (intl: IntlShape): Record<ResidenceDataTypes, string> => {
  return {
    [ResidenceDataTypes.BuildYear]: intl.formatMessage({ id: 'sec-loans-residence-build-year-label' }),
    [ResidenceDataTypes.ResidenceType]: intl.formatMessage({ id: 'sec-loans-residence-residence-type-label' }),
    [ResidenceDataTypes.LivingArea]: intl.formatMessage({ id: 'sec-loans-residence-living-area-label' }),
    [ResidenceDataTypes.TotalArea]: intl.formatMessage({ id: 'sec-loans-residence-total-area-label' }),
    [ResidenceDataTypes.LotArea]: intl.formatMessage({ id: 'sec-loans-residence-lot-area-label' }),
    [ResidenceDataTypes.NumberOfRooms]: intl.formatMessage({ id: 'sec-loans-residence-number-of-rooms-label' }),
    [ResidenceDataTypes.RoomLayout]: intl.formatMessage({ id: 'sec-loans-residence-room-layout-label' }),
    [ResidenceDataTypes.NumberOfFloors]: intl.formatMessage({ id: 'sec-loans-residence-number-of-floors-label' }),
    [ResidenceDataTypes.PipeRenovationDone]: intl.formatMessage({
      id: 'sec-loans-residence-is-pipe-renovation-done-label',
    }),
    [ResidenceDataTypes.RenovationHistory]: intl.formatMessage({ id: 'sec-loans-residence-renovation-history-label' }),
    [ResidenceDataTypes.HeatingType]: intl.formatMessage({ id: 'sec-loans-residence-heating-type-label' }),
    [ResidenceDataTypes.PlannedRenovations]: intl.formatMessage({
      id: 'sec-loans-residence-planned-renovations-label',
    }),
    [ResidenceDataTypes.NumberOfApartments]: intl.formatMessage({
      id: 'sec-loans-residence-number-of-apartments-in-company-label',
    }),
    [ResidenceDataTypes.RealEstateId]: intl.formatMessage({ id: 'sec-loans-residence-real-estate-id-label' }),
    [ResidenceDataTypes.LotType]: intl.formatMessage({ id: 'sec-loans-lot-ownership-label' }),
    [ResidenceDataTypes.SalesSite]: intl.formatMessage({ id: 'sec-loans-housing-sales-site-label' }),
    [ResidenceDataTypes.SalesSiteId]: intl.formatMessage({ id: 'sec-loans-housing-sales-site-target-id-label' }),
    [ResidenceDataTypes.SalesSiteOther]: intl.formatMessage({ id: 'common-other-label' }),
    [ResidenceDataTypes.HasSalesSite]: intl.formatMessage({ id: 'sec-loans-housing-has-sales-site' }),
    [ResidenceDataTypes.IsCollateralForSBankLoan]: intl.formatMessage({
      id: 'sec-loans-residence-already-s-bank-collateral',
    }),
    [ResidenceDataTypes.RenovationNeeds]: intl.formatMessage({
      id: 'sec-loans-residence-renovation-needs',
    }),
  }
}
