import { assertValue, SecLoansHousingLoanTargetType } from '@shared'
import { formatNumber, testIsValidMonetaryValue, YesNo } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, omitWhen, only, Suite, test } from 'vest'

import { WizardData } from '../../../types'
import { PriceInfoFormData as FormData } from './PriceInfoStep'
import { hasCompanyLoanShare } from './priceInfoStepUtils'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
  applicationData: WizardData,
): Suite<string, string, (data: FormData, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    testIsValidMonetaryValue('price', data.price, formatMessage)

    omitWhen(
      applicationData.targetInfo?.housingLoanInfo?.targetTypeInfo?.targetType !==
        SecLoansHousingLoanTargetType.HousingStock,
      () => {
        testIsValidMonetaryValue('netSellingPrice', data.netSellingPrice, formatMessage)

        omitWhen(!data.price, () => {
          test(
            'price',
            formatMessage(
              { id: 'sec-loans-validate-value-is-not-more-than-other-value' },
              {
                firstValue: formatMessage({
                  id: 'sec-loans-target-price-label',
                }),
                secondValue: formatMessage({ id: 'sec-loans-net-selling-price-label' }),
              },
            ),
            () => {
              const netSellingPrice = assertValue(data.netSellingPrice, 'data.netSellingPrice')
              const price = assertValue(data.price, 'data.price')
              enforce(formatNumber(netSellingPrice) >= formatNumber(price)).isTruthy()
            },
          )
        })

        omitWhen(
          !!data.netSellingPrice &&
            !!data.price &&
            !hasCompanyLoanShare(formatNumber(data.netSellingPrice), formatNumber(data.price)),
          () => {
            test('isPayingCompanyLoanShare', formatMessage({ id: 'validate-required-field-missing' }), () => {
              enforce(data.isPayingCompanyLoanShare).inside(Object.values(YesNo))
            })
          },
        )
      },
    )
  })
}
