import { trackPageView } from '@snowplow/browser-tracker'
import { TechnicalErrorStep } from '@ui-components/layout/TechnicalErrorStep'
import { Component, ReactNode } from 'react'
import { createGlobalStyle, styled } from 'styled-components'

import { sendErrorEventToAnalytics } from './analytics'

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
  }
`

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export class AppErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  }

  static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    console.error('Uncaught error:', error, errorInfo)

    trackPageView({ title: 'AppErrorBoundary' })
    sendErrorEventToAnalytics(`App error: ${error.message}`, error)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <>
          <GlobalStyle />
          <TechnicalErrorStep
            heading={
              <Centered>
                <span>Odottamaton tekninen virhe</span>
                <span>Oförutsett tekniskt fel</span>
              </Centered>
            }
            content={
              <Centered>
                <p>Yritä myöhemmin uudelleen tai ota yhteyttä asiakaspalveluun.</p>
                <p>Försök senare på nytt eller kontakta kundtjänsten.</p>
              </Centered>
            }
            buttonLabel="Lataa uudelleen / Ladda om"
            onButtonClick={() => {
              if (sessionStorage) {
                sessionStorage.clear()
              }
              location.reload()
            }}
          />
        </>
      )
    }
    return this.props.children
  }
}
