import { enableErrorTracking, ErrorTrackingPlugin, trackError } from '@snowplow/browser-plugin-error-tracking'
import { newTracker, trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker'

export interface SnowPlowData {
  [key: string]: string
}

export const NotAvailable = 'N/A'
type NotAvailable = typeof NotAvailable

// AnalyticsFields is just a 'string' type but this allows 'N/A' to be autocompleted
// eslint-disable-next-line @typescript-eslint/ban-types
type AnalyticsField = (string & {}) | NotAvailable

export interface AnalyticsData extends SnowPlowData {
  src: AnalyticsField
  flow: AnalyticsField
  product: AnalyticsField
  version: AnalyticsField
  ui_lang: AnalyticsField
}

export type CommonAnalyticsPageViewEvent = 'ModalQuit' | 'ModalQuitEdit'
export type CommonAnalyticsCustomEventName =
  | 'CancelButton'
  | 'CloseModal'
  | 'ExitButton'
  | 'ConfirmButton'
  | 'ContinueButton'
  | 'BackButton'
  | 'SaveButton'

enum SnowplowSchema {
  Common = 'iglu:fi.sok/hakemukset_common/jsonschema/1-0-0',
  CustomAction = 'iglu:fi.sok/hakemukset_custom_action/jsonschema/1-0-0',
}

export const ANALYTICS_PRODUCT_NOT_APPLICABLE = 'NotApplicable'

export abstract class AnalyticsService<T_STEPTYPE, T_CUSTOMEVENTNAME> {
  public abstract sendPageViewEvent(step: T_STEPTYPE): void

  public abstract sendCustomEvent(step: T_STEPTYPE, name: T_CUSTOMEVENTNAME): void

  public abstract sendCustomDataEvent(data: { [key: string]: string }): void
}

export function initializeSnowplow(
  appId: string = import.meta.env.VITE_SNOWPLOW_APP_ID,
  collectorEndpoint: string = import.meta.env.VITE_SNOWPLOW_COLLECTOR_ENDPOINT,
): void {
  if (appId && collectorEndpoint) {
    newTracker('sp1', collectorEndpoint, {
      appId,
      eventMethod: 'post',
      anonymousTracking: { withServerAnonymisation: true, withSessionTracking: false },
      stateStorageStrategy: 'none',
      contexts: { webPage: true },
      plugins: [ErrorTrackingPlugin()],
    })
    enableErrorTracking()
  }
}

export const sendPageViewEventToAnalytics = (name: string, data: AnalyticsData): void => {
  trackPageView({
    title: name,
    context: [
      {
        schema: SnowplowSchema.Common,
        data,
      },
    ],
  })
}

export const sendErrorEventToAnalytics = (message: string, error?: Error): void => {
  trackError({ message, error })
}

export const sendCustomEventToAnalytics = (category: string, name: string, data: SnowPlowData): void => {
  trackSelfDescribingEvent({
    event: {
      schema: SnowplowSchema.CustomAction,
      data: {
        action_category: category,
        action_name: name,
      },
    },
    context: [
      {
        schema: SnowplowSchema.Common,
        data,
      },
    ],
  })
}

export const sendCustomDataEventToAnalytics = (data: SnowPlowData): void => {
  trackSelfDescribingEvent({
    event: {
      schema: SnowplowSchema.CustomAction,
      data,
    },
  })
}

export interface AnalyticsContextValue<T_SERVICE extends AnalyticsService<string, string>> {
  analyticsService: T_SERVICE
}
