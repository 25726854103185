import {
  assertValue,
  CollateralInfoData,
  isCollateralRequired,
  OptionalAndNullable,
  SecLoanApplicationType,
  SecLoansCollateralType,
} from '@shared'
import { buildOptionList, convertUndefinedToNull, getYesNoDefault, resolveFormEmptyString } from '@ui-common'
import { FormGroupOption } from '@ui-components'
import { IntlShape } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

import { getSecLoansLocalizedEnums } from '../../../../utils/getSecLoansLocalizedEnums'
import { CollateralInfoStepData, StepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { CollateralInfoFormData } from './types'

export const convertToFormValues = (
  collateralData: CollateralInfoStepData | undefined,
): OptionalAndNullable<CollateralInfoFormData> => {
  return convertUndefinedToNull({
    hasCollaterals: getYesNoDefault(!!collateralData, collateralData?.collaterals?.length),
    collaterals:
      collateralData?.collaterals?.map((c) => ({
        ...c,
        id: uuidv4(),
      })) || [],
  })
}

export const convertToSubmitData = (formData: CollateralInfoFormData): CollateralInfoStepData => {
  const collaterals = formData.collaterals
    ? formData.collaterals.map((collateral) => {
        return {
          ...collateral,
          additionalInfo: resolveFormEmptyString(collateral.additionalInfo),
          id: undefined,
        }
      })
    : []
  return {
    collaterals,
  }
}

export const getAndValidateCollateralInfoTypeData: StepDataValidator<CollateralInfoData | undefined | null> = (
  validationData,
  applicationMetadata,
) => {
  const collateralInfoData = validationData.loanInfo?.collateralInfoData
  const { applicationType, applicationPurpose } = assertValue(applicationMetadata, 'applicationMetadata')
  const isCollateralInfoRequired = isCollateralRequired(applicationType, applicationPurpose, validationData)
  if (!isCollateralInfoRequired) {
    return null
  }
  const isCollateralInfoComplete = isCollateralComplete(collateralInfoData)
  const isStepDataValid = isFormDataValid(convertToFormValues(collateralInfoData), createFormValidator)
  return isStepDataValid && isCollateralInfoComplete ? collateralInfoData : undefined
}

export const isCollateralComplete = (collateralInfo: CollateralInfoStepData | undefined): boolean => {
  return !!collateralInfo?.collaterals
}

export const getCollateralOptions = (applicationType: SecLoanApplicationType, intl: IntlShape): FormGroupOption[] => {
  const { collateralTypeTexts } = getSecLoansLocalizedEnums(intl.formatMessage)

  const isHousingLoan = applicationType === SecLoanApplicationType.HousingLoan
  return buildOptionList(
    Object.values(SecLoansCollateralType).filter(
      (collateralType) => isHousingLoan || collateralType !== SecLoansCollateralType.StateGuarantee,
    ),
    collateralTypeTexts,
  )
}
