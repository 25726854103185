import { InsuranceInfoData, OptionalAndNullable } from '@shared'
import { convertUndefinedToNull, getYesNoDefault } from '@ui-common'

import { StepDataValidator, WizardData } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { InsuranceInfoFormData } from './types'

export const convertToFormValues = (
  insuranceInfoData: InsuranceInfoData | undefined,
): OptionalAndNullable<InsuranceInfoFormData> => {
  return convertUndefinedToNull({
    isInterestedInInsurance: getYesNoDefault(!!insuranceInfoData, insuranceInfoData?.isInterestedInInsurance),
  })
}

export const getAndValidateInsuranceInfoTypeData: StepDataValidator<InsuranceInfoData | undefined> = (
  validationData: WizardData,
) => {
  const insuranceInfoData = validationData.loanInfo?.insuranceInfoData
  return isFormDataValid(convertToFormValues(insuranceInfoData), createFormValidator) ? insuranceInfoData : undefined
}
