import { YesNo } from '@ui-common'

import {
  BaseWizardContext,
  CreditInfoStepBase,
  CreditInfoStepData,
  CreditInfoStepDataType,
  HierarchicalApplicationData,
} from '../../../types'

export const MAX_NUMBER_OF_CREDIT_CARDS_OPTION = 3

export type { CreditInfoStepData, CreditInfoStepDataType } from '../../../types'

export interface CreditInfoStepContext extends CreditInfoStepDataType, BaseWizardContext {}

export interface SecondApplicantFormData extends CreditInfoStepData {
  hasCreditCards?: YesNo
}

export type IncomeFormDataBase = Omit<CreditInfoStepBase, 'creditLimit' | 'numberOfCreditCards'> & {
  numberOfCreditCards?: string
  numberOfCreditCardsOther?: string
  creditLimit?: string
  hasCreditCards?: YesNo
}

export interface CreditInfoStepFormData extends IncomeFormDataBase, HierarchicalApplicationData {
  secondApplicant?: IncomeFormDataBase
}
