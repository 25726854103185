import { convertEmptyStringsInSubmitData, trimSubmitData } from '@ui-common/utils'
import { FormDevTool } from '@ui-common/utils/FormDevTool'
import { ReactNode } from 'react'
import { DefaultValues, FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form'
import * as R from 'remeda'

import { useScrollToFormError } from './useScrollToFormError'

export interface UseWizardFormReturn<TFieldValues extends FieldValues> extends UseFormReturn<TFieldValues> {
  FormDevTool: () => ReactNode
  triggerScrollToError: () => void
}

export interface UseWizardFormProps<TFieldValues extends FieldValues = FieldValues> extends UseFormProps<TFieldValues> {
  shouldConvertEmptyStringsToUndefined?: boolean
}

export const useWizardFormShouldUnregisterAsFalse = <TFieldValues extends FieldValues = FieldValues>({
  resolver,
  defaultValues = {} as DefaultValues<TFieldValues>,
}: UseWizardFormProps<TFieldValues> = {}): UseWizardFormReturn<TFieldValues> => {
  return useWizardForm({ resolver, defaultValues, shouldUnregister: false })
}

export const useWizardForm = <TFieldValues extends FieldValues = FieldValues>({
  resolver,
  defaultValues = {} as DefaultValues<TFieldValues>,
  shouldUnregister = true,
  mode = 'onTouched',
  shouldConvertEmptyStringsToUndefined = true,
}: UseWizardFormProps<TFieldValues> = {}): UseWizardFormReturn<TFieldValues> => {
  const { handleSubmit, formState, ...rest } = useForm<TFieldValues>({
    mode,
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: false,
    resolver,
    defaultValues,
    shouldUnregister,
  })

  const { triggerScroll } = useScrollToFormError(formState.errors)

  return {
    handleSubmit: shouldConvertEmptyStringsToUndefined
      ? R.piped(trimSubmitData, convertEmptyStringsInSubmitData, handleSubmit)
      : R.piped(trimSubmitData, handleSubmit),
    formState,
    triggerScrollToError: triggerScroll,
    FormDevTool,
    ...rest,
  }
}
