import { APPLICANT_INFO_WIZARD_MACHINE_ID } from '@sec-loans-ui/wizard/steps/applicant-info-phase/applicantInfoWizardMachine'
import { LOAN_INFO_WIZARD_MACHINE_ID } from '@sec-loans-ui/wizard/steps/loan-info-phase/loanInfoWizardMachine'
import { TARGET_INFO_WIZARD_MACHINE_ID } from '@sec-loans-ui/wizard/steps/loan-target-phase/targetInfoWizardMachine'
import {
  BestTimeToReach,
  ConstructionMaterial,
  Language,
  LocalizedString,
  Occupation,
  OtherOccupation,
  SchoolDegree,
  SecLoanApplicationState,
  SecLoanApplicationType,
  SecLoanCarOwnershipType,
  SecLoanHomeOwnershipHousingType,
  SecLoanHousingType,
  SecLoanOccupation,
  SecLoanOtherIncomeType,
  SecLoanPreviousEmploymentTypeOptions,
  SecLoanProbationOptions,
  SecLoanPurposeType,
  SecLoansAbortModalCase,
  SecLoansApplicants,
  SecLoansCollateralType,
  SecLoansCompanyForm,
  SecLoansConstructionLotOwnership,
  SecLoansCurrentResidenceSellingPlan,
  SecLoansEmploymentType,
  SecLoansExpenseShareForNewFlat,
  SecLoansGuaranteeLiability,
  SecLoansHeatingType,
  SecLoansHousingLoanTargetType,
  SecLoansHousingSalesSite,
  SecLoansIndustrySector,
  SecLoansInvestmentType,
  SecLoansLoanOrCreditType,
  SecLoansLotOwnership,
  SecLoansMethodOfRepayment,
  SecLoansPropertyType,
  SecLoansPurchaseLoanPurpose,
  SecLoansReferenceRate,
  SecLoansResidenceType,
  SecLoanWorkingHoursOptions,
  SecLoanWorkingPositionOptions,
} from '@shared'
import { YesNo } from '@ui-common'
import { CompletionProgress, ComplexMessage, PhaseInstructions } from '@ui-components'
import { ReactElement } from 'react'
import { IntlShape } from 'react-intl'

import { SecLoansAloneOrTogether } from '../wizard/steps/applicant-info-phase/number-of-applicants-info-step/types'
import { SecondaryApplicantChoice } from '../wizard/types'
import { PhaseType } from '../wizard/wizardSteps'

export const getSecLoansLocalizedEnums = (
  formatMessage: IntlShape['formatMessage'],
): {
  applicationTypeLabelTexts: Record<SecLoanApplicationType, string>
  loanPurposeTypeLabelTexts: Record<SecLoanPurposeType, string>
  expenseInfoCarOwnershipLabelTexts: Record<SecLoanCarOwnershipType, string>
  incomeInfoOtherIncomeLabelText: Record<SecLoanOtherIncomeType, string>
  housingInfoHousingTypeLabelText: Record<SecLoanHousingType, string>
  housingLoanTargetTypeDescriptionTexts: Record<SecLoansHousingLoanTargetType, string>
  cottageLoanTargetTypeDescriptionTexts: Record<SecLoansHousingLoanTargetType, string>
  housingInfoHomeOwnershipTypeLabelText: Record<SecLoanHomeOwnershipHousingType, string>
  aloneOrTogetherLabelTexts: Record<SecLoansAloneOrTogether, string>
  employmentInfoWorkingHoursLabelText: Record<SecLoanWorkingHoursOptions, string>
  employmentInfoWorkingPositionLabelText: Record<SecLoanWorkingPositionOptions, string>
  employmentInfoProbationLabelText: Record<SecLoanProbationOptions, string>
  employmentInfoPreviousEmploymentTypeLabelText: Record<SecLoanPreviousEmploymentTypeOptions, string>
  housingLoanTargetTypeTexts: Record<SecLoansHousingLoanTargetType, string>
  residenceInfoResidenceTypeTexts: Record<SecLoansResidenceType, string>
  currentResidenceSellingPlanTexts: Record<SecLoansCurrentResidenceSellingPlan, string>
  expenseShareForNewFlatTexts: Record<SecLoansExpenseShareForNewFlat, string>
  methodOfRepaymentLabelTexts: Record<SecLoansMethodOfRepayment, string>
  methodOfRepaymentDescriptionTexts: Record<SecLoansMethodOfRepayment, string>
  investmentTypeTexts: Record<SecLoansInvestmentType, string>
  propertyTypeTexts: Record<SecLoansPropertyType, string>
  ownershipTexts: Record<SecLoansApplicants, string>
  loanCreditTypeTexts: Record<SecLoansLoanOrCreditType, string>
  secLoansEmploymentTypeTexts: Record<SecLoansEmploymentType, string>
  secLoansCompanyFormTexts: Record<SecLoansCompanyForm, string>
  secLoansIndustrySectorTexts: Record<SecLoansIndustrySector, string>
  highestDegree: Record<SchoolDegree, string>
  occupationTexts: Record<SecLoanOccupation, string>
  applicantsTexts: Record<SecLoansApplicants, string>
  liabilityTexts: Record<SecLoansGuaranteeLiability, string>
  applicationStateTexts: Record<SecLoanApplicationState, string>
  referenceRateTexts: Record<SecLoansReferenceRate, string>
  collateralTypeTexts: Record<SecLoansCollateralType, string>
  hasCollateralsTexts: Record<YesNo, string>
  collateralsAdditionalInfoTexts: Record<SecLoansCollateralType, string>
  lotOwnerShipTexts: Record<SecLoansLotOwnership, string>
  salesSiteTexts: Record<SecLoansHousingSalesSite, string>
  heatingTypeTexts: Record<SecLoansHeatingType, string>
  serviceLanguageTexts: LocalizedString
  phaseFinalizationTexts: Record<string, ComplexMessage>
  phaseDescriptionTexts: Record<PhaseType, string>
  phaseHeaderTexts: Record<PhaseType, string>
  priceTexts: Record<SecLoansHousingLoanTargetType, string>
  priceDescriptionTexts: Record<SecLoansHousingLoanTargetType, string>
  purchaseLoanPurposeTexts: Record<SecLoansPurchaseLoanPurpose, string>
  abortModalHeaderTexts: Record<SecLoansAbortModalCase, string>
  abortModalDescriptionTexts: Record<SecLoansAbortModalCase, string>
  residenceTypeDescriptionTexts: Record<SecLoansHousingLoanTargetType, string>
  secondaryApplicantChoiceTexts: Record<SecondaryApplicantChoice, string>
  constructionLotOwnershipTexts: Record<SecLoansConstructionLotOwnership, string>
  constructionMaterialTexts: Record<ConstructionMaterial, string>
  phaseInstructionTexts: PhaseInstructions
  bestTimeToReachTexts: Record<BestTimeToReach, string>
} => {
  const abortModalHeaderTexts: Record<SecLoansAbortModalCase, string> = {
    [SecLoansAbortModalCase.LeaveAndContinueLater]: formatMessage({ id: 'sec-loans-abort-prompt-header' }),
    [SecLoansAbortModalCase.TiredOfWaiting]: formatMessage({ id: 'sec-loans-abort-tired-of-waiting-prompt-header' }),
  }

  const abortModalDescriptionTexts: Record<SecLoansAbortModalCase, string> = {
    [SecLoansAbortModalCase.LeaveAndContinueLater]: formatMessage({ id: 'sec-loans-abort-prompt-message' }),
    [SecLoansAbortModalCase.TiredOfWaiting]: formatMessage({ id: 'sec-loans-abort-tired-of-waiting-prompt-message' }),
  }

  const applicationTypeLabelTexts: Record<SecLoanApplicationType, string> = {
    [SecLoanApplicationType.HousingLoan]: formatMessage({ id: 'sec-loans-loan-type-title-mortgage-loan' }),
    [SecLoanApplicationType.CottageLoan]: formatMessage({ id: 'sec-loans-loan-type-title-cottage-loan' }),
    [SecLoanApplicationType.PropertyInvestment]: formatMessage({ id: 'sec-loans-loan-type-title-investment-loan' }),
    [SecLoanApplicationType.PurchaseLoan]: formatMessage({ id: 'sec-loans-loan-type-title-purchase-loan' }),
  }

  const loanPurposeTypeLabelTexts: Record<SecLoanPurposeType, string> = {
    [SecLoanPurposeType.Buy]: formatMessage({ id: 'sec-loans-loan-purpose-buy' }),
    [SecLoanPurposeType.Construction]: formatMessage({ id: 'sec-loans-loan-purpose-construction' }),
    [SecLoanPurposeType.Renovation]: formatMessage({ id: 'sec-loans-loan-purpose-renovation' }),
    [SecLoanPurposeType.LoanTransferToSbank]: formatMessage({ id: 'sec-loans-loan-purpose-loan-transfer-to-sbank' }),
    [SecLoanPurposeType.Other]: formatMessage({ id: 'sec-loans-loan-purpose-other' }),
  }

  const expenseInfoCarOwnershipLabelTexts: Record<SecLoanCarOwnershipType, string> = {
    [SecLoanCarOwnershipType.OwnCar]: formatMessage({
      id: 'sec-loans-expenses-info-car-ownership-form-option-own-car',
    }),
    [SecLoanCarOwnershipType.Leasing]: formatMessage({
      id: 'sec-loans-expenses-info-car-ownership-form-option-leasing',
    }),

    [SecLoanCarOwnershipType.CompanyCarBenefitInKind]: formatMessage({
      id: 'sec-loans-expenses-info-car-ownership-form-option-benefit-in-kind',
    }),

    [SecLoanCarOwnershipType.CompanyCarFringeBenefit]: formatMessage({
      id: 'sec-loans-expenses-info-car-ownership-form-option-fringe-benefit',
    }),

    [SecLoanCarOwnershipType.OwnMultipleCars]: formatMessage({
      id: 'sec-loans-expenses-info-car-ownership-form-option-multiple-car',
    }),
  }

  const incomeInfoOtherIncomeLabelText: Record<SecLoanOtherIncomeType, string> = {
    [SecLoanOtherIncomeType.ChildAllowance]: formatMessage({
      id: 'sec-loans-income-info-other-income-form-option-child-allowance',
    }),

    [SecLoanOtherIncomeType.RentalIncome]: formatMessage({
      id: 'sec-loans-income-info-other-income-form-option-rental-income',
    }),

    [SecLoanOtherIncomeType.RegularExpenseReimbursements]: formatMessage({
      id: 'sec-loans-income-info-other-income-form-option-regular-expense-reimbursements',
    }),

    [SecLoanOtherIncomeType.DividendIncome]: formatMessage({
      id: 'sec-loans-income-info-other-income-form-option-dividend-income',
    }),

    [SecLoanOtherIncomeType.SideJob]: formatMessage({ id: 'sec-loans-income-info-other-income-form-option-side-job' }),
    [SecLoanOtherIncomeType.HousingAllowance]: formatMessage({
      id: 'sec-loans-income-info-other-income-form-option-housing-allowance',
    }),

    [SecLoanOtherIncomeType.Grant]: formatMessage({ id: 'sec-loans-income-info-other-income-form-option-grant' }),
    [SecLoanOtherIncomeType.ChildSupport]: formatMessage({
      id: 'sec-loans-income-info-other-income-form-option-child-support',
    }),

    [SecLoanOtherIncomeType.Pension]: formatMessage({ id: 'sec-loans-income-info-other-income-form-option-pension' }),
    [SecLoanOtherIncomeType.Other]: formatMessage({ id: 'common-other' }),
  }

  const housingLoanTargetTypeDescriptionTexts: Record<SecLoansHousingLoanTargetType, string> = {
    [SecLoansHousingLoanTargetType.HousingStock]: formatMessage({
      id: 'sec-loans-target-info-target-type-housing-loan-housing-stock-description',
    }),
    [SecLoansHousingLoanTargetType.RealEstate]: formatMessage({
      id: 'sec-loans-target-info-target-type-housing-loan-real-estate-description',
    }),
    [SecLoansHousingLoanTargetType.NotKnown]: '',
    [SecLoansHousingLoanTargetType.RightOfResidence]: '',
    [SecLoansHousingLoanTargetType.PartOwnership]: '',
  }

  const cottageLoanTargetTypeDescriptionTexts: Record<SecLoansHousingLoanTargetType, string> = {
    [SecLoansHousingLoanTargetType.HousingStock]: '',
    [SecLoansHousingLoanTargetType.RealEstate]: formatMessage({
      id: 'sec-loans-target-info-target-type-cottage-loan-real-estate-description',
    }),
    [SecLoansHousingLoanTargetType.NotKnown]: '',
    [SecLoansHousingLoanTargetType.RightOfResidence]: '',
    [SecLoansHousingLoanTargetType.PartOwnership]: '',
  }

  const housingInfoHousingTypeLabelText: Record<SecLoanHousingType, string> = {
    [SecLoanHousingType.HomeOwnership]: formatMessage({
      id: 'sec-loans-housing-info-housing-type-form-option-home-ownership',
    }),

    [SecLoanHousingType.RentalHome]: formatMessage({
      id: 'sec-loans-housing-info-housing-type-form-option-rental-home',
    }),

    [SecLoanHousingType.RightOfResidence]: formatMessage({
      id: 'sec-loans-housing-info-housing-type-form-option-right-of-resident',
    }),

    [SecLoanHousingType.SharedOwnership]: formatMessage({
      id: 'sec-loans-housing-info-housing-type-form-option-shared-ownership',
    }),

    [SecLoanHousingType.EmployerProvidedHousing]: formatMessage({
      id: 'sec-loans-housing-info-housing-type-form-option-employer-provided-housing',
    }),

    [SecLoanHousingType.ParentsHome]: formatMessage({
      id: 'sec-loans-housing-info-housing-type-form-option-parents-home',
    }),

    [SecLoanHousingType.Other]: formatMessage({ id: 'common-other' }),
  }

  const housingInfoHomeOwnershipTypeLabelText: Record<SecLoanHomeOwnershipHousingType, string> = {
    [SecLoanHomeOwnershipHousingType.HousingShareCooperative]: formatMessage({
      id: 'sec-loans-housing-info-home-ownership-type-form-option-housing-share-cooperative',
    }),

    [SecLoanHomeOwnershipHousingType.DetachedHouseOnOwnLand]: formatMessage({
      id: 'sec-loans-housing-info-home-ownership-type-form-option-detached-house-on-own-land',
    }),

    [SecLoanHomeOwnershipHousingType.DetachedHouseOnLeasedLand]: formatMessage({
      id: 'sec-loans-housing-info-home-ownership-type-form-option-detached-house-on-leased-land',
    }),

    [SecLoanHomeOwnershipHousingType.Other]: formatMessage({ id: 'common-other' }),
  }

  const aloneOrTogetherLabelTexts: Record<SecLoansAloneOrTogether, string> = {
    [SecLoansAloneOrTogether.Alone]: formatMessage({ id: 'sec-loans-number-of-applicants-alone-or-together-alone' }),
    [SecLoansAloneOrTogether.Together]: formatMessage({
      id: 'sec-loans-number-of-applicants-alone-or-together-together',
    }),
  }

  const employmentInfoWorkingHoursLabelText: Record<SecLoanWorkingHoursOptions, string> = {
    [SecLoanWorkingHoursOptions.FullTime]: formatMessage({
      id: 'sec-loans-employment-info-working-hours-form-option-full-time',
    }),

    [SecLoanWorkingHoursOptions.PartTime]: formatMessage({
      id: 'sec-loans-employment-info-working-hours-form-option-part-time',
    }),
  }

  const employmentInfoWorkingPositionLabelText: Record<SecLoanWorkingPositionOptions, string> = {
    [SecLoanWorkingPositionOptions.Employee]: formatMessage({ id: 'employment-info-employee-type-employed' }),
    [SecLoanWorkingPositionOptions.OfficeWorker]: formatMessage({ id: 'employment-info-employee-type-official' }),
    [SecLoanWorkingPositionOptions.ManagerOrSpecialist]: formatMessage({
      id: 'sec-loans-employment-info-working-position-form-option-manager-or-specialist',
    }),

    [SecLoanWorkingPositionOptions.LeadershipPosition]: formatMessage({
      id: 'sec-loans-employment-info-working-position-form-option-leadership-position',
    }),

    [SecLoanWorkingPositionOptions.Other]: formatMessage({ id: 'common-other' }),
  }

  const employmentInfoProbationLabelText: Record<SecLoanProbationOptions, string> = {
    [SecLoanProbationOptions.Ended]: formatMessage({ id: 'sec-loans-employment-info-probation-form-option-ended' }),
    [SecLoanProbationOptions.Ongoing]: formatMessage({ id: 'sec-loans-employment-info-probation-form-option-ongoing' }),
    [SecLoanProbationOptions.NoProbation]: formatMessage({
      id: 'sec-loans-employment-info-probation-form-option-no-probation',
    }),
  }

  const employmentInfoPreviousEmploymentTypeLabelText: Record<SecLoanPreviousEmploymentTypeOptions, string> = {
    [SecLoanPreviousEmploymentTypeOptions.Permanent]: formatMessage({
      id: 'employment-info-employment-type-permanent',
    }),

    [SecLoanPreviousEmploymentTypeOptions.FixedTerm]: formatMessage({
      id: 'employment-info-employment-type-time-limited',
    }),

    [SecLoanPreviousEmploymentTypeOptions.Temporary]: formatMessage({
      id: 'sec-loans-employment-info-previous-employment-type-form-option-no-temporary',
    }),

    [SecLoanPreviousEmploymentTypeOptions.EntrepreneurOrFreelancer]: formatMessage({
      id: 'personal-info-occupation-entrepreneur',
    }),

    [SecLoanPreviousEmploymentTypeOptions.NoPreviousEmployment]: formatMessage({
      id: 'sec-loans-employment-info-previous-employment-type-form-option-no-previous-employment',
    }),
  }

  const housingLoanTargetTypeTexts: Record<SecLoansHousingLoanTargetType, string> = {
    [SecLoansHousingLoanTargetType.NotKnown]: formatMessage({
      id: 'sec-loans-target-info-target-type-target-not-known',
    }),

    [SecLoansHousingLoanTargetType.HousingStock]: formatMessage({ id: 'sec-loans-target-info-target-type-apartment' }),
    [SecLoansHousingLoanTargetType.RealEstate]: formatMessage({ id: 'sec-loans-target-info-target-type-real-estate' }),
    [SecLoansHousingLoanTargetType.PartOwnership]: formatMessage({
      id: 'sec-loans-target-info-target-type-part-ownership',
    }),

    [SecLoansHousingLoanTargetType.RightOfResidence]: formatMessage({
      id: 'sec-loans-target-info-target-type-right-of-residence',
    }),
  }

  const residenceInfoResidenceTypeTexts: Record<SecLoansResidenceType, string> = {
    [SecLoansResidenceType.BlockOfFlats]: formatMessage({ id: 'sec-loans-residence-type-bloc-of-flats' }),
    [SecLoansResidenceType.TerracedHouse]: formatMessage({ id: 'sec-loans-residence-type-terraced-house' }),
    [SecLoansResidenceType.TownHouse]: formatMessage({ id: 'sec-loans-residence-type-town-house' }),
    [SecLoansResidenceType.SemiDetachedHouse]: formatMessage({ id: 'sec-loans-residence-type-semi-detached-house' }),
    [SecLoansResidenceType.DetachedHouse]: formatMessage({ id: 'sec-loans-residence-type-detached-house' }),
    [SecLoansResidenceType.RealEstate]: formatMessage({ id: 'sec-loans-residence-type-real-estate' }),
    [SecLoansResidenceType.Other]: formatMessage({ id: 'common-other' }),
  }

  const currentResidenceSellingPlanTexts: Record<SecLoansCurrentResidenceSellingPlan, string> = {
    [SecLoansCurrentResidenceSellingPlan.SellingFirst]: formatMessage({
      id: 'sec-loans-current-residence-selling-plan-selling-first',
    }),

    [SecLoansCurrentResidenceSellingPlan.SellingAfter]: formatMessage({
      id: 'sec-loans-current-residence-selling-plan-selling-after',
    }),

    [SecLoansCurrentResidenceSellingPlan.NotForSale]: formatMessage({
      id: 'sec-loans-current-residence-selling-plan-not-for-sale',
    }),
  }

  const expenseShareForNewFlatTexts: Record<SecLoansExpenseShareForNewFlat, string> = {
    [SecLoansExpenseShareForNewFlat.All]: formatMessage({ id: 'sec-loans-current-residence-expense-for-new-flat-all' }),
    [SecLoansExpenseShareForNewFlat.OtherAmount]: formatMessage({
      id: 'sec-loans-current-residence-expense-for-new-flat-other-amount',
    }),
  }

  const methodOfRepaymentLabelTexts: Record<SecLoansMethodOfRepayment, string> = {
    [SecLoansMethodOfRepayment.Annuity]: formatMessage({
      id: 'sec-loans-payment-plan-method-of-repayment-annuity-label',
    }),

    [SecLoansMethodOfRepayment.ConstantAmount]: formatMessage({
      id: 'sec-loans-payment-plan-method-of-repayment-equal-payment-label',
    }),

    [SecLoansMethodOfRepayment.ConstantDuration]: formatMessage({
      id: 'sec-loans-payment-plan-method-of-repayment-constant-duration-label',
    }),
  }

  const methodOfRepaymentDescriptionTexts: Record<SecLoansMethodOfRepayment, string> = {
    [SecLoansMethodOfRepayment.Annuity]: formatMessage({
      id: 'sec-loans-payment-plan-method-of-repayment-annuity-description',
    }),

    [SecLoansMethodOfRepayment.ConstantAmount]: formatMessage({
      id: 'sec-loans-payment-plan-method-of-repayment-equal-payment-description',
    }),

    [SecLoansMethodOfRepayment.ConstantDuration]: formatMessage({
      id: 'sec-loans-payment-plan-method-of-repayment-constant-duration-description',
    }),
  }

  const investmentTypeTexts: Record<SecLoansInvestmentType, string> = {
    [SecLoansInvestmentType.Funds]: formatMessage({ id: 'sec-loans-investment-type-funds' }),
    [SecLoansInvestmentType.InsuranceSavings]: formatMessage({ id: 'sec-loans-investment-type-insurance-savings' }),
    [SecLoansInvestmentType.PensionInsurance]: formatMessage({ id: 'sec-loans-investment-type-pension-insurance' }),
    [SecLoansInvestmentType.Equity]: formatMessage({ id: 'sec-loans-investment-type-equity' }),
    [SecLoansInvestmentType.Other]: formatMessage({ id: 'common-other' }),
  }

  const propertyTypeTexts: Record<SecLoansPropertyType, string> = {
    [SecLoansPropertyType.HousingStock]: formatMessage({ id: 'sec-loans-property-type-housing-stock' }),
    [SecLoansPropertyType.Residential]: formatMessage({ id: 'sec-loans-property-type-residential' }),
    [SecLoansPropertyType.Leisure]: formatMessage({ id: 'sec-loans-property-type-leisure' }),
    [SecLoansPropertyType.PartOwnership]: formatMessage({ id: 'sec-loans-property-type-part-ownership' }),
    [SecLoansPropertyType.RightOfOccupancy]: formatMessage({ id: 'sec-loans-property-type-right-of-occupancy' }),
    [SecLoansPropertyType.Other]: formatMessage({ id: 'common-other' }),
  }

  const ownershipTexts: Record<SecLoansApplicants, string> = {
    [SecLoansApplicants.BothApplicants]: formatMessage({ id: 'sec-loans-ownership-common' }),
    [SecLoansApplicants.FirstApplicant]: formatMessage({ id: 'sec-loans-ownership-first-applicant' }),
    [SecLoansApplicants.SecondApplicant]: formatMessage({ id: 'sec-loans-ownership-with-applicant' }),
  }

  const loanCreditTypeTexts: Record<SecLoansLoanOrCreditType, string> = {
    [SecLoansLoanOrCreditType.CarLoan]: formatMessage({ id: 'sec-loans-loan-credit-type-car-loan' }),
    [SecLoansLoanOrCreditType.ConsumerCredit]: formatMessage({ id: 'sec-loans-loan-credit-type-consumer-credit' }),
    [SecLoansLoanOrCreditType.Mortgage]: formatMessage({ id: 'sec-loans-loan-credit-type-mortgage' }),
    [SecLoansLoanOrCreditType.CottageLoan]: formatMessage({ id: 'sec-loans-loan-credit-type-cottage-loan' }),
    [SecLoansLoanOrCreditType.RenovationLoan]: formatMessage({ id: 'sec-loans-loan-credit-type-renovation-loan' }),
    [SecLoansLoanOrCreditType.StudentLoan]: formatMessage({ id: 'sec-loans-loan-credit-type-student-loan' }),
    [SecLoansLoanOrCreditType.InvestmentMortgage]: formatMessage({
      id: 'sec-loans-loan-credit-type-investment-mortgage',
    }),

    [SecLoansLoanOrCreditType.InvestmentLoan]: formatMessage({ id: 'sec-loans-loan-credit-type-investment-loan' }),
    [SecLoansLoanOrCreditType.Other]: formatMessage({ id: 'common-other' }),
  }

  const secLoansEmploymentTypeTexts: Record<SecLoansEmploymentType, string> = {
    [SecLoansEmploymentType.Permanent]: formatMessage({ id: 'sec-loans-info-employment-type-permanent' }),
    [SecLoansEmploymentType.TimeLimited]: formatMessage({ id: 'sec-loans-info-employment-type-time-limited' }),
    [SecLoansEmploymentType.HiredWork]: formatMessage({ id: 'sec-loans-info-employment-type-hired-work' }),
  }

  const secLoansCompanyFormTexts: Record<SecLoansCompanyForm, string> = {
    [SecLoansCompanyForm.LimitedCompany]: formatMessage({ id: 'sec-loans-company-form-limited-company' }),
    [SecLoansCompanyForm.LimitedPartnership]: formatMessage({ id: 'sec-loans-company-form-limited-partnership' }),
    [SecLoansCompanyForm.OpenCompany]: formatMessage({ id: 'sec-loans-company-form-open-company' }),
    [SecLoansCompanyForm.TradeName]: formatMessage({ id: 'sec-loans-company-form-trade-name' }),
  }

  const secLoansIndustrySectorTexts: Record<SecLoansIndustrySector, string> = {
    [SecLoansIndustrySector.AgricultureFishing]: formatMessage({ id: 'sec-loans-industry-sector-agriculture-fishing' }),
    [SecLoansIndustrySector.MiningQuarrying]: formatMessage({ id: 'sec-loans-industry-sector-mining-quarrying' }),
    [SecLoansIndustrySector.Industry]: formatMessage({ id: 'sec-loans-industry-sector-industry' }),
    [SecLoansIndustrySector.ElectricityGasSupply]: formatMessage({
      id: 'sec-loans-industry-sector-electricity-gas-supply',
    }),

    [SecLoansIndustrySector.WaterSupply]: formatMessage({ id: 'sec-loans-industry-sector-water-supply' }),
    [SecLoansIndustrySector.Construction]: formatMessage({ id: 'sec-loans-industry-sector-construction' }),
    [SecLoansIndustrySector.WholesaleRetail]: formatMessage({ id: 'sec-loans-industry-sector-wholesale-retail' }),
    [SecLoansIndustrySector.Transportation]: formatMessage({ id: 'sec-loans-industry-sector-transportation' }),
    [SecLoansIndustrySector.AccommodationFood]: formatMessage({ id: 'sec-loans-industry-sector-accommodation-food' }),
    [SecLoansIndustrySector.InfoCommunication]: formatMessage({ id: 'sec-loans-industry-sector-info-communication' }),
    [SecLoansIndustrySector.FinancyInsurance]: formatMessage({ id: 'sec-loans-industry-sector-financy-insurance' }),
    [SecLoansIndustrySector.RealEstate]: formatMessage({ id: 'sec-loans-industry-sector-real-estate' }),
    [SecLoansIndustrySector.ScientificalTechnical]: formatMessage({
      id: 'sec-loans-industry-sector-scientifical-technical',
    }),

    [SecLoansIndustrySector.Administration]: formatMessage({ id: 'sec-loans-industry-sector-administration' }),
    [SecLoansIndustrySector.PublicAdministration]: formatMessage({
      id: 'sec-loans-industry-sector-public-administration',
    }),

    [SecLoansIndustrySector.Education]: formatMessage({ id: 'sec-loans-industry-sector-education' }),
    [SecLoansIndustrySector.HealthAndSocialServices]: formatMessage({
      id: 'sec-loans-industry-sector-health-and-social-services',
    }),

    [SecLoansIndustrySector.Arts]: formatMessage({ id: 'sec-loans-industry-sector-arts' }),
    [SecLoansIndustrySector.OtherServices]: formatMessage({ id: 'sec-loans-industry-sector-other-services' }),
    [SecLoansIndustrySector.HouseholdEmployers]: formatMessage({ id: 'sec-loans-industry-sector-household-employers' }),
    [SecLoansIndustrySector.InternationalOrganisations]: formatMessage({
      id: 'sec-loans-industry-sector-international-organisations',
    }),
  }

  const highestDegree: Record<SchoolDegree, string> = {
    [SchoolDegree.Elementary]: formatMessage({ id: 'personal-info-school-degree-elementary' }),
    [SchoolDegree.Graduate]: formatMessage({ id: 'personal-info-school-degree-graduate' }),
    [SchoolDegree.HighSchool]: formatMessage({ id: 'personal-info-school-degree-high-school' }),
    [SchoolDegree.Undergraduate]: formatMessage({ id: 'personal-info-school-degree-undergraduate' }),
    [SchoolDegree.Vocational]: formatMessage({ id: 'personal-info-school-degree-vocational' }),
  }

  const occupationTexts: Record<SecLoanOccupation, string> = {
    [Occupation.Employed]: formatMessage({ id: 'personal-info-occupation-employed' }),
    [Occupation.Entrepreneur]: formatMessage({ id: 'personal-info-occupation-entrepreneur' }),
    [Occupation.Student]: formatMessage({ id: 'personal-info-occupation-student' }),
    [Occupation.Pensioner]: formatMessage({ id: 'personal-info-occupation-pensioner' }),
    [Occupation.Unemployed]: formatMessage({ id: 'personal-info-occupation-unemployed' }),
    [OtherOccupation.OnFamilyLeave]: formatMessage({ id: 'personal-info-occupation-on-family-leave' }),
    [OtherOccupation.Other]: formatMessage({ id: 'personal-info-occupation-other' }),
  }

  const applicantsTexts: Record<SecLoansApplicants, string> = {
    [SecLoansApplicants.FirstApplicant]: formatMessage({ id: 'sec-loans-two-applicants-first-applicant-label' }),
    [SecLoansApplicants.SecondApplicant]: formatMessage({ id: 'sec-loans-two-applicants-second-applicant-label' }),
    [SecLoansApplicants.BothApplicants]: formatMessage({ id: 'sec-loans-two-applicants-both-applicant-label' }),
  }

  const liabilityTexts: Record<SecLoansGuaranteeLiability, string> = {
    [SecLoansGuaranteeLiability.Mortgage]: formatMessage({ id: 'sec-loans-guarantee-info-liability-mortgage' }),
    [SecLoansGuaranteeLiability.Other]: formatMessage({ id: 'common-other' }),
  }

  const applicationStateTexts: Record<SecLoanApplicationState, string> = {
    [SecLoanApplicationState.Accepted]: formatMessage({ id: 'sec-loans-application-status-decision-given' }),
    [SecLoanApplicationState.CancelledByPrimaryApplicant]: formatMessage({
      id: 'sec-loans-application-status-cancelled',
    }),

    [SecLoanApplicationState.CancelledBySecondaryApplicant]: formatMessage({
      id: 'sec-loans-application-status-cancelled',
    }),

    [SecLoanApplicationState.Draft]: formatMessage({ id: 'sec-loans-application-status-draft' }),
    [SecLoanApplicationState.Rejected]: formatMessage({ id: 'sec-loans-application-status-decision-given' }),
    [SecLoanApplicationState.ReturnedBySecondaryApplicant]: formatMessage({
      id: 'sec-loans-application-status-returned-by-second-applicant',
    }),

    [SecLoanApplicationState.Sending]: formatMessage({ id: 'sec-loans-application-status-pending' }),
    [SecLoanApplicationState.WaitingForResend]: formatMessage({ id: 'sec-loans-application-status-pending' }),
    [SecLoanApplicationState.Pending]: formatMessage({ id: 'sec-loans-application-status-pending' }),
    [SecLoanApplicationState.WaitingForSecondaryApplicant]: formatMessage({
      id: 'sec-loans-application-status-waiting-for-second-applicant',
    }),
  }

  const referenceRateTexts: Record<SecLoansReferenceRate, string> = {
    [SecLoansReferenceRate.Euribor12Month]: formatMessage({ id: 'sec-loans-reference-rate-euribor-12' }),
    [SecLoansReferenceRate.Fixed]: formatMessage({ id: 'sec-loans-reference-rate-fixed' }),
  }

  const hasCollateralsTexts: Record<YesNo, string> = {
    [YesNo.Yes]: formatMessage({ id: 'common-yes' }),
    [YesNo.No]: formatMessage({ id: 'sec-loans-collateral-not-known-yet' }),
  }

  const collateralTypeTexts: Record<SecLoansCollateralType, string> = {
    [SecLoansCollateralType.OwnCollateral]: formatMessage({ id: 'sec-loans-collateral-own-collateral' }),
    [SecLoansCollateralType.StateGuarantee]: formatMessage({ id: 'sec-loans-collateral-state-guarantee' }),
    [SecLoansCollateralType.AnotherPersonsAssets]: formatMessage({ id: 'sec-loans-collateral-another-persons-assets' }),
  }

  const collateralsAdditionalInfoTexts: Record<SecLoansCollateralType, string> = {
    [SecLoansCollateralType.OwnCollateral]: formatMessage({ id: 'sec-loans-additional-info-form-label' }),
    [SecLoansCollateralType.StateGuarantee]: formatMessage({ id: 'sec-loans-additional-info-form-label' }),
    [SecLoansCollateralType.AnotherPersonsAssets]: formatMessage({
      id: 'sec-loans-collateral-another-persons-assets-additonal-info-label',
    }),
  }

  const lotOwnerShipTexts: Record<SecLoansLotOwnership, string> = {
    [SecLoansLotOwnership.Own]: formatMessage({ id: 'sec-loans-lot-ownership-own' }),
    [SecLoansLotOwnership.PrivateRental]: formatMessage({ id: 'sec-loans-lot-ownership-private-rental' }),
    [SecLoansLotOwnership.PublicRental]: formatMessage({ id: 'sec-loans-lot-ownership-public-rental' }),
  }

  const salesSiteTexts: Record<SecLoansHousingSalesSite, string> = {
    [SecLoansHousingSalesSite.Etuovi]: formatMessage({ id: 'sec-loans-housing-sales-site-etuovi' }),
    [SecLoansHousingSalesSite.Oikotie]: formatMessage({ id: 'sec-loans-housing-sales-site-oikotie' }),
    [SecLoansHousingSalesSite.Other]: formatMessage({ id: 'common-other' }),
  }

  const phaseFinalizationTexts: Record<string, ComplexMessage> = {
    true: formatMessage<ReactElement>({ id: 'sec-loans-two-applicants-phases-finalization-phase-text' }),
    false: formatMessage<ReactElement>({ id: 'sec-loans-one-applicant-phases-finalization-phase-text' }),
  }

  const phaseDescriptionTexts: Record<PhaseType, string> = {
    [APPLICANT_INFO_WIZARD_MACHINE_ID]: formatMessage({ id: 'sec-loans-phases-applicant-phase-description' }),
    [LOAN_INFO_WIZARD_MACHINE_ID]: formatMessage({ id: 'sec-loans-phases-loan-info-phase-description' }),
    [TARGET_INFO_WIZARD_MACHINE_ID]: formatMessage({ id: 'sec-loans-phases-loan-target-phase-description' }),
  }
  const phaseHeaderTexts: Record<PhaseType, string> = {
    [APPLICANT_INFO_WIZARD_MACHINE_ID]: formatMessage({ id: 'sec-loans-phases-applicant-phase-header' }),
    [LOAN_INFO_WIZARD_MACHINE_ID]: formatMessage({ id: 'sec-loans-phases-loan-info-phase-header' }),
    [TARGET_INFO_WIZARD_MACHINE_ID]: formatMessage({ id: 'sec-loans-phases-loan-target-phase-header' }),
  }

  const heatingTypeTexts: Record<SecLoansHeatingType, string> = {
    [SecLoansHeatingType.District]: formatMessage({ id: 'sec-loans-residence-heating-type-district' }),
    [SecLoansHeatingType.Electric]: formatMessage({ id: 'sec-loans-residence-heating-type-electric' }),
    [SecLoansHeatingType.GeoThermal]: formatMessage({ id: 'sec-loans-residence-heating-type-geothermal' }),
    [SecLoansHeatingType.Oil]: formatMessage({ id: 'sec-loans-residence-heating-type-oil' }),
    [SecLoansHeatingType.Other]: formatMessage({ id: 'common-other' }),
  }

  const priceTexts: Record<SecLoansHousingLoanTargetType, string> = {
    [SecLoansHousingLoanTargetType.HousingStock]: formatMessage({ id: 'sec-loans-sales-price-label' }),
    [SecLoansHousingLoanTargetType.NotKnown]: formatMessage({ id: 'sec-loans-price-estimate' }),
    [SecLoansHousingLoanTargetType.PartOwnership]: formatMessage({ id: 'sec-loans-sales-price-label' }),
    [SecLoansHousingLoanTargetType.RealEstate]: formatMessage({ id: 'sec-loans-sales-price-label' }),
    [SecLoansHousingLoanTargetType.RightOfResidence]: formatMessage({ id: 'sec-loans-sales-price-label' }),
  }

  const priceDescriptionTexts: Record<SecLoansHousingLoanTargetType, string> = {
    [SecLoansHousingLoanTargetType.HousingStock]: formatMessage({ id: 'sec-loan-apartment-price-description' }),
    [SecLoansHousingLoanTargetType.NotKnown]: '',
    [SecLoansHousingLoanTargetType.PartOwnership]: '',
    [SecLoansHousingLoanTargetType.RealEstate]: formatMessage({ id: 'sec-loans-real-estate-price-description' }),
    [SecLoansHousingLoanTargetType.RightOfResidence]: '',
  }

  const serviceLanguageTexts: LocalizedString = {
    [Language.Fi]: formatMessage({ id: 'ao-basic-form-language-finnish' }),
    [Language.Sv]: formatMessage({ id: 'ao-basic-form-language-swedish' }),
  }

  const purchaseLoanPurposeTexts: Record<SecLoansPurchaseLoanPurpose, string> = {
    [SecLoansPurchaseLoanPurpose.Car]: formatMessage({ id: 'sec-loans-purchase-loan-purpose-car-label' }),
    [SecLoansPurchaseLoanPurpose.Consumption]: formatMessage({
      id: 'sec-loans-purchase-loan-purpose-consumption-label',
    }),

    [SecLoansPurchaseLoanPurpose.Investment]: formatMessage({ id: 'sec-loans-purchase-loan-purpose-investment-label' }),
    [SecLoansPurchaseLoanPurpose.Other]: formatMessage({ id: 'common-other' }),
  }

  const residenceTypeDescriptionTexts: Record<SecLoansHousingLoanTargetType, string> = {
    [SecLoansHousingLoanTargetType.NotKnown]: formatMessage({
      id: 'sec-loans-target-unknown-residence-type-description',
    }),

    [SecLoansHousingLoanTargetType.HousingStock]: '',
    [SecLoansHousingLoanTargetType.PartOwnership]: '',
    [SecLoansHousingLoanTargetType.RealEstate]: '',
    [SecLoansHousingLoanTargetType.RightOfResidence]: '',
  }

  const secondaryApplicantChoiceTexts: Record<SecondaryApplicantChoice, string> = {
    [SecondaryApplicantChoice.SubmitApplication]: formatMessage({ id: 'sec-loans-status-continue-to-send-label' }),
    [SecondaryApplicantChoice.ReturnApplication]: formatMessage({
      id: 'sec-loans-status-return-application-to-first-applicant-title',
    }),
  }

  const constructionLotOwnershipTexts: Record<SecLoansConstructionLotOwnership, string> = {
    [SecLoansConstructionLotOwnership.Own]: formatMessage({
      id: 'sec-loans-construction-loan-plot-ownership-own-label',
    }),
    [SecLoansConstructionLotOwnership.BuyLater]: formatMessage({
      id: 'sec-loans-construction-loan-plot-ownership-buy-later-label',
    }),
    [SecLoansConstructionLotOwnership.Rent]: formatMessage({
      id: 'sec-loans-construction-loan-plot-ownership-rent-label',
    }),
  }

  const constructionMaterialTexts: Record<ConstructionMaterial, string> = {
    [ConstructionMaterial.Wood]: formatMessage({ id: 'sec-loans-construction-loan-construction-material-wood-label' }),
    [ConstructionMaterial.Brick]: formatMessage({
      id: 'sec-loans-construction-loan-construction-material-brick-label',
    }),

    [ConstructionMaterial.Stone]: formatMessage({
      id: 'sec-loans-construction-loan-construction-material-stone-label',
    }),
  }

  const phaseInstructionTexts: PhaseInstructions = {
    [CompletionProgress.NotStarted]: {
      title: formatMessage({ id: 'sec-loans-progress-start-header' }),
      text: formatMessage({ id: 'sec-loans-progress-start-description' }),
    },
    [CompletionProgress.InComplete]: {
      title: formatMessage({ id: 'sec-loans-progress-started-header' }),
      text: formatMessage({ id: 'sec-loans-progress-started-description' }),
    },
    [CompletionProgress.InCompletePastHalf]: {
      title: formatMessage({ id: 'sec-loans-progress-halfway-header' }),
      text: formatMessage({ id: 'sec-loans-progress-halfway-description' }),
    },
    [CompletionProgress.Complete]: {
      title: formatMessage({ id: 'sec-loans-progress-finalizing-header' }),
      text: formatMessage({ id: 'sec-loans-progress-finalizing-description' }),
    },
  }

  const bestTimeToReachTexts: Record<BestTimeToReach, string> = {
    [BestTimeToReach.Afternoon]: formatMessage({ id: 'sec-loans-best-time-to-reach-afternoon' }),
    [BestTimeToReach.Forenoon]: formatMessage({ id: 'sec-loans-best-time-to-reach-forenoon' }),
    [BestTimeToReach.AnyTime]: formatMessage({ id: 'sec-loans-best-time-to-reach-any-time' }),
  }

  return {
    aloneOrTogetherLabelTexts,
    applicantsTexts,
    applicationStateTexts,
    applicationTypeLabelTexts,
    loanPurposeTypeLabelTexts,
    expenseInfoCarOwnershipLabelTexts,
    incomeInfoOtherIncomeLabelText,
    housingInfoHousingTypeLabelText,
    housingInfoHomeOwnershipTypeLabelText,
    investmentTypeTexts,
    employmentInfoWorkingHoursLabelText,
    employmentInfoWorkingPositionLabelText,
    employmentInfoProbationLabelText,
    employmentInfoPreviousEmploymentTypeLabelText,
    loanCreditTypeTexts,
    housingLoanTargetTypeDescriptionTexts,
    cottageLoanTargetTypeDescriptionTexts,
    housingLoanTargetTypeTexts,
    residenceInfoResidenceTypeTexts,
    currentResidenceSellingPlanTexts,
    expenseShareForNewFlatTexts,
    liabilityTexts,
    methodOfRepaymentLabelTexts,
    methodOfRepaymentDescriptionTexts,
    ownershipTexts,
    propertyTypeTexts,
    secLoansEmploymentTypeTexts,
    secLoansCompanyFormTexts,
    secLoansIndustrySectorTexts,
    highestDegree,
    occupationTexts,
    referenceRateTexts,
    collateralTypeTexts,
    hasCollateralsTexts,
    collateralsAdditionalInfoTexts,
    lotOwnerShipTexts,
    salesSiteTexts,
    phaseFinalizationTexts,
    phaseDescriptionTexts,
    phaseHeaderTexts,
    heatingTypeTexts,
    priceTexts,
    priceDescriptionTexts,
    serviceLanguageTexts,
    purchaseLoanPurposeTexts,
    abortModalHeaderTexts,
    abortModalDescriptionTexts,
    residenceTypeDescriptionTexts,
    secondaryApplicantChoiceTexts,
    constructionLotOwnershipTexts,
    constructionMaterialTexts,
    phaseInstructionTexts,
    bestTimeToReachTexts,
  }
}
