import {
  assertValue,
  OptionalAndNullable,
  RenovationsInfoData,
  SecLoanApplicationType,
  SecLoanPurposeType,
  SecLoansHousingLoanTargetType,
} from '@shared'
import { convertUndefinedToNull, getYesNoDefault, numberToString, toNumber, YesNo } from '@ui-common'

import { RenovationsInfoStepData, StepDataValidator } from '../../../types'
import { isFormDataValid } from '../../../wizardData'
import { createFormValidator } from './formValidator'
import { RenovationsInfoFormData } from './types'

export const convertToFormValues = (
  renovationsInfo: RenovationsInfoStepData | undefined,
): OptionalAndNullable<RenovationsInfoFormData> => {
  return convertUndefinedToNull({
    hasUpcomingRenovations: getYesNoDefault(!!renovationsInfo, renovationsInfo?.renovationsAmount, YesNo.No),
    renovationsAmount: numberToString(renovationsInfo?.renovationsAmount),
    hasOtherPurchases: getYesNoDefault(!!renovationsInfo, renovationsInfo?.otherPurchasesAmount, YesNo.No),
    otherPurchasesAmount: numberToString(renovationsInfo?.otherPurchasesAmount),
  })
}

export const convertToSubmitData = (formData: RenovationsInfoFormData): RenovationsInfoStepData => {
  return {
    renovationsAmount: formData.hasUpcomingRenovations === YesNo.Yes ? toNumber(formData.renovationsAmount) : undefined,
    otherPurchasesAmount:
      formData.hasOtherPurchases === YesNo.Yes ? toNumber(formData.otherPurchasesAmount) : undefined,
  }
}

export const getAndValidateRenovationsTypeData: StepDataValidator<RenovationsInfoData | undefined | null> = (
  validationData,
  applicationMetadata,
) => {
  const metadata = assertValue(applicationMetadata, 'applicationMetadata')
  const isKnownTarget =
    validationData.targetInfo?.housingLoanInfo?.targetTypeInfo?.targetType !== SecLoansHousingLoanTargetType.NotKnown
  const isConstructionPurpose = metadata?.applicationPurpose === SecLoanPurposeType.Construction
  const isCottageLoanTransfer =
    metadata.applicationType === SecLoanApplicationType.CottageLoan &&
    metadata.applicationPurpose === SecLoanPurposeType.LoanTransferToSbank

  const isInvestmentLoanTransfer =
    metadata.applicationType === SecLoanApplicationType.PropertyInvestment &&
    metadata.applicationPurpose === SecLoanPurposeType.LoanTransferToSbank

  if (!isKnownTarget || isConstructionPurpose || isCottageLoanTransfer || isInvestmentLoanTransfer) {
    return null
  }
  const renovationsInfoData = validationData.targetInfo?.renovationsInfoData
  return isFormDataValid(convertToFormValues(renovationsInfoData), createFormValidator)
    ? renovationsInfoData
    : undefined
}

export const isRenovationsComplete = (renovationsInfo: RenovationsInfoStepData | undefined): boolean => {
  return !!renovationsInfo
}
