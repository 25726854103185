import { OptionalAndNullable } from '@shared'
import { YesNo } from '@ui-common'
import { Path } from 'react-hook-form'
import { IntlShape } from 'react-intl'
import { create, enforce, only, Suite, test } from 'vest'

import { InsuranceInfoFormData as FormData } from './types'

export const createFormValidator = (
  formatMessage: IntlShape['formatMessage'],
): Suite<string, string, (data: OptionalAndNullable<FormData>, changedField?: Path<FormData>) => void> => {
  return create((data, changedField) => {
    only(changedField)

    test('isInterestedInInsurance', formatMessage({ id: 'validate-required-field-missing' }), () => {
      enforce(data.isInterestedInInsurance).inside(Object.values(YesNo))
    })
  })
}
