import { AlertModal, ButtonType } from '@ui-components/containers/AlertModal'
import { ModalVisualMode } from '@ui-components/containers/ModalTypes'
import { NoButtonsModal } from '@ui-components/containers/NoButtonsModal'
import { PromptModal } from '@ui-components/containers/PromptModal'
import { useSelector } from '@xstate/react'
import { ReactElement, ReactNode } from 'react'
import { Actor } from 'xstate'

import { modalMachine, OPEN } from './modalMachine'

export interface PrimaryButtonOptions {
  label: string
  onClick?: () => unknown
  type?: ButtonType
}

export interface SecondaryButtonOptions {
  label: string
  onClick?: () => unknown
}

export interface ShowModalOptions {
  children: ReactNode
  contentAriaLabel: string
  header?: string | ReactElement
  primaryButtonOptions?: PrimaryButtonOptions
  secondaryButtonOptions?: SecondaryButtonOptions
  mode?: ModalVisualMode
  requestId?: string
}

export interface PromptModalOptions {
  shouldKeepButtonsOrder?: boolean
}

export interface ModalProps {
  service: Actor<typeof modalMachine>
  promptModalOptions?: PromptModalOptions
}

const defaultOptions: ShowModalOptions = {
  children: undefined,
  contentAriaLabel: '',
  primaryButtonOptions: undefined,
  mode: ModalVisualMode.Info,
}

export const Modal = ({ service, promptModalOptions }: ModalProps): JSX.Element => {
  const state = useSelector(service, (s) => s)

  const context = state.context
  const options = context.options ?? defaultOptions
  const { primaryButtonOptions, secondaryButtonOptions, mode, contentAriaLabel, children, header, requestId } = options

  const onPrimaryButtonClick = () => {
    service.send({ type: 'CONFIRM' })
    if (primaryButtonOptions?.onClick) {
      primaryButtonOptions.onClick()
    }
  }

  const onSecondaryButtonClick = () => {
    onClose()
    if (secondaryButtonOptions?.onClick) {
      secondaryButtonOptions.onClick()
    }
  }

  const onClose = () => {
    service.send({ type: 'DISMISS' })
  }

  const primaryButtonLabel = primaryButtonOptions?.label ?? ''
  const secondaryButtonLabel = secondaryButtonOptions?.label ?? ''

  const shouldShowPromptModal = state.matches(OPEN) && !!secondaryButtonOptions
  const shouldShowAlertModal = state.matches(OPEN) && !shouldShowPromptModal && !!primaryButtonOptions
  const shouldShowNoButtonsModal = state.matches(OPEN) && !secondaryButtonOptions && !primaryButtonOptions
  return (
    <>
      <AlertModal
        contentLabel={contentAriaLabel}
        onButtonClick={onPrimaryButtonClick}
        buttonLabel={primaryButtonLabel}
        buttonType={primaryButtonOptions?.type}
        isOpen={shouldShowAlertModal}
        header={header}
        mode={mode}
        requestId={requestId}
      >
        {children}
      </AlertModal>
      <PromptModal
        contentLabel={contentAriaLabel}
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={onSecondaryButtonClick}
        onClose={onClose}
        primaryButtonLabel={primaryButtonLabel}
        secondaryButtonLabel={secondaryButtonLabel}
        isOpen={shouldShowPromptModal}
        shouldKeepButtonsOrder={promptModalOptions?.shouldKeepButtonsOrder}
        header={header}
        mode={mode}
        requestId={requestId}
      >
        {children}
      </PromptModal>
      <NoButtonsModal
        contentLabel={contentAriaLabel}
        onClose={onClose}
        isOpen={shouldShowNoButtonsModal}
        header={header}
        requestId={requestId}
      >
        {children}
      </NoButtonsModal>
    </>
  )
}
