import { forwardRef } from 'react'
import { useIntl } from 'react-intl'

import { CsatDifficultyLevel } from '../../../types'
import { buildOptionList } from '../../../utils'
import { getCommonLocalizedEnums } from '../../../utils/getCommonLocalizedEnums'
import { SnowPlowData } from '../analytics'
import { CsatFormData, CsatFormHandle, CsatQuestionnaire } from './CsatQuestionnaire'

interface CsatDifficultyProps {
  difficultyLevelLabel: string
  onContinue: () => unknown
}

export const CsatDifficultyQuestionnaire = forwardRef<CsatFormHandle, CsatDifficultyProps>((props, ref) => {
  const { difficultyLevelLabel, onContinue } = props
  const intl = useIntl()
  const { csatDifficultyLevels } = getCommonLocalizedEnums(intl.formatMessage)

  const getAnalyticsData = (data: CsatFormData): SnowPlowData | undefined => {
    if (data.difficultyLevel) {
      const levels: Record<CsatDifficultyLevel, string> = {
        [CsatDifficultyLevel.VeryEasy]: '5',
        [CsatDifficultyLevel.Easy]: '4',
        [CsatDifficultyLevel.Neutral]: '3',
        [CsatDifficultyLevel.Difficult]: '2',
        [CsatDifficultyLevel.VeryDifficult]: '1',
      }
      const csatDifficultyLevel = levels[data.difficultyLevel]
      return { csatDifficultyLevel, csatFeedback: data.feedback ?? '' }
    }
    return undefined
  }

  const options = buildOptionList(Object.values(CsatDifficultyLevel), csatDifficultyLevels)

  return (
    <CsatQuestionnaire
      getAnalyticsData={getAnalyticsData}
      onContinue={onContinue}
      options={options}
      formId={'difficultyLevel'}
      formLabel={difficultyLevelLabel}
      feedbackLabel={intl.formatMessage({ id: 'common-csat-feedback-label' })}
      ref={ref}
    />
  )
})

CsatDifficultyQuestionnaire.displayName = 'CsatDifficultyQuestionnaire'
